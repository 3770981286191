import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useState } from "react";
import moment from "moment-timezone";
import { MobileFriendly, PhoneAndroid } from "@mui/icons-material";
import { ListOrdered, ShoppingBag } from "lucide-react";
import Container from "../../components/Container";

const GET_USER = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      resellerId
      name
      userType
      doj
      mobile
      wallet
      totalMessages
      totalMessagesSent
      isConnected
      expireInDays
      planType
      devices {
        id
        deviceId
        deviceName
        isConnected
        updatedAt
      }
      orders {
        orderId
        name
        amount
        status
        createdAt
      }
    }
  }
`;

const GET_PLAN_BY_NAME = gql`
  query ($planName: String!) {
    PlanByName(planName: $planName) {
      id
      name
      type
      rate
      credits
      wallet
      planFor
      amount
      validity
      description
    }
  }
`;

export default function Dashboard() {
  const navigate = useNavigate();

  const [data, setData] = useState("");
  const [resellerInfo, setResellerInfo] = useState("");


  let { uid } = JSON.parse(localStorage.getItem("user")) || {};

  let planName = "";

  const setUserProfile = (userProfile) => {
    if (userProfile) {
      if (userProfile.userType === "User") {
        setData(userProfile);
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const {
    data: userData,
    loading: userLoading,
  } = useQuery(GET_USER, {
    variables: { uid },
  });

  const {
    data: userPlanData,
    loading: userPlanLoading,
    refetch: userPlanRefetch,
  } = useQuery(GET_PLAN_BY_NAME, {
    variables: { planName },
  });

  const {
    data: resellerData,
    loading: resellerLoading,
    refetch: resellerRefetch,
  } = useQuery(GET_USER, {
    variables: { uid },
  });

  useEffect(() => {
    if (data) {
      userPlanRefetch({ planName: data.planType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let userProfile = null;

    if (userData) {

      userProfile = userData.user;

      if (userProfile.resellerId) resellerRefetch({ uid: userProfile.resellerId });

      setUserProfile(userProfile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userLoading]);

  useEffect(() => {
    let resellerProfile = null;
    if (resellerData) {
      resellerProfile = resellerData.user;

      if (resellerProfile.userType === "Reseller") {
        setResellerInfo(resellerProfile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resellerData, resellerLoading]);

  return (
    <Container>
      <div className="flex flex-col justify-between h-full w-full">
        <div>
          {
            !userLoading && data ? (
              <div>
                <h1 className="mt-4 mb-4 text-center text-[16px] font-semibold text-gray-600 uppercase">
                  Welcome back {data.name}
                </h1>
                <h2 className="text-center text-sm font-semibold text-red-500">
                  {moment(Number(data.doj))
                    .add(data.expireInDays, "days")
                    .tz("Asia/Kolkata")
                    .format("YYYY-MM-DD") >
                    moment(Number(new Date().getTime()))
                      .add(0, "days")
                      .tz("Asia/Kolkata")
                      .format("YYYY-MM-DD")
                    ? ""
                    : "Your account has been expired. Contact service provider"}
                </h2>
                <div class="mt-11 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
                  <div className="w-full h-full rounded-md bg-pink-400 p-4 text-white relative">
                    <h3 className="text-center uppercase ">PLAN DETAILS</h3>

                    <div className="grid grid-cols-12 justify-left items-center space-x-2">
                      <p className="font-serif col-span-6">Purchase Date</p>
                      <p className="col-span-2"> : </p>
                      <p className="col-span-4">{moment(Number(data.doj))
                        .tz("Asia/Kolkata")
                        .format("DD-MM-YYYY")}</p>
                    </div>

                    <div className="grid grid-cols-12 justify-left items-center space-x-2">
                      <p className="font-serif col-span-6">Expiry Date</p>
                      <p className="col-span-2"> : </p>
                      <p className="col-span-4">{moment(Number(data.doj))
                        .add(data.expireInDays, "days")
                        .tz("Asia/Kolkata")
                        .format("DD-MM-YYYY")}</p>
                    </div>

                    {
                      Number(data.wallet) === 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-6">Total Credits</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-4">{data.totalMessages}</p>
                        </div>
                      )
                    }

                    <div className="grid grid-cols-12 justify-left items-center space-x-2">
                      <p className="font-serif col-span-6">Used Credits</p>
                      <p className="col-span-2"> : </p>
                      <p className="col-span-4">{data.totalMessagesSent}</p>
                    </div>

                    {
                      Number(data.wallet) === 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-6">Remaining Credits</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-4">
                            {Number(data.totalMessages) -
                              Number(data.totalMessagesSent)}
                          </p>
                        </div>
                      )
                    }

                    {
                      !userPlanLoading && userPlanData && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-6">Plan </p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-4">{userPlanData.PlanByName.name}</p>
                        </div>
                      )
                    }

                    {
                      !userPlanLoading && userPlanData && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-6">Plan Type</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-4">{userPlanData.PlanByName.type}</p>
                        </div>
                      )
                    }

                    <div className="grid grid-cols-12 justify-left items-center space-x-2">
                      <p className="font-serif col-span-6">Status</p>
                      <p className="col-span-2"> : </p>
                      <p className="col-span-4">{
                        moment(Number(data.doj))
                          .add(data.expireInDays, "days")
                          .tz("Asia/Kolkata")
                          .format("YYYY-MM-DD") >
                          moment(Number(new Date().getTime()))
                            .add(0, "days")
                            .tz("Asia/Kolkata")
                            .format("YYYY-MM-DD")
                          ? "ACTIVE"
                          : "EXPIRED"
                      }</p>
                    </div>

                    {
                      data.resellerId.trim() === "" &&
                      (
                        <div className="absolute right-0 -bottom-8">
                          <button className="px-4 py-2 rounded-xl text-xs w-[120px] bg-gray-600 flex justify-center items-center"
                            onClick={() => navigate("/plans")}
                          >
                            <ShoppingBag />
                            <span>Buy</span>
                          </button>
                        </div>
                      )
                    }
                  </div>
                  <div className={`${data.devices[0]?.isConnected ? "bg-yellow-400" : "bg-yellow-400"} w-full h-full rounded-md  p-4 text-white relative`}>
                    <h3 className="text-center uppercase ">ORDER</h3>
                    {
                      data.orders.length > 0 && (

                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Last Order</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{data.orders[0].orderId}</p>
                        </div>
                      )
                    }
                    {
                      data.orders.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Purchased By</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{data.orders[0]?.name}</p>
                        </div>

                      )
                    }
                    {
                      data.orders.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Amount</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{data.orders[0]?.amount}</p>
                        </div>

                      )
                    }
                    {
                      data.orders.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Status</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{data.orders[0]?.status}</p>
                        </div>

                      )
                    }
                    {
                      data.orders.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Purchase Date</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{moment.utc(Number(data.orders[0].createdAt)).tz('Asia/Kolkata').format('DD-MM-YYYY HH:mm:ss')}</p>
                        </div>
                      )
                    }

                    <div className="absolute right-0 -bottom-8">
                      <button className="px-4 py-2 rounded-xl text-xs w-[120px]  bg-gray-600 flex justify-center items-center"
                        onClick={() => navigate("/order")}
                      >
                        <ListOrdered />
                        <span>Orders</span>
                      </button>
                    </div>

                  </div>
                  <div className={`${data.devices[0]?.isConnected ? "bg-green-400" : "bg-red-400"} w-full h-full rounded-md  p-4 text-white relative`}>
                    <h3 className="text-center uppercase ">DEVICE STATUS</h3>
                    {
                      data.devices.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Device Name</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{data.devices[0].deviceName}</p>
                        </div>
                      )
                    }
                    {
                      data.devices.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Status</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{data.devices[0]?.isConnected ? "Connected" : "Disconnected"}</p>
                        </div>
                      )
                    }
                    {
                      data.devices.length > 0 && (
                        <div className="grid grid-cols-12 justify-left items-center space-x-2">
                          <p className="font-serif col-span-4">Connection</p>
                          <p className="col-span-2"> : </p>
                          <p className="col-span-6">{moment.utc(Number(data.devices[0].updatedAt)).tz('Asia/Kolkata').format('DD-MM-YYYY HH:mm:ss')}</p>
                        </div>
                      )
                    }
                    <div className="absolute right-0 -bottom-8">
                      <button className="px-4 py-2 rounded-xl text-xs w-[120px] bg-gray-600 flex justify-center items-center"
                        onClick={() => navigate("/addDevice")}
                      >
                        <MobileFriendly />
                        <span>Devices</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No Data!</div>
            )
          }
        </div>
        <div>
          {
            data?.resellerId && (
              <div className="text-xs left-[260px] bottom-2  bg-green-200 font-serif">
                <p className="m-auto w-[100%] font-serif text-green-500">
                  FOR SALES & SUPPORT: <PhoneAndroid></PhoneAndroid>{" "}
                  <span className="blink">{resellerInfo.mobile}</span>
                </p>
              </div>
            )
          }
          {
            !data?.resellerId && (
              <div className="text-xs left-[260px] bottom-2  bg-green-200 font-serif">
                <p className="m-auto w-[100%]  text-green-500">
                  FOR SALES & SUPPORT: <PhoneAndroid></PhoneAndroid>{" "}
                  <span className="blink">9717080648, 9717945648</span>
                </p>
              </div>
            )
          }
        </div>
      </div>
    </Container>
  );
}