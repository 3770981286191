import { useEffect, useState } from 'react';
import moment from 'moment';
import { gql, useMutation, useQuery } from "@apollo/client";

const DELETE_TEMPLATE = gql`
    mutation($templateId: ID!) {
        templateDelete(templateId: $templateId) {
            templateErrors {
                message
            }
        }
    }
`
const UPDATE_TEMPLATE = gql`
    mutation($templateId: ID!,$template:TemplateInput!) {
        templateUpdate(templateId: $templateId,template: $template) {
            templateErrors {
                message
            }
        }
    }
`
const CREATE_TEMPLATE = gql`
    mutation($template:TemplateInput!) {
        templateCreate(template: $template) {
            templateErrors {
                message
            }
            template {
                id
            }
        }
    }
`
const LIST_TEMPLATE = gql`
    query TemplateByUID($uid: String!){
        templateByUID(uid: $uid){
            id
            uid
            templateName
            category
            language
            body
            userId
            createdAt
        }
    }
`
const useTemplate = () => {
    const [records, setRecords] = useState(null);
    const [isPending, setPending] = useState(false);
    const [updateClicked, setUpdateClicked] = useState(false)
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const [templateCreate] = useMutation(CREATE_TEMPLATE);
    const [templateUpdate] = useMutation(UPDATE_TEMPLATE);
    const [deleteTemplate] = useMutation(DELETE_TEMPLATE);

    const {
        refetch,
        data,
        loading
    } = useQuery(LIST_TEMPLATE, {
        variables: { uid: userInfo.uid }
    });

    const [formData, setFormData] = useState({
        id: "",
        uid: "",
        templateName: "",
        category: "",
        language: "",
        body: "",
        userId: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = async (e) => {
        e.preventDefault()
        // Logic to save form data
        await templateCreate({
            variables: {
                template: {
                    category: formData.category,
                    language: formData.language,
                    body: formData.body,
                    templateName: formData.templateName + ` - [${userInfo.uid}]`,
                    userId: Number(userInfo.id),
                    uid: userInfo.uid
                }
            },
            onCompleted: (resp) => {

                if (
                    resp.templateCreate.templateErrors &&
                    resp.templateCreate.templateErrors.length > 0
                ) {
                    let errorMessage = resp.templateCreate.templateErrors[0].message
                    setError(errorMessage)
                }

                setSuccess("Template created successfully!")

                refetch({ uid: userInfo.uid });
            }
        })

    };

    const handleCancel = () => {
        // Logic to cancel and reset form data
        setFormData({});
    };

    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = (e) => {
        if (e) {
            setIsEditing(true);
            setFormData(e);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        // Logic to save form data
        await templateUpdate({
            variables: {
                templateId: Number(formData.id),
                template: {
                    category: formData.category,
                    language: formData.language,
                    body: formData.body,
                    templateName: formData.templateName,
                    userId: Number(userInfo.id),
                    uid: userInfo.uid
                }
            },
            onCompleted: (resp) => {

                if (
                    resp.templateUpdate.templateErrors &&
                    resp.templateUpdate.templateErrors.length > 0
                ) {
                    let errorMessage = resp.templateUpdate.templateErrors[0].message
                    setError(errorMessage)
                }

                setSuccess("Template updated successfully!")

                refetch({ uid: userInfo.uid });

                setUpdateClicked(false);

            }
        })
    };

    const handleDelete = async (e) => {
        setPending(true);

        let resp = window.confirm("Do you really want to delete template");

        if (resp) {

            //DELETE USER
            deleteTemplate({
                variables: {
                    templateId: Number(e.id),
                },
                refetchQueries: [LIST_TEMPLATE],
                onCompleted: () => {
                    setSuccess("Template deleted successfully!");

                    refetch({ uid: userInfo.uid });
                    setPending(false);
                },
                onError: (error) => {
                    setPending(false)
                    console.log(error)
                }
            });
        }

    };

    useEffect(() => {
        if (!isEditing && data && data.templateByUID && data.templateByUID.length > 0) {
            let templateRecords = [...data.templateByUID];

            templateRecords = templateRecords
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map(user => ({
                    ...user,
                    createdAt: moment(new Date(Number(user.createdAt)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                }));

            setRecords(templateRecords);
        }
    }, [data, loading, isEditing]);

    useEffect(() => {

        if (error || success) {
            setTimeout(() => {
                setError("")
                setSuccess("")
                setFormData({
                    id: "",
                    uid: "",
                    templateName: "",
                    category: "",
                    language: "",
                    body: "",
                    userId: ""
                })
            }, 2500)
        }
    }, [error, success])

    return {
        formData,
        handleChange,
        handleSave,
        handleUpdate,
        handleDelete,
        handleEdit,
        isPending,
        handleCancel,
        updateClicked,
        setUpdateClicked,
        records,
        error,
        success
    };
};

export default useTemplate;
