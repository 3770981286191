import React from 'react'
import Sidebar from '../../components/Sidebar'

function Reminder() {
    return (
        <div className="flex w-screen ">
            <Sidebar />
            <div className="body w-full flex-1 bg-gray-200 p-8">
                <div className="w-full">
                    Reminders
                </div>
            </div>
        </div>
    )
}

export default Reminder