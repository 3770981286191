import React, { useEffect, useState } from 'react'
import ExcelSheet from './ExcelSheet'

function ExcelSheetView({ excelColumnValues, setExcelSheetValues, setExcelSheetVisible }) {
    const [data, setData] = useState([])

    useEffect(() => {
        let sheetData = []

        console.log("excelColumnValues", excelColumnValues)

        if (
            excelColumnValues &&
            excelColumnValues.name &&
            excelColumnValues.phone &&
            excelColumnValues.message
        ) {
            sheetData = []

            sheetData = excelColumnValues.name.map((v, i) => {
                let index = i;
                index += 1

                return {
                    id: index,
                    name: excelColumnValues.name[i],
                    phone: excelColumnValues.phone[i],
                    message: excelColumnValues.message[i],
                }
            })

            setData(sheetData)
        } else {
            setData([])
        }
    }, [excelColumnValues])

    return (
        <div className='bg-white'>
            {data && <ExcelSheet data={data} setExcelSheetValues={setExcelSheetValues} setExcelSheetVisible={setExcelSheetVisible} />}
        </div>
    )
}

export default ExcelSheetView
