import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

function GroupInMessageView() {
    const location = useLocation()
    const [groupInMessage, setGroupInMessage] = useState("")

    useEffect(() => {
        setGroupInMessage(location.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex w-screen">
            <Sidebar />
            <div className="body w-full flex-1  p-8">
                <div className='w-1/2 m-auto'>
                    <div className='grid grid-cols-2'>
                        <div className='border-1 font-sans font-semibold'>GROUP NAME:</div>
                        <div className='border-1 font-mono'>{groupInMessage.groupName}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='border-1 font-sans font-semibold'>GROUP ADMIN:</div>
                        <div className='border-1 font-mono'>{groupInMessage.groupAdmin}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='border-1 font-sans font-semibold'>FROM:</div>
                        <div className='border-1 font-mono'>{groupInMessage.from}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='border-1 font-sans font-semibold'>TO:</div>
                        <div className='border-1 font-mono'>{groupInMessage.to}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='border-1 font-sans font-semibold'>MESSAGE:</div>
                        <div className='border-1 font-mono'>{groupInMessage.message}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupInMessageView
