import React, { useState, useEffect } from "react";

import { Download } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import Container from "../../components/Container";
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";



const columns = [
  { field: "fileName", headerName: "File Name", minWidth: 300, align: "left" },
  {
    field: "createdAt",
    headerName: "Uploaded Date",
    minWidth: 200,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    field: "version",
    headerName: "Version",
    minWidth: 130,
    align: "left",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    field: 'actions',
    type: 'actions',
    width: 100,
    headerName: "Actions",
    minWidth: 170,
    align: "center",
    getActions: ({ row }) => [
      <GridActionsCellItem
        icon={<Download />}
        label="Download"
        onClick={() => {
          // Check if downloadURL exists and is a valid URL
          if (row.downloadURL) {
            window.open(row.downloadURL, '_blank'); // Open in a new tab
          } else {
            console.error("Download URL is not valid");
          }
        }}
      />,
    ]
  }
];

const ALL_PLUGIN_GET = gql`
  query($pluginType: String!){
    PluginsByType(pluginType: $pluginType) {
      id
      fileName
      type
      version
      createdAt
      downloadURL
    }
  }
`;

function Plugin() {
  const [pluginData, setPluginData] = useState("");

  const { data, loading } = useQuery(ALL_PLUGIN_GET, {
    variables: {
      pluginType: ""
    }
  });

  //BUSY INFO FETCH
  useEffect(() => {
    if (data) {
      let pData = data.PluginsByType;
      pData = pData.map(d => {
        return {
          ...d,
          createdAt: moment(Number(d.createdAt)).tz("Asia/Kolkata").format("DD:MM:YY HH:MM")
        }
      })
      setPluginData(pData);
    }
  }, [data, loading]);

  return (
    <Container>

      <div className="grid grid-cols-12 gap-2 p-4 overflow-y-scroll no-scrollbar">
        <div className="col-span-6 place-content-center">
          {
            pluginData &&
              pluginData.length >= 0 ? (
              <DataTable height="350px" columns={columns} data={pluginData} bgcolor="white" />
            ) : (
              <div className="h-screen w-full flex justify-center items-center">
                <p className="text-xl font-semibold text-red-500 ">
                  no records found
                </p>
              </div>
            )
          }
        </div>
        <div className="col-span-6 place-content-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NQjikTrX6Dc"
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen
            className="rounded-lg shadow-lg"
          ></iframe>
        </div>
      </div>

    </Container>
  );
}

export default Plugin;
