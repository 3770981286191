import React from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function DataTable({ data, columns, height = "500px", bgColor = "white" }) {
    return (

        <div style={{ height: height, width: '100%', backgroundColor: bgColor, borderRadius: "16px" }}>
            <DataGrid
                sx={{ padding: 2 }}
                rows={data}
                columns={columns}
                components={{
                    Toolbar: GridToolbar,
                }}
                checkboxSelection={true}
            />
        </div>

    )
}

export default DataTable