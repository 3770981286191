import { Done } from '@mui/icons-material';
import React from 'react'
import Nav from "../../components/Nav";


function Aggrement() {
    return (
        <div>
            <Nav />
            <div className='p-8'>
                <h1 className='text-3xl font-semibold text-center mb-4 text-gray-400'>SELF DECLARTION BEFORE SIGN UP</h1>
                <p className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>I declare that</p>
                <ul>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I have fully understood the nature of services of the service provider and agree to subscribe to their demo/paid plans.</p>
                    </li>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I understand that, my numbers can get blocked if I do not follow the message sending policies of the service provider</p>
                    </li>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I cannot send spam messages as per the guidelines</p>
                    </li>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I understand that my number will get blocked if any recipient/recipients put my number in spam list.</p>
                    </li>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I cannot and will not ask for refund of any money ,under any circumstances, paid to service provider to use this service.</p>
                    </li>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I am fully responsible for any content inside the messages sent through my mobile number/numbers connected to this account at any time.</p>
                    </li>
                    <li className='flex justify-start items-center space-x-2 text-xl font-serif text-gray-400'>
                        <Done />
                        <p>I will not use this service to send sexual, abusive, minors related text or images to anybody.</p>
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default Aggrement