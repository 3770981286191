import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Emojis from "../../util/Emojis";
import { gql, useQuery } from "@apollo/client";
import CronStringGenerator from "../../util/CronStringGenerator";
import GoogleSheetHandler from "../../components/GoogleSheetHandler";
import ExcelSheetHandler from "../../components/ExcelSheetHandler";
import ExcelSheetView from "../googleSheet/ExcelSheetView";
import Container from "../../components/Container";
import { View } from "lucide-react";
import Alerts from "../../components/Alerts";
import ReactQuill from "react-quill";

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

const GET_TEMPLATES = gql`
    query($uid: String!){
        templateByUID(uid:$uid) {
            id,
            templateName,
            body
        }
    }
`

const DEVICE_GET = gql`
  query($uid: String!){
      devicesByUID(uid: $uid) {
          id
          deviceId
          deviceName
          uid
          userId
          isConnected
          createdAt
          updatedAt
      }
}`;

function SheetMessage() {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const [selectedFile, setSelectedFile] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isScheduleProcess, setIsScheduleProcess] = useState(false);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isScheduleClicked, setIsScheduleClicked] = useState(false);
  const [sentMessage, setSentMessage] = useState(0);
  const [totalMessage, setTotalMessage] = useState(0);
  const [sheetTypeSelection, setSheetTypeSelection] = useState("")
  const [excelColumnValues, setExcelColumnValues] = useState(null)
  const [formatedExcelColumnValues, setFormatedExcelColumnValues] = useState(null)
  const [devices, setDevices] = useState([]);
  const [templates, setTemplates] = useState(null);
  const [excelSheetVisible, setExcelSheetVisible] = useState(false)
  const [excelSheetValues, setExcelSheetValues] = useState([])
  const [userProfileInfo, setUserProfileInfo] = useState({})
  const [templateSelected, setTemplateSelected] = useState(false)

  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const selectedDeviceRef = useRef([]);
  const cursorRef = useRef(null);
  const phoneRef = useRef(null);
  const quillRef = useRef(null);
  const dateTimeRef = useRef({});
  const fileRef = useRef(null);

  let start = 0;
  let phones = [];

  //GRAPHQL DEVICE QUERY START
  const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
    variables: { uid: userInfo.uid },
  });

  const { refetch, data, loading } = useQuery(DEVICE_GET, {
    variables: { uid: userInfo.uid },
  });

  const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
    variables: {
      uid: userInfo.uid
    }
  });

  const handleMessage = (e) => {
    setMessage((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const removeUnwantedSymbol = (message) => {
    // Remove all HTML entities (e.g., &nbsp;, &amp;, etc.)
    message = message.replace(/&[a-zA-Z0-9#]+;/g, " ");

    // Remove all placeholder words from the string
    message = message.replace(/\{\{[^}]+\}\}/g, "").replace(/\s{2,}/g, " ").trim();

    // Replace <em> tags with WhatsApp italic formatting
    message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

    // Replace <strong> tags with WhatsApp bold formatting, allowing optional spaces around the text
    message = message.replace(/<strong>\s*(.*?)\s*<\/strong>/g, '*$1*');

    // Replace <u> tags (underline) with emphasis (using _ for emphasis since underline isn't supported)
    message = message.replace(/<u>(.*?)<\/u>/g, '_$1_');

    // Ensure <p> tags add new lines
    message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
    message = message.replace(/<p>/g, ''); // Remove opening <p> tags

    // Convert ordered list <ol> into plain numbered list
    message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
      let formattedList = '\n'; // Newline before the list
      let counter = 1;
      // Process each <li> item
      listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
        formattedList += `${counter}. ${listItem}\n`; // Add numbering
        counter++;
      });
      return formattedList; // Return formatted list
    });

    // Convert unordered list <ul> into plain bullet list
    message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
      let formattedList = '\n'; // Newline before the list
      // Process each <li> item
      listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
        formattedList += `• ${listItem}\n`; // Add bullet points
      });
      return formattedList; // Return formatted list
    });

    // Remove any other remaining HTML tags
    message = message.replace(/<[^>]*>/g, '');

    // Handle adjacent formatting symbols and ensure no spaces are lost
    message = message.replace(/_([\s\S]+?)_|\*([\s\S]+?)\*/g, function (match, italic, bold) {
      if (italic) return `_${italic}_`;
      if (bold) return `*${bold}*`;
      return match;
    });

    return message.trim(); // Trim any trailing spaces or newlines
  }

  const convertPlaceholdersToLowercase = (text) => {
    return text.replace(/{{\s*([^}]+)\s*}}/g, (_, placeholder) => `{{${placeholder.toLowerCase()}}}`);
  }

  const formatWhatsAppMessage = (mesg, obj) => {

    const message = convertPlaceholdersToLowercase(mesg);

    // Create a map of object keys in lowercase for case-insensitive matching
    const objLowerCaseKeys = Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});

    //Select the object of selected record
    let msg = message.replace(/{{\s*([^}]+)\s*}}/gi, (_, key) => {
      // Access the object's property using the lowercase key
      const value = objLowerCaseKeys[key.trim().toLowerCase()];
      return value !== undefined ? value : `{{${key}}}`; // Keep placeholder if property not found
    });

    return removeUnwantedSymbol(msg)
  }

  const replacePlaceholders = (obj) => {
    // Create a map of object keys in lowercase for case-insensitive matching
    const objLowerCaseKeys = Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});

    // Access 'message' property in a case-insensitive way
    const messageKey = Object.keys(objLowerCaseKeys).find(key => key.toLowerCase() === 'message');

    if (!messageKey) {
      throw new Error("Message property not found.");
    }

    //Select the object of selected record
    return objLowerCaseKeys[messageKey].replace(/{{\s*([^}]+)\s*}}/gi, (_, key) => {
      // Access the object's property using the lowercase key
      const value = objLowerCaseKeys[key.trim().toLowerCase()];
      return value !== undefined ? value : `{{${key}}}`; // Keep placeholder if property not found
    });
  }

  const sendScheduleFileMessage = (deviceName, phone) => {
    const apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;
    const formData = new FormData();

    formData.append("id", userInfo.uid);
    formData.append("uid", userInfo.uid);
    formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
    formData.append("deviceName", selectedDeviceRef.current[0]);
    formData.append("file", selectedFile);
    formData.append("dateTime", dateTimeRef.current.value);
    formData.append("phones", phone);

    if (message.messageType === "defaultMessage") {

      formData.append("message", message.message);
    } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {
      formData.append("message", excelSheetValues[start].message);
    } else {
      setError("Message is not valid or it is blank")
      return
    }

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          ++start;
          setStatus(msgResp);

          //Set Msg sent count
          setSentMessage(start);

          if (phones && phones.length > start) {
            sendScheduleFileMessage(deviceName, phones[start]);
          } else {
            setStatus(msgResp);
          }
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const sendScheduleMessage = () => {

    let indexRef = 0;

    setIsScheduleProcess(true);

    let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

    if (
      excelSheetValues &&
      excelSheetValues.length > 0
    ) {
      if (isSelected) {
        if (excelSheetValues && excelSheetValues.length > 0) {
          setSentMessage(start);
          phones = phoneRef.current.value.trim().split(",")
          sendScheduleFileMessage(selectedDeviceRef.current[indexRef], phones[start]);
        } else {
          setError("Phone number is missing")
        }
      } else {
        try {
          const sendMsg = async (deviceName, phone) => {
            let data = {};

            if (message.messageType === "defaultMessage") {
              data = JSON.stringify({
                id: userInfo.uid,
                phones: phone,
                message: message.message,
                dateTime: dateTimeRef.current.value,
                cronString: CronStringGenerator(dateTimeRef.current.value),
                uid: userInfo.uid,
                deviceName: deviceName,
                file: ""
              });
            } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {
              data = JSON.stringify({
                id: userInfo.uid,
                phones: phone,
                message: excelSheetValues[start].message,
                dateTime: dateTimeRef.current.value,
                cronString: CronStringGenerator(dateTimeRef.current.value),
                uid: userInfo.uid,
                deviceName: deviceName,
                file: ""
              });
            } else {
              setError("Message is not valid or it is blank")
              return
            }

            let config = {
              method: "POST",
              url: apiURL,
              headers: {
                "Content-type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                let msgResp = response.data.message;
                let msgStatus = response.data.status;

                if (msgStatus !== "error") {

                  setStatus(msgResp);
                  ++start;

                  //Set Msg sent count
                  setSentMessage(start);

                  if (phones && phones.length > start) {
                    sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
                  } else {
                    setStatus(msgResp);
                    return
                  }
                } else {
                  setError(msgResp);
                }
              })

          }

          if (excelSheetValues && excelSheetValues.length > 0) {
            setSentMessage(start);
            phones = phoneRef.current.value.trim().split(",")
            sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
          } else {
            setError("Phone number is missing")
          }
        } catch (error) {
          setError("You are not authorized to make this request");
        }
      }

    } else {
      setError("Phone is not valid");
    }
  }

  const sendFileMessage = (deviceName, phone) => {
    const apiURL = `https://messagesapi.co.in/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("phone", phone);

    if (message.messageType === "defaultMessage") {
      formData.append("message", message.message);
    } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {
      formData.append("message", excelSheetValues[start].message);
    } else {
      setError("Message is not valid or it is blank")
      return
    }

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          ++start;
          setStatus(msgResp);

          //Set Msg sent count
          setSentMessage(start);

          if (phones && phones.length > start) {
            sendFileMessage(deviceName, phones[start]);
          } else {
            setStatus(msgResp);
          }
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const findTheSelectedObjectValue = (objArray, value) => {
    return objArray.find(obj => obj.phone === value);
  }

  const sendMessage = (e) => {

    e.preventDefault();

    let indexRef = 0;

    setIsClicked(true);

    let apiURL = `https://messagesapi.co.in/chat/sendMessage`;

    if (selectedDeviceRef.current && selectedDeviceRef.current.length === 0) {
      setError("Please select atleast one device");
      return
    }

    if (
      excelColumnValues
    ) {

      if (isSelected) {

        if (excelSheetValues && excelSheetValues.length > 0) {
          setSentMessage(start);
          phones = phoneRef.current.value.trim().split(",")
          sendFileMessage(selectedDeviceRef.current[indexRef], phones[start]);
        } else {
          setError("Phone number is missing")
        }
      } else {

        const sendMsg = (deviceName, phone) => {
          let data = {};

          if (excelSheetValues.length > 0 && excelSheetValues[start].message) {

            if (message.messageType === "defaultMessage") {

              data = JSON.stringify({
                "id": userInfo.uid,
                "name": deviceName,
                "phone": phone.toString(),
                "message": formatWhatsAppMessage(message.message, excelSheetValues[start])
              });

            } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {

              data = JSON.stringify({
                "id": userInfo.uid,
                "name": deviceName,
                "phone": phone.toString(),
                "message": replacePlaceholders(findTheSelectedObjectValue(formatedExcelColumnValues, excelSheetValues[start].phone))
              });

            } else {
              setError("Message is not valid or it is blank")
              return
            }

          } else {
            data = JSON.stringify({
              "id": userInfo.uid,
              "name": deviceName,
              "phone": phone.toString(),
              "message": message
            });
          }

          var config = {
            method: "POST",
            url: apiURL,
            headers: {
              "Content-type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              let msgResp = response.data.message;
              let msgStatus = response.data.status;

              if (msgStatus !== "error") {

                setStatus(msgResp);
                ++start;

                //Set Msg sent count
                setSentMessage(start);

                if (phones && phones.length > start) {
                  sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
                } else {
                  setStatus(msgResp);
                  return
                }
              } else {
                setError(msgResp);
              }
            })
            .catch(function (error) {
              setError(error.response.data.message);
            });
        }

        if (excelSheetValues && excelSheetValues.length > 0) {
          setSentMessage(start);
          phones = phoneRef.current.value.trim().split(",")
          sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
        } else {
          setError("Phone number is missing")
        }

      }

    } else {
      setError("Phone is not valid");
    }
  };

  const handleSelectedDevice = (e) => {

    if (e.target.value) {
      selectedDeviceRef.current.push(e.target.value)
    } else {
      selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
    }

    if (selectedDeviceRef.current.length > 0) {
      setIsDeviceSelected(true)
    } else {
      setIsDeviceSelected(false)
    }
  }

  const handleSheetTypeSelection = (e) => {
    if (e.target.value) {

      if (
        userProfileInfo.planType &&
        (
          userProfileInfo.planType.includes("UL") ||
          userProfileInfo.planType.includes("WG") ||
          userProfileInfo.planType.includes("GS") ||
          userProfileInfo.planType.includes("RC")
        )
      ) {

        setSheetTypeSelection(e.target.value)
      } else {

        setError("You are not a valid user for this service")
      }

    }
  }

  const handleFileAttachmentReset = () => {
    if (fileRef.current) {
      fileRef.current.value = "";
      fileRef.current.type = "text";
      fileRef.current.type = "file";
    }
  };

  const handleQuillChange = (value) => {
    setMessage((prevData) => {
      return { ...prevData, "message": value };
    });
  };

  const handleSelectionChange = useCallback(() => {
    const editor = quillRef.current.getEditor(); // Get Quill editor instance
    const selection = editor.getSelection();

    if (selection) {
      cursorRef.current = selection.index; // Update cursor position

    }

  }, []);

  const handleSelectTemplate = (e) => {
    if (e.target.value) {
      setMessage((prevData) => {
        return { ...prevData, "message": e.target.value };
      });

      setTemplateSelected(!templateSelected)

    } else {
      setMessage((prevData) => {
        return { ...prevData, "message": "" };
      });
      setTemplateSelected(!templateSelected)
    }
  }

  const insertEmoji = (emoji) => {
    const editor = quillRef.current.getEditor();
    const cursorPosition = cursorRef.current;

    console.log("emoji", emoji)
    console.log("cursorPosition", cursorPosition)

    if (cursorPosition !== undefined) {
      editor.insertText(cursorPosition, emoji);
    }
  };

  const resetAll = () => {

    setSelectedFile("")
    setIsSelected(false)
    setIsScheduleProcess(false)
    setIsClicked(false)
    setIsScheduleClicked(false)
    setSentMessage(0)
    setTotalMessage(0)
    setSheetTypeSelection("")
    setExcelColumnValues(null)

    if (selectedDeviceRef.current && selectedDeviceRef.current?.value) selectedDeviceRef.current.value = "";
    if (cursorRef.current && cursorRef.current?.value) cursorRef.current.value = "";
    if (phoneRef.current && phoneRef.current.value) phoneRef.current.value = "";
    if (quillRef.current && quillRef.current.value) quillRef.current.value = "";
    if (dateTimeRef.current && dateTimeRef.current.value) dateTimeRef.current.value = "";
    if (fileRef.current && fileRef.current.value) fileRef.current.value = "";

    refetch({ uid: userInfo.uid })
  }

  // useEffects

  useEffect(() => {
    let userProfileData = null;

    if (userProfile) {
      userProfileData = userProfile.user;
      setUserProfileInfo(userProfileData);
    }
  }, [userProfile, userInfoLoading]);

  useEffect(() => {
    if ((status !== "" || error !== "") && sentMessage === totalMessage) {
      setTimeout(() => {
        resetAll();
        handleFileAttachmentReset();
        setMessage("")
        setError("")
        setStatus("")
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status, sentMessage]);

  //FETCH ALL ADDED DEVICES
  useEffect(() => {
    let devicesByUID = "";
    if (data) {
      devicesByUID = data.devicesByUID;
      setDevices(devicesByUID);
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      status !== "" || error !== ""
    ) {
      setTimeout(() => {
        setIsClicked(false)
        resetAll()
        document.getElementById("sheetmessage").reset();
      }, 2500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status]);

  useEffect(() => {
    let userProfileData = null;

    if (userProfile) {
      userProfileData = userProfile.user;
      setUserProfileInfo(userProfileData);

      if (
        !(
          userProfileData.planType &&
          (
            userProfileData.planType.includes("WG") ||
            userProfileData.planType.includes("GS") ||
            userProfileData.planType.includes("RC")
          )
        )
      ) {
        setError("You are not a valid user for this service")
      }
    }
  }, [userProfile, userInfoLoading]);

  useEffect(() => {

    if (templateData) {
      let data = templateData.templateByUID;

      setTemplates(data);
    }
  }, [templateData, templateLoading]);

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    editor.on('selection-change', handleSelectionChange);

    return () => {
      editor.off('selection-change', handleSelectionChange);
    };

  }, [handleSelectionChange]);

  useEffect(() => {
    let phones = []
    if (excelSheetValues && excelSheetValues.length > 0) {
      phones = excelSheetValues.map(value => value.phone)
      phoneRef.current.value = phones
      setTotalMessage(phoneRef.current.value.trim().split(",").length)
    }
  }, [excelSheetValues])

  return (
    <Container>
      <form id="sheetmessage" className="bg-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-4 pt-8">
          {/* Left Section */}
          <div className="md:col-span-9 h-screen overflow-y-scroll p-4">
            <div>
              <p className="text-green-400 text-center pb-4 text-xl font-semibold">IMPORT SHEET</p>
            </div>
            <div className="w-full flex flex-col space-y-4">
              <div className="grid grid-cols-1 gap-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <p className="text-sm font-semibold">CONNECTED DEVICE:</p>
                  <select onChange={handleSelectedDevice}>
                    <option value="" key="select device">Select device</option>
                    {devices &&
                      devices.map((device) => (
                        <option key={device.deviceName} value={device.deviceName}>
                          {device.deviceName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <p className="text-sm font-semibold">SHEET TYPE:</p>
                  <select onChange={handleSheetTypeSelection}>
                    <option value="" key="select sheet">Select sheet</option>
                    <option value="excel sheet" key="excel sheet">Excel Sheet</option>
                    <option value="google sheet" key="google sheet">Google Sheet</option>
                  </select>
                </div>
              </div>

              {/* Select Template */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                  <p className="text-sm font-semibold">
                    SELECT TEMPLATE:
                  </p>
                </div>
                <div>
                  <select onChange={handleSelectTemplate}>
                    <option name="" value="" key="Select Template">
                      Select Template
                    </option>
                    {templates &&
                      templates.map((template) => (
                        <option
                          name={template.templateName}
                          key={template.templateName}
                          value={template.body}
                        >
                          {template.templateName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              {/* Sheet Handlers */}
              <div className="grid grid-cols-1 gap-4">
                {sheetTypeSelection === "google sheet" && (
                  <div className="flex justify-start">
                    <GoogleSheetHandler setExcelColumnValues={setExcelColumnValues} setFormatedExcelColumnValues={setFormatedExcelColumnValues} userProfile={userProfileInfo} />
                  </div>
                )}
                {sheetTypeSelection === "excel sheet" && (
                  <div className="flex gap-2 justify-start">
                    <ExcelSheetHandler setExcelColumnValues={setExcelColumnValues} setFormatedExcelColumnValues={setFormatedExcelColumnValues} />
                  </div>
                )}
              </div>

              <div className="flex flex-wrap items-center gap-2">
                {excelColumnValues?.name && excelColumnValues?.phone && excelColumnValues?.message ? (
                  <button
                    className="bg-blue-400 px-2 py-1 text-xs w-auto rounded-md text-white"
                    disabled={error !== ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setExcelSheetVisible(!excelSheetVisible);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <View />
                      <span>View Contacts</span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="px-2 py-1 text-xs w-auto rounded-md text-white disabled bg-neutral-500"
                    disabled={error !== ""}
                  >
                    <div className="flex items-center gap-2">
                      <View />
                      <span>Map All Columns</span>
                    </div>
                  </button>
                )}
              </div>
              {excelSheetVisible && <ExcelSheetView className="w-full" excelColumnValues={excelColumnValues} setExcelSheetValues={setExcelSheetValues} setExcelSheetVisible={setExcelSheetVisible} />}

              <input
                autoComplete="chrome-off"
                type="text"
                name="phone"
                id="phone"
                className="w-full rounded-md px-2"
                onChange={(e) => {
                  e.preventDefault();
                  handleMessage(e);
                }}
                ref={phoneRef}
                disabled={isClicked}
                readOnly
              />

              {/* Responsive Radio Buttons */}
              <div className="flex flex-wrap justify-between items-center gap-2">
                <div>
                  <label htmlFor="message" className="text-sm font-serif font-semibold text-gray-600">
                    <span className="text-sm text-blue-500 font-semibold">Que: {totalMessage}</span>{" "}
                    |{" "}
                    <span className="text-sm text-green-500 font-semibold">Sent: {sentMessage}</span>
                  </label>
                </div>
                <div className="flex gap-2">
                  <label className="flex items-center text-gray-600 gap-2">
                    <input
                      type="radio"
                      name="messageType"
                      value="defaultMessage"
                      onChange={handleMessage}
                      checked={message.messageType === "defaultMessage" || templateSelected}
                    />
                    <span>Template Message</span>
                  </label>
                  <label className="flex items-center text-gray-600 gap-2">
                    <input
                      type="radio"
                      name="messageType"
                      value="sheetMessage"
                      onChange={handleMessage}
                      checked={message.messageType === "sheetMessage"}
                    />
                    <span>Sheet Message</span>
                  </label>
                </div>
              </div>

              {/* Text Editor */}
              <div className="w-full rounded-md p-2 bg-white h-[200px]">
                <ReactQuill
                  style={{ height: "150px" }}
                  ref={quillRef}
                  name="message"
                  onChange={handleQuillChange}
                  value={message?.message}
                  readOnly={isClicked || message.messageType === "sheetMessage" || templateSelected}
                  placeholder="Default Message"
                  theme="snow"
                />
              </div>

              {/* File Upload */}
              <label htmlFor="file" className="text-sm font-semibold text-gray-600 uppercase">
                Attach File:
              </label>
              <input
                type="file"
                accept=".txt, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="file"
                onChange={changeHandler}
                ref={fileRef}
              />

              {/* File Details */}
              <p className="text-xs font-semibold text-green-400 uppercase pb-2">About File</p>
              <div>
                <p>Filename: {selectedFile.name || ""}</p>
                <p>Filetype: {selectedFile.type || ""}</p>
                <p>Size in bytes: {selectedFile.size || ""}</p>
                <p>
                  lastModifiedDate:{" "}
                  {selectedFile.lastModifiedDate ? selectedFile.lastModifiedDate.toLocaleDateString() : ""}
                </p>
              </div>

              {/* Schedule Options */}
              {isScheduleClicked && (
                <div>
                  <label htmlFor="dateTime" className="text-sm font-semibold text-gray-600 uppercase my-2">
                    Scheduled
                  </label>
                  <input
                    type="datetime-local"
                    name="dateTime"
                    id="dateTime"
                    className="w-full rounded-md p-2"
                    onChange={handleMessage}
                    ref={dateTimeRef}
                  />
                </div>
              )}

              <div className="my-2">
                {status !== "" ? (
                  <p className="text-center text-sm tracking-normal text-green-500">
                    {status && <Alerts alertType="Info">{status}</Alerts>}
                  </p>
                ) : error !== "" ? (
                  <p className="text-center text-sm tracking-normal text-red-500">
                    {error && <Alerts alertType="Error">{error}</Alerts>}
                  </p>
                ) : (
                  ""
                )}
              </div>

              {/* Action Buttons */}
              <div className="my-2 text-right">
                <button
                  className="rounded-md bg-orange-400 py-1 px-4 text-sm text-white"
                  onClick={sendMessage}
                  disabled={isClicked || isScheduleClicked || !isDeviceSelected || error !== ""}
                >
                  {!isDeviceSelected ? "Select device" : isClicked ? "Sending..." : "Send Now"}
                </button>
                {!isScheduleClicked && (
                  <button
                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-4"
                    onClick={() => setIsScheduleClicked(true)}
                    disabled={isClicked || isScheduleClicked || !isDeviceSelected || error !== ""}
                  >
                    Schedule
                  </button>
                )}
                {isScheduleClicked && !isScheduleProcess && (
                  <button
                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-4"
                    disabled={!dateTimeRef.current.value}
                    onClick={() => sendScheduleMessage()}
                  >
                    Set Schedule
                  </button>
                )}
                {isScheduleClicked && isScheduleProcess && (
                  <button className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-4">Please Wait...</button>
                )}
                <button
                  className="rounded-md bg-red-400 py-1 px-4 text-sm text-white ml-4"
                  onClick={resetAll}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="md:col-span-3 hidden md:block">
            <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
          </div>
        </div>
      </form>
    </Container>
  );
}

export default SheetMessage;
