import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import axios from 'axios';
import "../../App.css"
import Footer from "../../components/Footer";

const domain = window.location.host;

function Policy() {
    const [data, setData] = useState("");

    const getData = () => {
        let data = JSON.stringify({
            "domain": `${domain}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://messageapiwl.com/user/userInfoByDomain',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {

                let data = response.data;

                if (data.status !== "error") {

                    setData(data.message)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getData();
    }, [])

    return (

        <div>
            <Nav />
            <div className="flex flex-col items-center w-2/3 m-auto p-8 rounded-2xl divide-y-2 divide-dashed mt-4">
                {
                    data && (
                        <div className="content" dangerouslySetInnerHTML={{ __html: data.policy }}></div>
                    )
                }

            </div>
            <Footer />
        </div>
    )

}

export default Policy