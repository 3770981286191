import React, { useState } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar';

function Container({ children }) {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="flex">
            {/* Sidebar */}
            <div
                className={`fixed z-20 inset-y-0 left-0 w-64 bg-blue-500 transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } transition-transform duration-300 ease-in-out lg:translate-x-0`}
            >
                <Sidebar />
            </div>

            {/* Overlay (for mobile sidebar) */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 z-10 bg-black opacity-50 lg:hidden"
                    onClick={() => setSidebarOpen(false)}
                ></div>
            )}

            {/* Main Content */}
            <div className="flex-1 lg:ml-64">
                {/* Navbar */}
                <div className="bg-gray-200 h-10 flex items-center">
                    {/* Burger Button */}
                    <button
                        className="lg:hidden p-2 text-gray-600"
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                    </button>
                    <Navbar />
                </div>

                {/* Content */}
                <div className="h-[calc(100vh-40px)]">
                    {/* Your content */}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Container
