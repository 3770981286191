import React, { useEffect, useState } from "react";
import illustration from './illustration.png'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Alerts from "../../components/Alerts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Google } from '@mui/icons-material';
import Logo from "./logo.png"

const CREATE_USER = gql`
  mutation CreateUser($user: UserInput!) {
    userCreate(user: $user) {
      userErrors {
        message
      }
      user {
        name
        uid
        email
      }
    }
  }
`;

const GET_USER_BY_MAIL = gql`
  query($email: String!){
    userByEmail(email: $email) {
      name
    }
  }
`;

const GET_USER_BY_MOBILE = gql`
  query($mobile: String!){
    userByMobile(mobile: $mobile) {
      name
    }
  }
`;

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    employeeCode: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const navigate = useNavigate();

  const handleFormInput = (e) => {
    const id = e.target.name;
    const value = e.target.value;

    setFormData((prevData) => {
      return { ...prevData, [id]: value };
    });
  };

  const [getUserByEmail] = useLazyQuery(GET_USER_BY_MAIL)

  const [getUserByMobile] = useLazyQuery(GET_USER_BY_MOBILE)

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: () => {
      handleWelcome();
      setSuccess("Account has been created successfully!")
    },
    onError: (error) => {
      if (
        error.message === "Unique constraint failed on the fields: (`email`)"
      ) {
        setError("Email is already registered");
      } else {
        setError(error.message);
      }

    },
  });

  const handleWelcome = () => {

    let data = JSON.stringify({
      "subject": "Account Created",
      "fromEmail": "info@messageapi.in",
      "toEmail": formData.email,
      "html": `<!doctype html>
<html lang="en-US">

<head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <title>New Account Email </title>
    <meta name="description" content="New Account Email .">
    <style type="text/css">
        a:hover {
            text-decoration: underline !important;
        }
    </style>
</head>

<body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px;" leftmargin="0">
    <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8" style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
            <td>
                <table style="max-width:670px; margin:0 auto;" width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <a href="https://messagesapi.co.in/" title="logo" target="_blank">
                            <img width="60" src="https://user.messageapi.in/static/media/logo.43a695fe1a86c9e871c5.png" title="logo" alt="logo">
                          </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0" style="max-width:670px; background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <!DOCTYPE html>
                                    <html>

                                    <head>
                                        <style>
                                            body {
                                                font-family: Arial, sans-serif;
                                                margin: 0;
                                                padding: 0;
                                                background-color: #f4f4f4;
                                                color: #333;
                                            }

                                            .email-container {
                                                max-width: 600px;
                                                margin: 20px auto;
                                                background-color: #fff;
                                                border-radius: 8px;
                                                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                                                overflow: hidden;
                                            }

                                            .header {
                                                background-color: #06A249;
                                                color: #fff;
                                                padding: 20px;
                                                text-align: center;
                                            }

                                            .header h1 {
                                                margin: 0;
                                                font-size: 24px;
                                            }

                                            .content {
                                                padding: 20px;
                                            }

                                            .content h2 {
                                                color: #06A249;
                                                font-size: 20px;
                                                margin-bottom: 15px;
                                            }

                                            .content p {
                                                line-height: 1.6;
                                                margin-bottom: 15px;
                                            }

                                            .content ul {
                                                list-style: disc;
                                                padding-left: 20px;
                                                margin: 15px 0;
                                            }

                                            .highlight {
                                                background-color: #E9F7EF;
                                                padding: 10px;
                                                border-left: 4px solid #06A249;
                                                margin: 15px 0;
                                            }

                                            .footer {
                                                background-color: #f4f4f4;
                                                text-align: center;
                                                padding: 15px;
                                                font-size: 14px;
                                                color: #777;
                                            }

                                            .footer a {
                                                color: #007bff;
                                                text-decoration: none;
                                            }
                                        </style>
                                    </head>

                                    <body>
                                        <div class="email-container">
                                            <div class="header">
                                                <h1>Welcome to Messageapi</h1>
                                            </div>
                                            <div class="content">
                                                <h2>Dear ${formData.name},</h2>
                                                <p>Congratulations on taking the first step to supercharge your WhatsApp Web with some amazing new features!</p>
                                                <div class="highlight">
                                                    <strong>WhatsApp is now integrated with your Google Sheet!</strong>
                                                    <p>Google Sheets and WhatsApp are the best tools for managing business leads. At messageapi.in, we have combined both tools to create a smart lead campaign functionality for businesses. Now lead conversion
                                                        is easier with the option to send WhatsApp messages directly from Google Sheets without saving numbers on your device.</p>
                                                </div>
                                                <div class="highlight">
                                                    <strong>Unlock Business Data from WhatsApp Groups</strong>
                                                    <p>WhatsApp groups are the new source of verified business leads. Download, extract, and export contacts from any group to create new leads. Schedule and broadcast campaigns to multiple group members directly
                                                        from your dashboard.</p>
                                                </div>
                                                <div class="highlight">
                                                    <strong>Export Chats into Any CRM</strong>
                                                    <p>Capture replies in group chats and forward them to your CRM or view them in the dashboard. Monitor team performance and analyze individual activities over WhatsApp.</p>
                                                </div>
                                                <div class="highlight">
                                                    <strong>Experience AI Smart Payment Reminders</strong>
                                                    <p>Tally users can now enjoy a smart business payment reminder feature. Segregate due invoices by age and manage outstanding payments on a FIFO basis, even with receipts maintained "on account".</p>
                                                </div>
                                                <div class="highlight">
                                                    <strong>Link Multi-Device and Add Multiple Team Members</strong>
                                                    <p>MessageAPI 2.0 enables multi-device functionality, allowing seamless access to customer support and sales teams from one account.</p>
                                                </div>
                                                <div class="highlight">
                                                    <strong>RCS Campaigns Without Number Blockage</strong>
                                                    <p>Run unlimited campaigns with RCS (Rich Communication Services) for new launches. Enjoy a hassle-free experience with no restrictions or number blockages.</p>
                                                </div>
                                                <p>Our team is here to help you with testing and onboarding each product type. Please feel free to call us for support.</p>
                                            </div>
                                            <div class="footer">
                                                <p>&copy; 2024 messageapi.in. All Rights Reserved.</p>
                                                <p>Need assistance? <a href="mailto:support@messageapi.in">Contact us</a></p>
                                            </div>
                                        </div>
                                    </body>

                                    </html>

                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>
</body>

</html>`,
      "attachments": []
    });

    let config = {
      method: 'post',
      url: `https://messagesapi.co.in/mail/sendmail`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        setTimeout(() => { navigate("/login"); }, 10000)
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility);
  };

  const handleMerchantExpiry = (e) => {
    e.preventDefault();

    handleSignup();

  }

  const handleSignup = async () => {

    setIsPending(true);

    //CHECK MAIL IS AVAILABLE
    let isMailAvailable = await getUserByEmail({
      variables: {
        email: formData.email.toLowerCase().trim()
      }
    });

    //CHECK MOBILE IS AVAILABLE
    let isMobileAvailable = await getUserByMobile({
      variables: {
        mobile: formData.mobile.trim()
      }
    });

    if (isMailAvailable.data) {
      setError("Duplicate email is not allowed :(")

      return
    }

    if (isMobileAvailable.data) {
      setError("Duplicate mobile is not allowed :(")

      return
    }

    if (formData.password !== formData.confirm_password) {
      setError("Password not matched");

    } else {
      //SAVE DATA IN DATABASE START
      let uuid = uuidv4();

      createUser({
        variables: {
          user: {
            uid: uuid.replace(/[^a-zA-Z0-9]/g, ""),
            resellerId: "",
            employeeCode: formData.employeeCode,
            name: formData.name,
            email: formData.email.toLowerCase().trim(),
            mobile: formData.mobile.trim(),
            password: formData.password,
            totalMessages: 30,
            totalMessagesSent: 0,
            userType: "User",
            planType: "DEMO",
            expireInDays: 3,
            isActive: true,
          },
        },
      });

      //SAVE DATA IN DATABASE END
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("");
      setIsPending(false);
    }, 5000)
  }, [error, success])

  return (
    <div className="min-h-screen flex flex-col md:flex-row ">
      {/* Left side - Login Form */}
      <div className="w-full md:w-1/2 bg-blue-50 md:flex justify-center items-center p-6 md:p-8 relative hidden">
        {/* Illustration Image */}
        <div className="text-center">
          <img
            src={illustration} // Replace with your image
            alt="Illustration"
            className="mx-auto mb-6 max-w-full"
          />
        </div>
      </div>

      {/* Right side - Image and Update Info */}
      <div className="w-full md:w-1/2 bg-white flex flex-col justify-center items-center p-6 md:p-8 ">
        <div className="w-full max-w-lg">
          {/* Logo */}
          <div className="text-center mb-6">
            <img
              src={Logo} // Replace this with your logo
              alt="MRK Group"
              className="mx-auto max-h-12"
            />
          </div>
          {/* Welcome Text */}
          <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 uppercase text-green-400">
            Welcome Back
          </h2>

          {/* Google Login Button */}
          <button className="w-full bg-blue-500 border border-gray-300 text-white py-2 rounded-lg flex items-center justify-center gap-2 mb-6">
            <Google />
            <span>Sign up with Google</span>
          </button>

          {/* Divider */}
          <div className="flex items-center mb-4">
            <hr className="flex-grow border-gray-300" />
            <span className="mx-2 text-gray-400 text-sm">OR SIGN UP WITH EMAIL</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          {/* Signup Form */}
          <form onSubmit={handleMerchantExpiry}>
            <div>
              <label htmlFor="name">Name</label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={(e) => handleFormInput(e)}
                  required
                  className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div>
                <label htmlFor="email">Email</label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="mobile">Mobile</label>
                <div className="mt-1">
                  <input
                    id="mobile"
                    name="mobile"
                    type="number"
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    id="password"
                    name="password"
                    type={passwordVisibility ? "text" : "password"}
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                  {/* Password visibility toggle */}
                  {passwordVisibility ? (
                    <VisibilityOffIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handlePasswordVisibility()}
                    />
                  ) : (
                    <VisibilityIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handlePasswordVisibility()}
                    />
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirm_password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <div className="relative mt-1">
                  <input
                    id="confirm_password"
                    name="confirm_password"
                    type={confirmPasswordVisibility ? "text" : "password"}
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                  {/* Confirm password visibility toggle */}
                  {confirmPasswordVisibility ? (
                    <VisibilityOffIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handleConfirmPasswordVisibility()}
                    />
                  ) : (
                    <VisibilityIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handleConfirmPasswordVisibility()}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center my-4">
              <input
                id="terms-and-privacy"
                name="terms-and-privacy"
                type="checkbox"
                required
                className="h-4 w-4"
              />
              <label
                htmlFor="terms-and-privacy"
                className="ml-2 block text-sm text-gray-900"
              >
                I agree to the
                <a
                  href="/aggrement"
                  className="mx-2 text-blue-400 hover:text-blue-500"
                >
                  Terms
                </a>
                and
                <a href="/" className="ml-2 text-blue-400 hover:text-blue-500">
                  Privacy Policy
                </a>
                .
              </label>
            </div>

            <div className="text-center">
              {!isPending ? (
                <button
                  type="submit"
                  className="w-full rounded-md bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full rounded-md bg-blue-500 py-2 px-4 text-sm font-medium text-white"
                  disabled
                >
                  Wait....
                </button>
              )}
              {error && <Alerts alertType="Error">{error}</Alerts>}
              {success && <Alerts alertType="Success">{success}</Alerts>}
            </div>
          </form>

          {/* Signup Link */}
          <p className="text-center mt-6">
            Already have an account?{" "}
            <Link to="/login" className="text-blue-400 hover:underline">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
