import React, { useCallback, useEffect, useRef } from 'react';
import useTemplate from './useTemplate';
import Container from '../../components/Container';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DataTable from '../../components/DataTable';
import Alerts from '../../components/Alerts'
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import Emojis from "../../util/Emojis";

const Template = () => {
    const quillRef = useRef(null);
    const cursorRef = useRef(0);


    const { formData, handleChange, handleSave, handleUpdate, setUpdateClicked, updateClicked, handleCancel, records, error, success, handleEdit, handleDelete } = useTemplate();

    const columns = [
        { field: "id", headerName: "ID", minWidth: 250, align: "left" },
        { field: "createdAt", headerName: "Date Time", minWidth: 250, align: "left" },
        { field: "templateName", headerName: " Name", minWidth: 180, align: "left" },
        { field: "category", headerName: "Category", minWidth: 150, align: "left" },
        { field: "language", headerName: "Language", minWidth: 150, align: "left" },
        { field: "body", headerName: "Message", minWidth: 350, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={< Edit />}
                    label="Edit"
                    onClick={() => {
                        setUpdateClicked(true);
                        handleEdit(params.row);
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteOutlineIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    // Handler for Quill input
    const handleQuillChange = (value) => {
        handleChange({
            target: {
                name: 'body',
                value: value,
            },
        });
    };

    const insertEmoji = (emoji) => {
        const editor = quillRef.current.getEditor();
        const cursorPosition = cursorRef.current;

        if (cursorPosition !== undefined) {
            editor.insertText(cursorPosition, emoji);
        }
    };

    const handleSelectionChange = useCallback(() => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        const selection = editor.getSelection();
        if (selection) {
            cursorRef.current = selection.index; // Update cursor position
        }
    }, []);

    useEffect(() => {
        const editor = quillRef.current.getEditor();
        editor.on('selection-change', handleSelectionChange);

        return () => {
            editor.off('selection-change', handleSelectionChange);
        };

    }, [handleSelectionChange]);

    useEffect(() => {
        if (error || success) {
            setTimeout(() => {
                document.getElementById("template").reset();
            }, 3000)
        }
    }, [error, success])

    return (
        <Container>
            <div className="grid grid-cols-12 gap-2 px-4 pt-8 h-screen">
                {/* Form Section */}
                <div className="col-span-12 md:col-span-8">
                    <form id="template">
                        <div className="space-y-2">
                            {error && <Alerts alertType="Error">{error}</Alerts>}
                            {success && <Alerts alertType="Success">{success}</Alerts>}

                            <div className="bg-white rounded-lg shadow-md p-4">
                                <p className="text-md mb-4 text-gray-600 text-center">CREATE TEMPLATE</p>

                                {/* Hidden Input */}
                                <div>
                                    <input
                                        type="text"
                                        name="id"
                                        value={formData.id}
                                        hidden
                                        readOnly
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    />
                                </div>

                                {/* Name Input */}
                                <div className="mb-4">
                                    <label className="block text-gray-600 mb-1">Name</label>
                                    <input
                                        type="text"
                                        name="templateName"
                                        value={formData.templateName}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    />
                                </div>

                                {/* Category Dropdown */}
                                <div className="mb-4">
                                    <label className="block text-gray-600 mb-1">Category</label>
                                    <select
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    >
                                        <option value="">Select Category</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Support">Support</option>
                                        <option value="Sales">Sales</option>
                                        <option value="Offer">Offer</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                {/* Language Dropdown */}
                                <div className="mb-4">
                                    <label className="block text-gray-600 mb-1">Language</label>
                                    <select
                                        name="language"
                                        value={formData.language}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    >
                                        <option value="">Select Language</option>
                                        <option value="English (US)">English (US)</option>
                                        <option value="English (UK)">English (UK)</option>
                                        <option value="Local Language">Local Language</option>
                                    </select>
                                </div>

                                {/* Message Editor */}
                                <div className="mb-4">
                                    <label className="block text-gray-600 mb-1">Message</label>
                                    <ReactQuill
                                        ref={quillRef}
                                        value={formData.body}
                                        onChange={handleQuillChange}
                                        className="w-full border border-gray-300 rounded-lg"
                                        theme="snow"
                                    />
                                </div>

                                {/* Buttons */}
                                <div className="flex justify-end space-x-4">
                                    <button
                                        onClick={handleCancel}
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-600 font-bold py-1 px-2 text-xs rounded"
                                    >
                                        Cancel
                                    </button>
                                    {!updateClicked ? (
                                        <button
                                            onClick={handleSave}
                                            className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-1 px-2 text-xs rounded"
                                        >
                                            Save
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleUpdate}
                                            className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-1 px-2 text-xs rounded"
                                        >
                                            Update
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Data Table */}
                            <div>
                                {records && records.length >= 0 ? (
                                    <DataTable columns={columns} data={records} />
                                ) : (
                                    <p className="text-xl font-semibold text-red-500">No records found</p>
                                )}
                            </div>
                        </div>
                    </form>
                </div>

                {/* Emojis Section */}
                <div className="md:col-span-3 hidden md:block">
                    <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
                </div>
            </div>
        </Container>

    );
};

export default Template;
