import React, { useState, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import DataTable from "../../components/DataTable";
import Container from "../../components/Container";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { SearchOffOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Alerts from "../../components/Alerts";

const GET_GROUP_IN_MESSAGE = gql`
    query getGroupInMessagesByID($to: String!, $groupAdmin: String!) {
        groupInMessageByID(to:$to, groupAdmin:$groupAdmin) {
            id
            uid
            deviceName
            groupName
            groupAdmin
            from
            to
            message
        }
    }
`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

export default function GroupInMessage() {
    const navigate = useNavigate();
    const [userProfileInfo, setUserProfileInfo] = useState({})

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };


    const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    const [{ data: groupInMessageData, loading: groupInMessageLoading }] = useLazyQuery(GET_GROUP_IN_MESSAGE);

    const [error, setError] = useState("")
    const [messagesInData, setMessagesInData] = useState("");

    const columns = [
        { field: "id", headerName: "ID", minWidth: 50, align: "left" },
        { field: "uid", headerName: "UID", minWidth: 50, align: "left" },
        { field: "deviceName", headerName: "Device", minWidth: 50, align: "left" },
        { field: "groupName", headerName: "Group Name", minWidth: 170, align: "left" },
        { field: "groupAdmin", headerName: "Group Admin", minWidth: 170, align: "left" },
        { field: "from", headerName: "From", minWidth: 250, align: "left" },
        { field: "to", headerName: "To", minWidth: 300, align: "left" },
        { field: "message", headerName: "Message", minWidth: 170, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<SearchOffOutlined />}
                    label="View"
                    onClick={() => navigate(
                        "/groupInMessageView", { state: { ...params.row } }
                    )}
                />,
            ]
        },
    ];

    useEffect(() => {
        if (groupInMessageData) {

            setMessagesInData(groupInMessageData.groupInMessageByID);
        }
    }, [groupInMessageData, groupInMessageLoading]);

    useEffect(() => {
        let userProfileData = null;

        if (userProfile) {
            userProfileData = userProfile.user;
            setUserProfileInfo(userProfileData);

            if (
                !(
                    userProfileData.planType &&
                    (
                        userProfileData.planType.includes("RC")
                    )
                )
            ) {
                setError("You are not a valid user for this service")
            }
        }
    }, [userProfile, userInfoLoading]);

    return (
        <Container>
            <div>
                <Alerts alertType={"Error"}>{error}</Alerts>
            </div>
            <div>
                <p className="text-gray-600 flex justify-center items-center py-4 text-xl font-semibold">REPLIES HISTORY</p>
            </div>

            {
                userProfileInfo.planType &&
                (
                    userProfileInfo.planType.includes("RC")
                ) && (
                    <div className="grid grid-cols-12 gap-2  p-4 pt-0 h-screen overflow-y-scroll no-scrollbar">
                        <div className="col-span-12">
                            {!groupInMessageLoading && (
                                <div className="mb-4">
                                    <DataTable columns={columns} data={messagesInData} />
                                </div>
                            )}
                        </div>
                    </div>
                )
            }

        </Container>
    );
}
