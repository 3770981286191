import { useState } from 'react';
import * as XLSX from 'xlsx';

function useExcelSheet() {
    const [sheetNames, setSheetNames] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [selectedHeader, setSelectedHeader] = useState('');
    const [formattedData, setFormattedData] = useState([]);
    const [columnValues, setColumnValues] = useState(null);
    const [workbook, setWorkbook] = useState(null); // Store the workbook here

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0]; // Get the first sheet name
            const sheet = workbook.Sheets[sheetName]; // Get the sheet data

            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert to array of arrays
            const headers = jsonData[0]; // Assume the first row contains headers
            const rows = jsonData.slice(1); // Remaining rows contain data

            const formattedData = rows
                .map(row => {
                    const rowObject = {};
                    headers.forEach((header, index) => {
                        rowObject[header.toLowerCase()] = row[index] || null; // Assign data to headers
                    });
                    return rowObject;
                })
                .filter(rowObject =>
                    Object.values(rowObject).some(value => value !== null)
                ); // Exclude rows with all properties null

            setFormattedData(formattedData); // Log the filtered object-formatted data
            setWorkbook(workbook); // Set the workbook state
            setSheetNames(workbook.SheetNames);
            // You can also store `formattedData` in a state if needed
        };

        reader.readAsArrayBuffer(file);
    };

    const getColumnHeaders = (sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] || [];
        setColumnHeaders(headers);
    };

    const getColumnValues = (sheetName, headerName, mapColumn) => {
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const headerIndex = json[0].indexOf(headerName);

        if (headerIndex !== -1) {
            const values = json.slice(1).map(row => row[headerIndex]);
            setColumnValues(prevData => {
                return {
                    ...prevData,
                    [mapColumn]: values
                }
            });
        } else {
            console.error(`Header "${headerName}" not found.`);
            setColumnValues({});
        }
    };

    const fetchColumnHeaders = (sheetName) => {
        setSelectedSheet(sheetName);
        getColumnHeaders(sheetName);
    };

    const fetchColumnValues = (header, mapColumn) => {

        if (selectedSheet) {
            setSelectedHeader(header);
            getColumnValues(selectedSheet, header, mapColumn);
        }
    };

    return {
        handleFileUpload,
        sheetNames,
        columnHeaders,
        columnValues,
        fetchColumnHeaders,
        fetchColumnValues,
        formattedData
    };
}

export default useExcelSheet;
