import React, { useEffect, useState } from 'react'
import logo from "./logo.png";
import { Email, Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import axios from 'axios';

const domain = window.location.host;

function Footer() {

    const [data, setData] = useState("");

    const getData = () => {
        let data = JSON.stringify({
            "domain": `${domain}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://messageapiwl.com/user/userInfoByDomain',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {

                let data = response.data;

                if (data.status !== "error") {

                    setData(data.message)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <footer className="mt-40 px-4 divide-y bg-gray-200 text-coolGray-800 border-t-2 border-green-300 border-dashed">
            <div className="container flex flex-col justify-between py-10 space-y-8 lg:flex-row lg:space-y-0">
                <div className="lg:w-1/3">
                    <a
                        href={`https://messagesapi.co.in`}
                        className="flex justify-center space-x-3 lg:justify-start"
                    >
                        <div className="flex items-center justify-center w-18 h-16 ">
                            <img src={data.companyLogo} alt="Logo" className="h-16 w-18 mr-2" />
                        </div>

                        <div>
                            <span className="self-center md:text-4xl font-semibold text-gray-500 mb-2 block">
                                {data.businessName}
                            </span>

                        </div>
                    </a>
                </div>
                <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
                    <div className="space-y-3">
                        <h3 className="tracking-wide uppercase text-green-500">
                            Product
                        </h3>
                        <ul className="space-y-1">
                            <li>
                                <a href="#features">Features</a>
                            </li>
                            <li>
                                <a href="/privacy">Terms of Service</a>
                            </li>
                            <li>
                                <a href="/returnpolicy">Return and Cancellation Policy</a>
                            </li>
                            <li>
                                <a href="/privacypolicy">Privacy Policy</a>
                            </li>
                            <li>
                                <Link to="/login">Pricing</Link>
                            </li>

                        </ul>
                    </div>
                    <div className="space-y-3">
                        <h3 className="uppercase text-green-500">Developers</h3>
                        <ul className="space-y-1">
                            <li>
                                <Link to="/login">Public API</Link>
                            </li>
                            <li>
                                <Link to="/login">Documentation</Link>
                            </li>
                            <li>
                                <Link to="/login">Guides</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="space-y-3">
                        <h3 className="tracking-wide uppercase text-green-500">
                            Address
                        </h3>
                        <ul className="space-y-1">
                            <li>{data.addressl1}</li>
                            <li>{data.addressl2}</li>
                            <li>{data.addressl3}</li>
                        </ul>
                    </div>

                    <div className="space-y-3">
                        <h3 className="uppercase text-green-500">Contact US</h3>
                        <ul>
                            <li>
                                <Email className="uppercase text-green-500 mr-2" />
                                <a href={`mailto:${data.email}`}>Send Email</a>

                            </li>
                            <li>
                                <Phone className="uppercase text-green-500 mr-2" />
                                <a href={`tel:+91${data.mobile}`}>{data.mobile}</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div className="py-6 text-sm text-center text-green-500">
                © 2022 Company Co. All rights reserved.
            </div>
        </footer>
    )
}

export default Footer
