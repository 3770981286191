import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import GoogleSheetEventHandler from "../../components/GoogleSheetEventHandler";
import Container from "../../components/Container";
import SheetRecords from "../sheet/SheetRecords";
import Alerts from "../../components/Alerts";

const DEVICE_GET = gql`
  query($uid: String!){
      devicesByUID(uid: $uid) {
          id
          deviceId
          deviceName
          uid
          userId
          isConnected
          createdAt
          updatedAt
      }
}`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

function TriggerMessage() {
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const [devices, setDevices] = useState([]);
    const [selectedData, setSelectedData] = useState([])
    const [mapColumnValues, setMapColumnValues] = useState(null)
    const [columnHeader, setColumnHeader] = useState(null)
    const [userProfileInfo, setUserProfileInfo] = useState({})
    const [waGroups, setWaGroups] = useState("");

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const selectedDeviceRef = useRef([]);
    const [sheetUrl, setSheetUrl] = useState('');

    let targetColumn = 0

    const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    function formatWhatsAppMessage(message) {
        // Remove all HTML entities (e.g., &nbsp;, &amp;, etc.)
        message = message.replace(/&[a-zA-Z0-9#]+;/g, " ");

        // Remove all placeholder words from the string
        message = message.replace(/\{\{[^}]+\}\}/g, "").replace(/\s{2,}/g, " ").trim();

        // Replace <em> tags with WhatsApp italic formatting
        message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

        // Replace <strong> tags with WhatsApp bold formatting, allowing optional spaces around the text
        message = message.replace(/<strong>\s*(.*?)\s*<\/strong>/g, '*$1*');

        // Replace <u> tags (underline) with emphasis (using _ for emphasis since underline isn't supported)
        message = message.replace(/<u>(.*?)<\/u>/g, '_$1_');

        // Ensure <p> tags add new lines
        message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
        message = message.replace(/<p>/g, ''); // Remove opening <p> tags

        // Convert ordered list <ol> into plain numbered list
        message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            let counter = 1;
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `${counter}. ${listItem}\n`; // Add numbering
                counter++;
            });
            return formattedList; // Return formatted list
        });

        // Convert unordered list <ul> into plain bullet list
        message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `• ${listItem}\n`; // Add bullet points
            });
            return formattedList; // Return formatted list
        });

        // Remove any other remaining HTML tags
        message = message.replace(/<[^>]*>/g, '');

        // Handle adjacent formatting symbols and ensure no spaces are lost
        message = message.replace(/_([\s\S]+?)_|\*([\s\S]+?)\*/g, function (match, italic, bold) {
            if (italic) return `_${italic}_`;
            if (bold) return `*${bold}*`;
            return match;
        });

        return message.trim(); // Trim any trailing spaces or newlines
    }

    const saveTrigger = async () => {
        if (
            selectedData &&
            selectedData.length > 0
        ) {

            const mapData = selectedData.map(obj => {
                const { id, ...rest } = obj;
                return {
                    ...rest,
                    "userId": parseInt(`${userInfo.id}`),
                    "deviceId": `${userInfo.uid}-${selectedDeviceRef.current[0]}`,
                    "deviceName": selectedDeviceRef.current[0],
                    "mapColumnValues": JSON.stringify(mapColumnValues),
                    "message": formatWhatsAppMessage(rest.message)
                }
            })

            let data = JSON.stringify({
                query: `mutation($sheets: [SheetInput!]!) {
                sheetCreate(sheets: $sheets) {
                    message
                }
            }`,
                variables: { "sheets": mapData }
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://graphql.messagesapi.co.in/',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {

                    console.log("sheetCreate", response)

                    setMessage(response.data.data.sheetCreate.message);

                    resetAll();
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.message)
                    resetAll()
                });

        }

    }

    const removeDatabaseTrigger = async (sheetId) => {

        let data = JSON.stringify({
            query: `mutation($sheetIds: [ID!]!) {
                sheetDelete(sheetIds: $sheetIds) {
                    message
                    sheetErrors {
                    message
                    }
                }
            }`,
            variables: { "sheetIds": [sheetId] }
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://graphql.messagesapi.co.in/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleWAAllGroups = () => {

        if (selectedDeviceRef.current && selectedDeviceRef.current.length > 0) {

            let config = {
                method: 'get',
                url: `https://messagesapi.co.in/contact/getAllGroups/${userInfo.uid}/${selectedDeviceRef.current[0]}`,
                headers: {}
            };

            axios(config)
                .then((response) => {
                    let data = response.data;
                    if (data.length > 0) {
                        setWaGroups(data);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message)
                });
        } else {
            setError("Select any connected device");

        }

    }

    const handleSubmit = async () => {

        setIsClicked(true);

        const sheetId = extractSheetId(sheetUrl);

        if (sheetId) {

            //Remove all previous trigger from database by sheetId
            removeDatabaseTrigger(sheetId)

            //Remove all previous trigger from google sheet by sheetId

            let statusValue = mapColumnValues['status']
            targetColumn = columnHeader.findIndex(element => element === statusValue) + 1

            try {
                let data = JSON.stringify({
                    "sheetId": sheetId,
                    "targetColumn": targetColumn
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://messagesapi.co.in/googlesheet/setup-trigger',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                    .then(async (response) => {
                        console.log(JSON.stringify(response.data));
                        setMessage(response.data.message);
                        await saveTrigger()
                        setIsClicked(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setError(`${error.message}`);
                        setIsClicked(false);
                    });

            } catch (error) {
                setError(`${error.message}`);
                setIsClicked(false);
            }
        } else {
            setError('Invalid Google Sheet URL');
            setIsClicked(false);
        }
    };

    const extractSheetId = (url) => {
        const regex = /\/d\/([a-zA-Z0-9-_]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const { data, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const handleSelectedDevice = (e) => {
        e.preventDefault();

        if (e.target.value) {
            selectedDeviceRef.current.push(e.target.value)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)

            if (
                (
                    userProfileInfo.planType &&
                    (
                        userProfileInfo.planType.includes("WG") ||
                        userProfileInfo.planType.includes("RC")
                    )
                )
            ) {
                handleWAAllGroups()
            }
        } else {
            setIsDeviceSelected(false)
        }
    }

    const resetAll = async () => {
        window.location.reload();
    }

    useEffect(() => {
        if (error !== "" || message !== "") {
            setTimeout(() => {
                resetAll();
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, message]);

    useEffect(() => {
        let devicesByUID = "";
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        let userProfileData = null;

        if (userProfile) {
            userProfileData = userProfile.user;
            setUserProfileInfo(userProfileData);

            if (
                !(
                    userProfileData.planType &&
                    (
                        userProfileData.planType.includes("WG") ||
                        userProfileData.planType.includes("GS") ||
                        userProfileData.planType.includes("RC")
                    )
                )
            ) {
                setError("You are not a valid user for this service")
            }
        }
    }, [userProfile, userInfoLoading]);

    return (
        <Container>
            <div className="pb-12 pt-4">
                <p className="text-green-400  flex justify-center items-center pb-4 text-xl font-semibold">SET TRIGGER</p>
            </div>
            <div className="grid grid-cols-12 gap-2 px-4 pt-4 pl-8 w-[80%]">
                <div className="col-span-10 gap-2">

                    <div className="flex flex-col space-y-4  overflow-y-scroll no-scrollbar">
                        <div className="grid grid-cols-1 gap-2">
                            <div className="grid grid-cols-2 gap-2">
                                <div className="">
                                    <p className="text-xs font-semibold">CONNECTED DEVICE : </p>
                                </div>
                                <div className="flex justify-start items-center" >
                                    <select onChange={handleSelectedDevice}>
                                        <option name="" value="" key="select device">Select device</option>
                                        {
                                            devices && devices.map((device) => {
                                                return (
                                                    <option name={device.deviceName} key={device.deviceName} value={device.deviceName}>{device.deviceName}</option>
                                                )
                                            }
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div
                            className="grid grid-cols-1 my-4 w-full"
                        >
                            {
                                userProfileInfo.planType &&
                                (

                                    userProfileInfo.planType.includes("GS") ||
                                    userProfileInfo.planType.includes("WG") ||
                                    userProfileInfo.planType.includes("RC")
                                ) && (
                                    <div className="grid grid-cols-1 mt-4">
                                        <GoogleSheetEventHandler
                                            userInfo={userInfo}
                                            setSelectedData={setSelectedData}
                                            setSelectedSheetUrl={setSheetUrl}
                                            setMapColumnValues={setMapColumnValues}
                                            setColumnHeader={setColumnHeader}
                                            waGroups={waGroups}
                                        />
                                        {
                                            selectedData &&
                                            selectedData.length > 0 &&
                                            (
                                                <div className='flex justify-end my-4 w-full'>
                                                    {
                                                        !isClicked ? (
                                                            <button className='w-[160px] rounded-full bg-blue-500 disabled:bg-blue-200 text-white px-4 py-1 text-xs' type="submit" onClick={handleSubmit} disabled={!isDeviceSelected}>
                                                                {isDeviceSelected ? "Save" : "Select device"}
                                                            </button>
                                                        ) : (
                                                            <button className='w-[200px] rounded-full bg-blue-300 text-white px-4 py-1 text-xs ' type="submit" disabled>Wait...</button>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                )

                            }

                            <div className="grid-cols-1 w-full">
                                <div className="my-2">
                                    {
                                        message !== "" ? (
                                            <p className="text-center text-sm tracking-normal text-green-500 ">
                                                <Alerts alertType="Info">{message}</Alerts>
                                            </p>
                                        ) : error !== "" ? (
                                            <p className="text-center text-sm tracking-normal text-red-500">
                                                <Alerts alertType="Error">{error}</Alerts>
                                            </p>
                                        ) : (
                                            ""
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-2 my-4 px-4">

                <p className="text-green-400  flex justify-center items-center py-4 text-xl font-semibold">TRIGGER HISTORY</p>

                <SheetRecords />
            </div>
        </Container>
    );
}

export default TriggerMessage;
