import React, { useState, useEffect } from "react";

import QRCode from "react-qr-code";
import { useParams } from "react-router-dom"; // Use useParams to fetch URL params
import socketIOClient from 'socket.io-client';
import wreloadqr from "./wreloadqr.png";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment-timezone";

import { Menu, Settings } from "lucide-react";
import ContainerWithoutNav from "../../components/ContainerWithoutNav";
import Alerts from "../../components/Alerts";

const ENDPOINT = `https://apigully.com/`;
const ROOTDOMAIN = `messagesapi.co.in`;

const GET_USER = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
        id
        uid
        devices {
            id
            deviceId
            deviceName
            isConnected
            updatedAt
        }
    }
  }
`;

const USER_DEVICE_STATUS = gql`
  mutation($deviceId: ID!, $device: DeviceInput!){
    deviceUpdate(deviceId: $deviceId, device: $device) {
      device {
        id
      }
    }
  }
`;

function EQRCode() {
    const { id, device } = useParams(); // Get URL parameters

    const [socket] = useState(() => socketIOClient(ENDPOINT));
    const [qrCode, setQRCode] = useState("");
    const [error, setError] = useState("");
    const [userProfile, setUserProfile] = useState(null);
    const [retryClicked, setRetryClicked] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState("");
    const [updateUserDevice] = useMutation(USER_DEVICE_STATUS);

    const { data, loading } = useQuery(GET_USER, {
        variables: { uid: id },
    });

    const retryHandle = (e) => {
        e.preventDefault();
        setRetryClicked(true);
        setConnectionStatus("Please Wait...");

        const room = `${ROOTDOMAIN}-${id}-${device}`;
        const message = {
            userId: Number(userProfile.id),
            id: id,
            uid: id,
            name: device,
        };

        if (userProfile) {

            if (userProfile && userProfile.devices[0].deviceName === device) {
                socket.emit('AddDevice', { room, message });
            } else {
                setError("Device is not valid")
            }
        }
    };

    useEffect(() => {
        const room = `${ROOTDOMAIN}-${id}-${device}`;

        socket.on('connect', () => {
            setConnectionStatus("Connecting to the server...");
        });

        if (userProfile) {

            if (userProfile && userProfile.devices[0].deviceName === device) {
                socket.emit('joinRoom', room);
            } else {
                setError("Device is not valid")
            }
        }




        socket.on(`${id}-${device}`, (msg) => {
            console.log("Received message:", msg);

            const deviceId = `${id}-${device}`;

            if (msg.qrCode) {
                setQRCode(msg.qrCode);
                setConnectionStatus("QR Ready for Scan");
                setRetryClicked(false);
            } else {
                setConnectionStatus(msg.message);

                if (msg.message.includes("is connected successfully")) {
                    const phoneNumber = msg.message.split(" ")[0].trim();

                    setQRCode("");

                    updateUserDevice({
                        variables: {
                            deviceId,
                            device: {
                                userId: Number(userProfile.id),
                                uid: deviceId.split("-")[0]?.trim(),
                                deviceId,
                                deviceName: deviceId.split("-")[1]?.trim(),
                                phoneNumber,
                                isConnected: true,
                                updatedAt: moment().tz("Asia/Kolkata").format(),
                            },
                        },
                    });
                }
            }
        });

        socket.on('disconnect', () => {
            setConnectionStatus("Disconnected from the server");
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off(`${id}-${device}`);
        };
    }, [userProfile]);

    useEffect(() => {

        if (userProfile) {

            const room = `${ROOTDOMAIN}-${id}-${device}`;
            const message = {
                userId: Number(userProfile.id),
                id: id,
                uid: id,
                name: device,
            };

            if (userProfile && userProfile.devices[0].deviceName === device) {
                socket.emit('AddDevice', { room, message });
            } else {
                setError("Device is not valid")
            }

        }
    }, [userProfile]);

    useEffect(() => {
        let userProfile = null;
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);
        }
    }, [data, loading]);

    return (
        <div className="flex w-screen h-screen bg-white">

            <ContainerWithoutNav>
                <div className="h-screen p-4 flex items-center justify-center">
                    <div className="w-full bg-white rounded-lg shadow-md p-6 grid grid-cols-12 h-[60%]">
                        <div className="col-span-8 space-y-6 h-[60%]">
                            <h1 className="text-2xl md:text-3xl font-bold text-green-400 uppercase text-left">
                                Use WhatsApp on MessageAPI
                            </h1>
                            <ol className="list-decimal list-inside space-y-4 text-gray-400">
                                <li>Open WhatsApp on your phone</li>
                                <li className="flex items-center">
                                    Tap Menu <Menu className="inline-block w-5 h-5 mx-1" /> on Android, or Settings{" "}
                                    <Settings className="inline-block w-5 h-5 mx-1" /> on iPhone
                                </li>
                                <li>
                                    Tap <span className="font-semibold">Linked devices</span> and then{" "}
                                    <span className="font-semibold">Link a device</span>
                                </li>
                                <li>Point your phone at this screen to capture the QR code</li>
                                <li>Do not sign out from your account after device is connected successfully</li>
                            </ol>
                        </div>

                        <div className="col-span-4 h-[60%] justify-center self-center">
                            <div>
                                {connectionStatus === "Retry" || connectionStatus === "Your device is disconnected!!" ? (
                                    <button onClick={retryHandle} disabled={retryClicked}>
                                        <img src={wreloadqr} alt="retry" className="w-full max-w-[220px] h-auto" width={220} height={220} />
                                    </button>
                                ) : qrCode && userProfile.devices[0].deviceName === device && (
                                    <QRCode value={qrCode} className="w-full max-w-[220px] h-auto" width={220} height={220} />
                                )}

                                <div className="flex flex-col justify-start gap-2">
                                    {
                                        !error && <div className="text-lg font-serif text-green-500 leading-3 tracking-wides my-4 w-full">
                                            {connectionStatus}
                                        </div>
                                    }

                                    <Alerts alertType={"Error"}>{error}</Alerts>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerWithoutNav>
        </div>
    );
}

export default EQRCode;
