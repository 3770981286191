import { Api } from '@mui/icons-material';
import { BookTemplate, ChevronDown, FileText, Group, LayoutDashboard, ListOrdered, MessageCircle, QrCode, Settings, Sheet, SheetIcon, Users } from 'lucide-react'
import React, { useState } from 'react'
import { Link } from "react-router-dom";

function Sidebar() {
    const [openSubmenu, setOpenSubmenu] = useState(null)

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const toggleSubmenu = (submenu) => {
        setOpenSubmenu(openSubmenu === submenu ? null : submenu)
    }

    const menuItems = [
        {
            title: 'Home',
            icon: <LayoutDashboard className="w-5 h-5" />,
            link: '/dashboard',
            submenu: []
        },
        {
            title: 'Integration',
            icon: <Api className="w-5 h-5" />,
            link: '',
            submenu: [
                { title: 'API', link: 'https://documenter.getpostman.com/view/1240173/2sAXxLDEz7' },
                { title: 'Tally', link: '/plugins' },
                { title: 'Busy', link: '/busy' },
            ]
        },
        {
            title: 'Message',
            icon: <MessageCircle className="w-5 h-5" />,
            submenu: [
                { title: 'Single', link: '/singlemessage' },
                { title: 'Multi', link: '/multimessage' }
            ]
        },
        {
            title: 'Template',
            icon: <BookTemplate className="w-5 h-5" />,
            link: '/template',
            submenu: []
        },
        {
            title: 'Google Sheet',
            icon: <SheetIcon className="w-5 h-5" />,
            submenu: [
                { title: 'Messaging', link: '/sheetmessage' },
                { title: 'Trigger', link: '/triggermessage' }
            ]
        },
        {
            title: 'Groups',
            icon: <Group className="w-5 h-5" />,
            submenu: [
                { title: 'Manager', link: '/manageGroup' },
                { title: 'Messaging', link: '/groupmessage' },
                { title: 'Broadcast ', link: '/bulkmessage' }
            ]
        },
        {
            title: 'Manage Replies',
            icon: <Group className="w-5 h-5" />,
            link: '/groupInMessage',
            submenu: []
        },
        {
            title: 'Reports',
            icon: <FileText className="w-5 h-5" />,
            submenu: [
                {
                    title: 'Delivery', link: '/report/delivery', state: { "uid": userInfo?.uid }
                },
                { title: 'Schedule', link: '/report/schedule', state: { "uid": userInfo?.uid } }
            ]
        }
    ]

    return (
        <div className="w-64">
            <aside
                className={`bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform transition-all duration-200 ease-in-out`}
            >
                <nav>
                    {menuItems.map((item, index) => (
                        <div key={item.title} className="mb-2">
                            <button
                                onClick={() => toggleSubmenu(item.title)}
                                className="flex items-center p-2 w-full text-left hover:bg-gray-700 rounded"
                            >
                                {item.icon}
                                <span className="ml-2">
                                    <Link to={item.link}>
                                        <span className="ml-4 uppercase text-xs">{item.title}</span>
                                    </Link>
                                </span>
                                {item.submenu.length > 0 && (
                                    <ChevronDown className={`w-4 h-4 ml-auto transform ${openSubmenu === item.title ? 'rotate-180' : ''}`} />
                                )}
                            </button>
                            {item.submenu.length > 0 && openSubmenu === item.title && (
                                <div className="pl-4 mt-2 space-y-2">
                                    {item.submenu.map((subItem, subIndex) => (
                                        <Link
                                            key={subItem.title}
                                            to={subItem.link}
                                            className="block p-2 hover:bg-gray-700 rounded text-xs"
                                            state={subItem.state}
                                        >
                                            {subItem.title}
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </nav>
            </aside>
        </div>
    )
}

export default Sidebar
