import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';

import { FaHourglass } from 'react-icons/fa';
import './style.css'; // For spinning animation

import { gql, useMutation, useQuery } from "@apollo/client";

import Alerts from '../../components/Alerts';
import { Add, Delete, LinkOffOutlined, MobileFriendly, QrCode } from '@mui/icons-material';
import axios from 'axios'
import Container from '../../components/Container';
import { Hourglass } from 'lucide-react';

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const DEVICE_ADD = gql`
    mutation($device: DeviceInput!){
        deviceCreate(device: $device) {
            device {
            deviceId
            deviceName
            uid
            userId
            }
        }
    }
`;

const DEVICE_DELETE = gql`
    mutation($deviceId: ID!){
        deviceDelete(deviceId: $deviceId) {
            device {
                id
            }
        }
    }
`;

function Device() {
    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };
    const [device, setDevice] = useState({ name: "" });
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [processing, setProcessing] = useState(false);


    //GRAPHQL DEVICE QUERY START
    const { refetch, data, loading } = useQuery(DEVICE_GET, {
        variables: {
            uid: userInfo.uid
        }
    });

    const [
        addDevice
    ] = useMutation(DEVICE_ADD, {
        refetchQueries: [
            DEVICE_GET,
            {
                variables: { uid: userInfo.uid },
            },
        ],
    });

    const [deleteDevice] = useMutation(DEVICE_DELETE, {
        refetchQueries: [
            DEVICE_GET,
            {
                variables: { uid: userInfo.uid },
            }
        ]
    });

    const handleResetConnection = ({ uid, deviceName }) => {
        setProcessing(true);
        let config = {
            method: 'get',
            url: `https://messagesapi.co.in/device/logout/${uid}/${deviceName}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                let result = response.data;
                refetch();
                setProcessing(false);
                setSuccess(result.message)
            })
            .catch((error) => {
                console.log(error);
                setError(error.message)
                setProcessing(false);
            });

    }

    const handleDeleteDevice = ({ uid, deviceName, device }) => {
        setProcessing(true);
        let config = {
            method: 'get',
            url: `https://messagesapi.co.in/device/destroy/${uid}/${deviceName}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                let result = response.data;
                deleteDevice({ variables: { deviceId: device.deviceId } })
                refetch();
                setProcessing(false);
                setSuccess(result.message)
            })
            .catch((error) => {
                console.log(error);
                setError(error.message)
                setProcessing(false);
            });

    }

    const handleChange = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''); // Removes all special characters except spaces
        setDevice(prevData => {
            return { ...prevData, [e.target.name]: sanitizedValue }
        });
    };


    const handleNewDevice = async (e) => {
        e.preventDefault();
        setProcessing(true);

        if (
            device.name !== "" &&
            userInfo
        ) {
            await addDevice({
                variables: {
                    "device": {
                        "deviceId": `${userInfo.uid}-${device.name.replaceAll(" ", "")}`,
                        "deviceName": device.name.replaceAll(" ", ""),
                        "uid": userInfo.uid,
                        "userId": Number(userInfo.id)
                    }
                }
            });

            setProcessing(false);
            setDevice({ name: "" });

        } else {
            setError("Please input device name")
            setProcessing(false);
        }
    }

    const handleQRRequest = (data) => {

        if (
            userInfo
        ) {

            navigate("/qrcode", { state: data });
        } else {
            setError("Please input device name")
            setProcessing(false);
        }

    }

    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (
            userInfo &&
            userInfo.uid !== ""
        ) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo])

    //RESET ALL
    useEffect(() => {
        if (
            error ||
            success
        ) {
            setTimeout(() => {
                setError("")
                setSuccess("")
            }, [10000])
        }
    }, [error, success])

    return (
        <Container>
            <div className="rounded-md p-4 border-2 h-auto w-full md:w-3/4 lg:w-1/2 m-auto">
                <div className="relative text-center flex-1">
                    {/* Add Device Section */}
                    {devices?.length === 0 && (
                        <div>
                            <h1 className="text-xl md:text-2xl font-semibold text-green-400 mb-4">
                                {devices?.length > 0 ? "YOUR DEVICE" : "ADD DEVICE"}
                            </h1>
                            <div className="m-auto w-full md:w-[90%]">
                                <form action="" onSubmit={handleNewDevice}>
                                    <div className="flex flex-col md:flex-row justify-center items-center gap-2 m-auto">
                                        <input
                                            className="w-full md:w-[70%] p-2 border rounded"
                                            type="text"
                                            onChange={handleChange}
                                            value={device.name}
                                            name="name"
                                            placeholder="Enter device name"
                                        />
                                        <button
                                            type="submit"
                                            className="px-4 py-2 bg-green-700 text-white rounded-full"
                                            disabled={processing}
                                        >
                                            {!processing ? (
                                                <span>
                                                    <Add /> Add
                                                </span>
                                            ) : (
                                                <span>
                                                    <Add /> Wait...
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {/* Device List Section */}
                    <div className="m-auto w-full md:w-[90%]">
                        {/* Alerts */}
                        <div>
                            {error && <Alerts alertType="Error">{error}</Alerts>}
                            {success && <Alerts alertType="Success">{success}</Alerts>}
                        </div>

                        {/* Device List */}
                        {devices?.length > 0 && (
                            <div className="w-full md:w-[90%] m-auto">
                                <h2 className="mt-4 border-dotted border-b-2 text-green-400 border-b-blue-500 font-semibold font-serif text-lg md:text-xl tracking-widest px-4 py-2">
                                    DEVICE LIST
                                </h2>

                                {/* Status Legend */}
                                <div className="py-2 m-auto w-full md:w-[60%]">
                                    <ul className="flex justify-around">
                                        <li className="flex items-center gap-1">
                                            <span className="w-4 h-4 bg-green-400"></span> CONNECTED
                                        </li>
                                        <li className="flex items-center gap-1">
                                            <span className="w-4 h-4 bg-gray-400"></span> DISCONNECTED
                                        </li>
                                    </ul>
                                </div>

                                {/* Note */}
                                <div className="py-2">
                                    <p className="text-green-400 font-serif text-sm mt-4">
                                        Note: Click on the QR icon to connect the device.
                                    </p>
                                </div>

                                {/* Device Items */}
                                <ul className="w-full max-h-[300px] md:max-h-[400px] overflow-y-scroll no-scrollbar">
                                    {devices.map((device, index) => (
                                        <li
                                            key={device.deviceName}
                                            className={`${device.isConnected ? "bg-green-500" : "bg-gray-400"
                                                } flex justify-between items-center px-2 py-1 rounded-xl mt-2 text-white`}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleQRRequest({ ...userInfo, deviceName: device.deviceName })
                                                }
                                            >
                                                {device.isConnected ? <MobileFriendly /> : <QrCode />}
                                            </button>
                                            <span className="flex-1 text-left">{device.deviceName}</span>
                                            {device.isConnected ? (
                                                <button
                                                    onClick={() =>
                                                        handleResetConnection({
                                                            uid: userInfo.uid,
                                                            deviceName: device.deviceName,
                                                        })
                                                    }
                                                >
                                                    {!processing ? <LinkOffOutlined /> : <Hourglass />}
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        handleDeleteDevice({
                                                            uid: userInfo.uid,
                                                            deviceName: device.deviceName,
                                                            device,
                                                        })
                                                    }
                                                >
                                                    {!processing ? <Delete /> : <FaHourglass className="spinning-hourglass" />}
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* No Devices Found */}
                        {devices?.length === 0 && (
                            <p className="text-md font-serif py-2 text-red-400">No Device found!</p>
                        )}
                    </div>
                </div>
            </div>
        </Container>

    )
}

export default Device