import React, { useState } from 'react'
import { LogOut, User, UserCircle } from "lucide-react";
import { Link, useNavigate } from 'react-router-dom';


function Navbar() {
    const [isProfileOpen, setIsProfileOpen] = useState(false)
    const toggleProfile = () => setIsProfileOpen(!isProfileOpen)

    const navigate = useNavigate();
    return (
        <div className='w-full sticky top-0 z-10'>
            <header className="flex justify-between items-center px-4 py-1 bg-white shadow">
                <div className="flex items-center">
                    {/* TITLE */}
                </div>
                <div className="relative">
                    <button onClick={toggleProfile} className="flex items-center focus:outline-none">
                        <div alt="Profile" className="rounded-full" >
                            <UserCircle />
                        </div>
                    </button>
                    {isProfileOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                            <Link to="/profile" className=" px-4 py-2 text-sm text-gray-700 hover: flex items-center">
                                <User className="w-4 h-4 mr-2" />
                                Profile
                            </Link>
                            <Link onClick={() => {
                                window.localStorage.clear("user");
                                navigate("/login", { replace: true });
                            }}
                                to="/login"
                                className=" px-4 py-2 text-sm text-gray-700 hover: flex items-center">
                                <LogOut className="w-4 h-4 mr-2" />
                                Logout
                            </Link>
                        </div>
                    )}
                </div>
            </header>
        </div>
    )
}

export default Navbar
