import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Emojis from "../../util/Emojis";
import { gql, useQuery } from "@apollo/client";
import Container from "../../components/Container";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CronStringGenerator from "../../util/CronStringGenerator";
import Alerts from "../../components/Alerts";
import { CircularProgress } from "@mui/material";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const GET_TEMPLATES = gql`
    query($uid: String!){
        templateByUID(uid:$uid) {
            id,
            templateName,
            body
        }
    }
`

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

function GroupMessage() {
    const [devices, setDevices] = useState([]);
    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    //GRAPHQL DEVICE QUERY START
    const { refetch, data, loading } = useQuery(DEVICE_GET);
    const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
        variables: {
            uid: userInfo.uid
        }
    });

    const [message, setMessage] = useState({});
    const [success, setSuccess] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [isScheduleProcess, setIsScheduleProcess] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [waGroups, setWaGroups] = useState([]);
    const selectedDeviceRef = useRef([]);
    const cursorRef = useRef("");
    const selectedGroupRef = useRef("");
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [sentMessage, setSentMessage] = useState(0);
    const [totalMessage, setTotalMessage] = useState(0);
    const [templates, setTemplates] = useState(null);
    const [templateSelected, setTemplateSelected] = useState(false)
    const [userProfileInfo, setUserProfileInfo] = useState({})

    const dateTimeRef = useRef({});
    const fileRef = useRef("");
    const selectedTemplateRef = useRef("");

    const quillRef = useRef(null);

    const [selectedGroups, setSelectedGroups] = useState([]);

    let i = 0;

    const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    function formatWhatsAppMessage(message) {
        // Remove all HTML entities (e.g., &nbsp;, &amp;, etc.)
        message = message.replace(/&[a-zA-Z0-9#]+;/g, " ");

        // Remove all placeholder words from the string
        message = message.replace(/\{\{[^}]+\}\}/g, "").replace(/\s{2,}/g, " ").trim();

        // Replace <em> tags with WhatsApp italic formatting
        message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

        // Replace <strong> tags with WhatsApp bold formatting, allowing optional spaces around the text
        message = message.replace(/<strong>\s*(.*?)\s*<\/strong>/g, '*$1*');

        // Replace <u> tags (underline) with emphasis (using _ for emphasis since underline isn't supported)
        message = message.replace(/<u>(.*?)<\/u>/g, '_$1_');

        // Ensure <p> tags add new lines
        message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
        message = message.replace(/<p>/g, ''); // Remove opening <p> tags

        // Convert ordered list <ol> into plain numbered list
        message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            let counter = 1;
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `${counter}. ${listItem}\n`; // Add numbering
                counter++;
            });
            return formattedList; // Return formatted list
        });

        // Convert unordered list <ul> into plain bullet list
        message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `• ${listItem}\n`; // Add bullet points
            });
            return formattedList; // Return formatted list
        });

        // Remove any other remaining HTML tags
        message = message.replace(/<[^>]*>/g, '');

        // Handle adjacent formatting symbols and ensure no spaces are lost
        message = message.replace(/_([\s\S]+?)_|\*([\s\S]+?)\*/g, function (match, italic, bold) {
            if (italic) return `_${italic}_`;
            if (bold) return `*${bold}*`;
            return match;
        });

        return message.trim(); // Trim any trailing spaces or newlines
    }

    const formatedMessage = (msg, name = "") => {
        return formatWhatsAppMessage(msg, name)
    };

    const sendScheduleFileMessageMulti = (groupId) => {

        let apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;

        const formData = new FormData();

        formData.append("id", userInfo.uid);
        formData.append("uid", userInfo.uid);
        formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
        formData.append("deviceName", selectedDeviceRef.current[0]);
        formData.append("file", selectedFile);
        formData.append("message", formatedMessage(message.message, ""),);
        formData.append("dateTime", dateTimeRef.current.value);
        formData.append("phones", groupId);

        fetch(apiURL, {
            method: "POST",
            headers: {},
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {

                let msgResp = result.message;
                let msgStatus = result.status;

                if (msgStatus !== "error") {

                    i++;
                    //Set Msg sent count
                    setSentMessage(i);
                    if (i < selectedGroups.length) {
                        sendScheduleFileMessageMulti(selectedGroups[i]);
                    } else {
                        i = 0;
                        setSelectedGroups([]);
                        apiURL = "";
                        setStatus(msgResp);
                    }
                } else {
                    setError(msgResp);
                }
            })
            .catch((error) => {
                setError(error.response.data.message);
            });
    };

    const sendScheduleMessageMulti = (groupId) => {

        let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

        var data = JSON.stringify({
            id: userInfo.uid,
            phones: groupId,
            message: formatedMessage(message.message, ""),
            dateTime: dateTimeRef.current.value,
            cronString: CronStringGenerator(dateTimeRef.current.value),
            uid: userInfo.uid,
            deviceName: selectedDeviceRef.current[0],
            file: ""
        });

        var config = {
            method: "POST",
            url: apiURL,
            headers: {
                "Content-type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                let msgResp = response.data.message;
                let msgStatus = response.data.status;

                if (msgStatus !== "error") {

                    i++;
                    //Set Msg sent count
                    setSentMessage(i);

                    if (i < selectedGroups.length) {
                        sendScheduleMessageMulti(selectedGroups[i]);
                    } else {
                        i = 0;
                        setSelectedGroups([]);
                        apiURL = "";
                        setStatus(msgResp);
                    }
                } else {
                    setError(msgResp);
                }
            })
            .catch(function (error) {
                setError(error.response.data.message);
            });

    };

    const sendScheduleFileMessage = () => {

        const apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;

        setTotalMessage(Number(selectedGroups.length));

        if (selectedGroups.length > 1) {
            sendScheduleFileMessageMulti(selectedGroups[i]);
        }
        else {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("uid", userInfo.uid);
            formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
            formData.append("deviceName", selectedDeviceRef.current[0]);
            formData.append("file", selectedFile);
            formData.append("message", formatedMessage(message.message, ""),);
            formData.append("dateTime", dateTimeRef.current.value);
            formData.append("phones", selectedGroups[i]);

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message);
                });
        }
    };

    const sendScheduleMessage = () => {

        setIsScheduleProcess(true);

        setTotalMessage(Number(selectedGroups.length));

        if (selectedGroups.length !== 0) {

            if (isSelected) {

                sendScheduleFileMessage();
            } else {

                if (selectedGroups.length > 1) {

                    sendScheduleMessageMulti(selectedGroups[i]);

                }
                else {

                    let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

                    var data = JSON.stringify({
                        id: userInfo.uid,
                        phones: selectedGroups[i],
                        message: formatedMessage(message.message, ""),
                        dateTime: dateTimeRef.current.value,
                        cronString: CronStringGenerator(dateTimeRef.current.value),
                        uid: userInfo.uid,
                        deviceName: selectedDeviceRef.current[0],
                        file: ""
                    });

                    var config = {
                        method: "POST",
                        url: apiURL,
                        headers: {
                            "Content-type": "application/json",
                        },
                        data: data,
                    };

                    axios(config)
                        .then(function (response) {
                            let msgResp = response.data.message;
                            let msgStatus = response.data.status;

                            i++;

                            setSentMessage(i);

                            if (msgStatus !== "error") {

                                setStatus(msgResp);

                            } else {
                                setError(msgResp);
                            }
                        })
                        .catch(function (error) {
                            setError(error.response.data.message);
                        });
                }

            }
        } else {

            setError("Group is missing");
        }
    }

    const handleSelectChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedGroups(selectedOptions);
    };

    const handleQuillChange = (value) => {
        setMessage((prevData) => {
            return { ...prevData, "message": value };
        });
    };

    // Function to track and update cursor position using Quill's API
    const handleSelectionChange = useCallback(() => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        const selection = editor.getSelection();
        if (selection) {
            cursorRef.current = selection.index; // Update cursor position
        }
    }, []);

    // Insert emoji at cursor position
    const insertEmoji = (emoji) => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        const cursorPosition = cursorRef.current; // Get current cursor position

        if (cursorPosition !== undefined) {
            editor.insertText(cursorPosition, emoji); // Insert emoji at the cursor position
        }
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const sendMessage = async () => {

        setIsClicked(true);

        let indexRef = 0;
        let groupIndex = 0;


        let apiURL = `https://messagesapi.co.in/chat/sendGroupMessage`;

        if (selectedDeviceRef.current.length === 0) {
            setError("Please select atleast one device");
            return
        }

        if (
            selectedGroups &&
            selectedGroups.length > 0
        ) {
            if (message.message && message.message !== "") {

                if (isSelected) {

                    const sendFileMessage = async (deviceName, groupId) => {

                        const apiURL = `https://messagesapi.co.in/chat/sendGroupMessageFile/${userInfo.uid}/${deviceName}`;
                        const formData = new FormData();

                        formData.append("file", selectedFile);
                        formData.append("message", formatWhatsAppMessage(message.message));
                        formData.append("groupId", groupId);

                        fetch(apiURL, {
                            method: "POST",
                            headers: {},
                            body: formData,
                        })
                            .then((response) => response.json())
                            .then(async (result) => {
                                let msgResp = result.message;
                                let msgStatus = result.status;

                                if (msgStatus !== "error") {
                                    setStatus(msgResp);
                                } else {
                                    setError(msgResp);
                                }

                                ++groupIndex;

                                if (selectedGroups.length > groupIndex) {
                                    await sendFileMessage(selectedDeviceRef.current[indexRef], selectedGroups[groupIndex]);
                                }

                            })
                            .catch((error) => {
                                setError(error.response.data.message);
                            });
                    };

                    await sendFileMessage(selectedDeviceRef.current[indexRef], selectedGroups[groupIndex]);
                    setSuccess("Message sent to all groups successfully!")
                } else {

                    const sendMsg = async (deviceName, groupId) => {

                        let data = JSON.stringify({
                            "id": userInfo.uid,
                            "name": deviceName,
                            "groupId": groupId,
                            "message": formatWhatsAppMessage(message.message)
                        });

                        var config = {
                            method: "POST",
                            url: apiURL,
                            headers: {
                                "Content-type": "application/json",
                            },
                            data: data,
                        };

                        let response = await axios(config)
                        let msgResp = response.data.message;
                        let msgStatus = response.data.status;

                        if (msgStatus !== "error") {

                            setStatus(msgResp);
                        } else {

                            setError(msgResp);
                        }

                        ++groupIndex;

                        if (selectedGroups.length > groupIndex) {
                            await sendMsg(selectedDeviceRef.current[indexRef], selectedGroups[groupIndex]);
                        }
                    }

                    await sendMsg(selectedDeviceRef.current[indexRef], selectedGroups[groupIndex]);

                    setSuccess("Message sent to all groups successfully!")
                }
            } else {
                setError("Message is empty");
            }
        } else {
            setError("Phone is not valid");
        }
    };

    const handleSelectedDevice = (e) => {

        if (e.target.value) {
            selectedDeviceRef.current.push(e.target.value)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)
        } else {
            setIsDeviceSelected(false)
        }

        handleWAAllGroups()
    }

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleWAAllGroups = () => {

        if (selectedDeviceRef.current && selectedDeviceRef.current.length > 0) {

            let config = {
                method: 'get',
                url: `https://messagesapi.co.in/contact/getAllGroups/${userInfo.uid}/${selectedDeviceRef.current[0]}`,
                headers: {}
            };

            axios(config)
                .then((response) => {
                    let data = response.data;
                    if (data.length > 0) {

                        setWaGroups(data);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message)
                });
        } else {
            setError("Select any connected device");

        }

    }

    const handleSelectTemplate = (e) => {
        if (e.target.value) {
            setMessage((prevData) => {
                return { ...prevData, "message": e.target.value };
            });

            setTemplateSelected(!templateSelected)
        } else {
            setMessage((prevData) => {
                return { ...prevData, "message": "" };
            });

            setTemplateSelected(!templateSelected)
        }
    }

    const resetAll = () => {

        setMessage("");
        setSuccess("");
        setStatus("");
        setError("");
        setTemplates("");
        setSelectedGroups([]);
        setSelectedFile("");
        setIsScheduleProcess(false);
        setIsScheduleClicked(false);
        setIsSelected(false);
        setIsClicked(false);
        dateTimeRef.current.value = {};
        fileRef.current.value = null;
        setTemplateSelected(false);

    }

    useEffect(() => {

        if (templateData) {
            let data = templateData.templateByUID;

            setTemplates(data);
        }
    }, [templateData, templateLoading]);

    // Effect to track selection changes in Quill
    useEffect(() => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance

        editor.on('selection-change', handleSelectionChange); // Quill event listener for selection change

        return () => {
            editor.off('selection-change', handleSelectionChange); // Cleanup event listener
        };
    }, [handleSelectionChange]);


    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            error ||
            success ||
            status
        ) {

            setTimeout(() => {
                resetAll()
                document.getElementById("groupmessage").reset();
            }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, success, status]);

    useEffect(() => {
        let userProfileData = null;

        if (userProfile) {
            userProfileData = userProfile.user;
            setUserProfileInfo(userProfileData);

            if (
                !(
                    userProfileData.planType &&
                    (
                        userProfileData.planType.includes("WG") ||
                        userProfileData.planType.includes("RC")
                    )
                )
            ) {
                setError("You are not a valid user for this service")
            }
        }
    }, [userProfile, userInfoLoading]);


    return (
        // <Container>
        //     <form id="groupmessage">

        //         <div className="grid grid-cols-12 gap-2 px-4 pt-8 pl-8">

        //             <div className="col-span-9 h-screen overflow-y-scroll">

        //                 <div className="flex w-10/12 flex-col space-y-4">
        //                     <div className="grid grid-cols-2 gap-2">
        //                         <div className="">
        //                             <p className="text-sm font-semibold text-gray-600">CONNECTED DEVICE : </p>
        //                         </div>
        //                         <div className="flex justify-start items-center" >
        //                             <select onChange={handleSelectedDevice}>
        //                                 <option name="" value="" key="select device">Select device</option>
        //                                 {
        //                                     devices && devices.map((device) => {
        //                                         return (
        //                                             <option name={device.deviceName} key={device.deviceName} value={device.deviceName} >{device.deviceName}</option>
        //                                         )
        //                                     }
        //                                     )
        //                                 }
        //                             </select>
        //                         </div>
        //                     </div>
        //                     <div className="grid grid-cols-2 gap-2">
        //                         <div>
        //                             <p className="text-sm font-semibold text-gray-600">SELECT TEMPLATE : </p>
        //                         </div>
        //                         <div>
        //                             <select ref={selectedTemplateRef} onChange={handleSelectTemplate}>
        //                                 <option name="" value="" key="Select Template">Select Template</option>
        //                                 {
        //                                     templates && templates.map((template) => {
        //                                         return (
        //                                             <option name={template.templateName} key={template.templateName} value={template.body} >{template.templateName}</option>
        //                                         )
        //                                     }
        //                                     )
        //                                 }
        //                             </select>
        //                         </div>
        //                     </div>
        //                     <div className="grid grid-cols-2 gap-2">
        //                         <div className="">
        //                             <p className="text-sm font-semibold text-gray-600">SELECT MULTI WA GROUP : </p>
        //                         </div>
        //                         <div action=""
        //                             method="post"
        //                             className="flex justify-start items-center" >
        //                             {
        //                                 waGroups.length > 0 && (
        //                                     <div className='my-2'>
        //                                         <select
        //                                             name="selectedGroup"
        //                                             id="selectedGroup"
        //                                             ref={selectedGroupRef}
        //                                             multiple
        //                                             size={5}
        //                                             value={selectedGroups}
        //                                             onChange={handleSelectChange}
        //                                         >

        //                                             {
        //                                                 waGroups.map(group => (
        //                                                     <option value={group.groupId} key={group.groupId}>
        //                                                         {group.name}
        //                                                     </option>
        //                                                 ))
        //                                             }
        //                                         </select>
        //                                     </div>
        //                                 )
        //                             }
        //                             {
        //                                 waGroups.length === 0 &&
        //                                 selectedTemplateRef.current.value && (
        //                                     <div className="flex items-center gap-2">
        //                                         <CircularProgress size={16} />
        //                                         <span className="text-green-600">Wait Group is fetching......</span>
        //                                     </div>
        //                                 )
        //                             }
        //                         </div>
        //                     </div>
        //                     <div
        //                         action=""
        //                         method="post"
        //                         className="flex w-ful flex-col space-y-2 "
        //                     >

        //                         <label
        //                             htmlFor="message"
        //                             className="text-sm font-semibold text-gray-600"
        //                         >

        //                             <span className="text-sm  text-gray-600">
        //                                 Que : {totalMessage}
        //                             </span>{" "}
        //                             |{" "}
        //                             <span className="text-sm  text-gray-600">
        //                                 Sent : {sentMessage}
        //                             </span>
        //                         </label>

        //                         <div className="w-full rounded-md p-2 bg-white h-[200px]">
        //                             <ReactQuill
        //                                 style={{ height: '150px' }}
        //                                 ref={quillRef}
        //                                 name="message"
        //                                 id="message"
        //                                 onChange={handleQuillChange}
        //                                 value={message?.message}
        //                                 disabled={isClicked}
        //                                 placeholder="Message"
        //                                 theme="snow"
        //                                 readOnly={templateSelected}
        //                             />
        //                         </div>

        //                         <label htmlFor="file" className="text-sm font-thin text-gray-600 uppercase">
        //                             Attach File:
        //                         </label>
        //                         <input
        //                             type="file"
        //                             name="file"
        //                             accept=".txt, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        //                             id="file"
        //                             onChange={changeHandler}
        //                             ref={fileRef}
        //                         />

        //                         <p className="text-xs font-semibold text-green-400 uppercase pb-2 ">About File</p>
        //                         {
        //                             selectedFile && (
        //                                 <div>
        //                                     <p>
        //                                         Filename: {selectedFile?.name ? selectedFile.name : ""}
        //                                     </p>
        //                                     <p>
        //                                         Filetype: {selectedFile?.type ? selectedFile.type : ""}
        //                                     </p>
        //                                     <p>
        //                                         Size in bytes:{" "}
        //                                         {selectedFile?.size ? selectedFile.size : ""}
        //                                     </p>
        //                                     <p>
        //                                         lastModifiedDate:{" "}
        //                                         {selectedFile?.lastModifiedDate
        //                                             ? selectedFile.lastModifiedDate.toLocaleDateString()
        //                                             : ""}
        //                                     </p>
        //                                 </div>
        //                             )
        //                         }

        //                         {
        //                             isScheduleClicked && (
        //                                 <div className="my-8">
        //                                     <label htmlFor="dateTime" className="text-sm font-semibold text-gray-600 uppercase mb-2">
        //                                         Schedule DateTime:
        //                                     </label>
        //                                     <input
        //                                         type="datetime-local"
        //                                         name="dateTime"
        //                                         id="dateTime"
        //                                         className="w-full rounded-md px-2 py-1"
        //                                         onChange={handleMessage}
        //                                         ref={dateTimeRef}
        //                                     />
        //                                 </div>
        //                             )
        //                         }

        //                         <div className="my-2">
        //                             {
        //                                 status !== "" ? (
        //                                     <Alerts alertType="Info">{status}</Alerts>
        //                                 ) : error !== "" ? (
        //                                     <p className="text-center text-sm tracking-normal text-red-500">
        //                                         {
        //                                             error && (<Alerts alertType="Error">{error}</Alerts>)
        //                                         }
        //                                     </p>
        //                                 ) : success !== "" ? (
        //                                     <p className="text-center text-sm tracking-normal text-red-500">
        //                                         {
        //                                             error && (<Alerts alertType="Success">{success}</Alerts>)
        //                                         }
        //                                     </p>
        //                                 ) : (
        //                                     ""
        //                                 )
        //                             }
        //                         </div>

        //                         {
        //                             userProfileInfo.planType &&
        //                             (

        //                                 userProfileInfo.planType.includes("WG") ||
        //                                 userProfileInfo.planType.includes("RC")
        //                             ) && (
        //                                 <div className="p-2 text-right">
        //                                     <button
        //                                         className="rounded-md bg-orange-400 py-1 px-4 text-white text-sm"
        //                                         onClick={sendMessage}
        //                                         disabled={isClicked || !isDeviceSelected}
        //                                     >
        //                                         {!isDeviceSelected ? "Select Device" : isClicked ? "Sending..." : "Send Now"}
        //                                     </button>
        //                                     {!isScheduleClicked && (<button
        //                                         className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
        //                                         onClick={() => setIsScheduleClicked(true)}
        //                                     >
        //                                         Schedule
        //                                     </button>)}
        //                                     {isScheduleClicked && !isScheduleProcess && (<button
        //                                         className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
        //                                         disabled={isClicked || !isScheduleClicked || !isDeviceSelected || !dateTimeRef.current.value}
        //                                         onClick={() => sendScheduleMessage()}
        //                                     >
        //                                         Set Schedule
        //                                     </button>)}
        //                                     {isScheduleClicked && isScheduleProcess && (<button
        //                                         className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
        //                                     >
        //                                         Please Wait...
        //                                     </button>)}
        //                                 </div>
        //                             )
        //                         }

        //                     </div>
        //                 </div>

        //             </div>

        //             <div className="col-span-3">
        //                 <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
        //             </div>
        //         </div>
        //     </form>
        // </Container>
        <Container>
            <form id="groupmessage" className="bg-gray-100">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-4 pt-8">
                    {/* Left Section */}
                    <div className="col-span-12 md:col-span-9 h-screen overflow-y-auto">
                        <div className="flex flex-col space-y-4">
                            {/* Connected Device */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <label className="text-sm font-semibold text-gray-600">
                                    CONNECTED DEVICE:
                                </label>
                                <select
                                    onChange={handleSelectedDevice}
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                >
                                    <option value="" key="select device">
                                        Select device
                                    </option>
                                    {devices?.map((device) => (
                                        <option
                                            name={device.deviceName}
                                            key={device.deviceName}
                                            value={device.deviceName}
                                        >
                                            {device.deviceName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Select Template */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <label className="text-sm font-semibold text-gray-600">
                                    SELECT TEMPLATE:
                                </label>
                                <select
                                    ref={selectedTemplateRef}
                                    onChange={handleSelectTemplate}
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                >
                                    <option value="" key="Select Template">
                                        Select Template
                                    </option>
                                    {
                                        templates &&
                                        templates.length > 0 &&
                                        templates?.map((template) => (
                                            <option
                                                name={template.templateName}
                                                key={template.templateName}
                                                value={template.body}
                                            >
                                                {template.templateName}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            {/* Multi WA Group */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <label className="text-sm font-semibold text-gray-600">
                                    SELECT MULTI WA GROUP:
                                </label>
                                <div>
                                    {waGroups.length > 0 ? (
                                        <select
                                            name="selectedGroup"
                                            id="selectedGroup"
                                            ref={selectedGroupRef}
                                            multiple
                                            size={5}
                                            value={selectedGroups}
                                            onChange={handleSelectChange}
                                            className="border border-gray-300 rounded-md p-2 w-full"
                                        >
                                            {waGroups?.map((group) => (
                                                <option value={group.groupId} key={group.groupId}>
                                                    {group.name}
                                                </option>
                                            ))}
                                        </select>
                                    ) : selectedTemplateRef.current?.value ? (
                                        <div className="flex items-center gap-2">
                                            <CircularProgress size={16} />
                                            <span className="text-green-600">
                                                Wait Group is fetching......
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            {/* Message Editor */}
                            <div>
                                <label
                                    htmlFor="message"
                                    className="text-sm font-semibold text-gray-600"
                                >
                                    <span className="text-sm text-gray-600">Que: {totalMessage}</span>{" "}
                                    |{" "}
                                    <span className="text-sm text-gray-600">Sent: {sentMessage}</span>
                                </label>
                                <div className="w-full rounded-md p-2 bg-white h-[200px]">
                                    <ReactQuill
                                        style={{ height: "150px" }}
                                        ref={quillRef}
                                        name="message"
                                        id="message"
                                        onChange={handleQuillChange}
                                        value={message?.message}
                                        disabled={isClicked}
                                        placeholder="Message"
                                        theme="snow"
                                        readOnly={templateSelected}
                                    />
                                </div>
                            </div>

                            {/* File Input */}
                            <div>
                                <label
                                    htmlFor="file"
                                    className="text-sm font-thin text-gray-600 uppercase"
                                >
                                    Attach File:
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    accept=".txt, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    id="file"
                                    onChange={changeHandler}
                                    ref={fileRef}
                                    className="w-full border border-gray-300 rounded-md p-2"
                                />
                                {selectedFile && (
                                    <div className="text-sm text-gray-600">
                                        <p>Filename: {selectedFile?.name}</p>
                                        <p>Filetype: {selectedFile?.type}</p>
                                        <p>Size: {selectedFile?.size} bytes</p>
                                        <p>
                                            Last Modified:{" "}
                                            {selectedFile?.lastModifiedDate?.toLocaleDateString()}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Schedule DateTime */}
                            {isScheduleClicked && (
                                <div className="my-4">
                                    <label
                                        htmlFor="dateTime"
                                        className="text-sm font-semibold text-gray-600 uppercase"
                                    >
                                        Schedule DateTime:
                                    </label>
                                    <input
                                        type="datetime-local"
                                        name="dateTime"
                                        id="dateTime"
                                        onChange={handleMessage}
                                        ref={dateTimeRef}
                                        className="w-full border border-gray-300 rounded-md px-2 py-1"
                                    />
                                </div>
                            )}

                            {/* Alerts */}
                            <div>
                                {status ? (
                                    <Alerts alertType="Info">{status}</Alerts>
                                ) : error ? (
                                    <Alerts alertType="Error">{error}</Alerts>
                                ) : success ? (
                                    <Alerts alertType="Success">{success}</Alerts>
                                ) : null}
                            </div>

                            {/* Buttons */}
                            <div className="flex flex-wrap gap-4 justify-end">
                                <button
                                    className="bg-orange-400 hover:bg-orange-500 text-white py-2 px-4 rounded-md"
                                    onClick={sendMessage}
                                    disabled={isClicked || !isDeviceSelected}
                                >
                                    {!isDeviceSelected
                                        ? "Select Device"
                                        : isClicked
                                            ? "Sending..."
                                            : "Send Now"}
                                </button>
                                {!isScheduleClicked && (
                                    <button
                                        className="bg-blue-400 hover:bg-blue-500 text-white py-2 px-4 rounded-md"
                                        onClick={() => setIsScheduleClicked(true)}
                                    >
                                        Schedule
                                    </button>
                                )}
                                {isScheduleClicked && (
                                    <button
                                        className="bg-blue-400 hover:bg-blue-500 text-white py-2 px-4 rounded-md"
                                        onClick={sendScheduleMessage}
                                        disabled={
                                            isClicked ||
                                            !isScheduleClicked ||
                                            !isDeviceSelected ||
                                            !dateTimeRef.current?.value
                                        }
                                    >
                                        {isScheduleProcess ? "Please Wait..." : "Set Schedule"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Emoji Section */}
                    <div className="md:col-span-3 hidden md:block">
                        <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
                    </div>
                </div>
            </form>
        </Container>

    );
}

export default GroupMessage;
