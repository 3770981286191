import React from 'react'
import busy from './busy.png'
import Container from '../../components/Container'
import Sidebar from '../../components/Sidebar'

function Busy() {
    return (
        <div className="flex w-screen ">
            <Sidebar />
            <Container>
                <img src={busy} alt='busy' />
            </Container>
        </div>
    )
}

export default Busy
