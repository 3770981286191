import React, { useCallback, useEffect, useState } from 'react';
import { Add, Remove } from '@mui/icons-material';
import useGoogleSheet from '../hook/useGoogleSheet';
import useGoogleEventSheet from '../hook/useGoogleEventSheet';
import { gql, useQuery } from '@apollo/client';
import axios from 'axios';
import { Tooltip } from '@mui/material';

// Component for selecting a sheet
function SheetSelector({ sheetNames, onSelect }) {
    return (
        <select onChange={(e) => onSelect(e.target.value)}>
            <option>Select a page</option>
            {sheetNames && sheetNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
            ))}
        </select>
    );
}

// Component for selecting a header
function HeaderSelector({ columnHeaders, onSelect, mapColumn }) {
    return (
        <select onChange={(e) => onSelect(e.target.value, mapColumn)}>
            <option>Map column</option>
            {columnHeaders.map((header, index) => (
                <option key={index} value={header}>{header}</option>
            ))}
        </select>
    );
}

const GET_TEMPLATES = gql`
    query($uid: String!){
        templateByUID(uid:$uid) {
            id,
            templateName,
            body
        }
    }
`

function PhoneWiseEventRow({ id, rowData, handleInputChange, removeRow, columnValues, userInfo }) {

    const [templates, setTemplates] = useState(null);

    const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
        variables: {
            uid: userInfo.uid
        }
    });

    useEffect(() => {

        if (templateData) {
            let data = templateData.templateByUID;

            setTemplates(data);
        }
    }, [templateData, templateLoading]);

    return (
        <div className='grid grid-cols-4 gap-2 items-center justify-around my-2'>
            <select
                name='event'
                onChange={(e) => handleInputChange(id, 'event', e.target.value)}
                value={rowData.event || ''}
            >
                <option>Select a Value</option>
                {columnValues.map((value, index) => (
                    <option key={index} value={value}>{value}</option>
                ))}
            </select>
            <select
                name='message'
                onChange={(e) => handleInputChange(id, 'message', e.target.value)}
                value={rowData.message || ''}
            >
                <option name="" value="" key="Select Template">Select Template</option>
                {
                    templates && templates.map((template) => {
                        return (
                            <option name={template.templateName} key={template.templateName} value={template.body} >{template.templateName}</option>
                        )
                    }
                    )
                }
            </select>
            <input
                type="text"
                name='phone'
                value={rowData.phone || ''} // Ensure value isn't undefined
                onChange={(e) => handleInputChange(id, 'phone', e.target.value)}
            />
            <button className='justify-self-center' onClick={() => removeRow(id)}>
                <Remove fontSize="large" className='bg-red-500 text-white rounded-full px-2 py-2' />
            </button>
        </div>
    );
}

function GroupWiseEventRow({ id, rowData, handleInputChange, removeRow, columnValues, userInfo, waGroups }) {

    const [templates, setTemplates] = useState(null);

    const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
        variables: {
            uid: userInfo.uid
        }
    });

    useEffect(() => {

        if (templateData) {
            let data = templateData.templateByUID;

            setTemplates(data);
        }
    }, [templateData, templateLoading]);

    return (
        <div className='grid grid-cols-4 gap-2 items-center justify-around my-2'>
            <select
                name='event'
                onChange={(e) => handleInputChange(id, 'event', e.target.value)}
                value={rowData.event || ''}
            >
                <option>Select a Value</option>
                {columnValues.map((value, index) => (
                    <option key={index} value={value}>{value}</option>
                ))}
            </select>
            <select
                name='message'
                onChange={(e) => handleInputChange(id, 'message', e.target.value)}
                value={rowData.message || ''}
            >
                <option name="" value="" key="Select Template">Select Template</option>
                {
                    templates && templates.map((template) => {
                        return (
                            <option name={template.templateName} key={template.templateName} value={template.body} >{template.templateName}</option>
                        )
                    }
                    )
                }
            </select>

            <select
                name="group"
                onChange={(e) => handleInputChange(id, 'group', e.target.value)}
                value={rowData.group || ''}
            >
                <option value="">Select WA Groups</option>
                {
                    waGroups && (

                        waGroups.map(group => (
                            <option value={group.groupId} key={group.groupId}>
                                {group.name}
                            </option>
                        ))
                    )
                }
            </select>
            <button className='justify-self-center' onClick={() => removeRow(id)}>
                <Remove fontSize="large" className='bg-red-500 text-white rounded-full px-2 py-2' />
            </button>
        </div>
    );
}

// Main Component
function GoogleSheetEventHandler({
    setColumnHeader,
    userInfo,
    setSelectedData,
    setMapColumnValues,
    setSelectedSheetUrl,
    waGroups
}) {
    const {
        sheetEventUrl,
        setSheetEventUrl,
        sheetEventNames,
        columnEventHeaders,
        columnEventValues,
        fetchSheetEventNames,
        fetchColumnEventHeaders,
        fetchColumnEventValues,
        processingEvent
    } = useGoogleEventSheet();

    const {
        sheetId,
        sheetUrl,
        setSheetUrl,
        sheetNames,
        columnHeaders,
        fetchSheetNames,
        fetchColumnHeaders,
        mapHeaderColumnValues,
        mapColumnValues,
    } = useGoogleSheet();

    useEffect(() => {
        if (columnHeaders) {
            setColumnHeader(columnHeaders)
        }
    }, [columnHeaders])

    const [rows, setRows] = useState([]); // State to track rows and their data

    // Add a new row to the state
    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { id: prevRows.length + 1, uid: userInfo.uid, sheetId, message: '', phone: '', event: '' } // Add new row with empty inputs
        ]);
    };

    const handleInputChange = (id, field, value) => {

        setRows(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, [field]: value } : row // Only update the specific field of the affected row
            )
        );
    };

    // Remove row by id
    const removeRow = useCallback((id) => {
        setRows((prevRows) => prevRows.filter(row => row.id !== id));
    }, []);

    const [activeTab, setActiveTab] = useState('phone');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (rows.length > 0) setSelectedData(rows)
    }, [rows])

    useEffect(() => {
        if (mapColumnValues) setMapColumnValues(mapColumnValues)
    }, [mapColumnValues])

    return (
        <div className="grid grid-cols-1 my-4 ">
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-6'>
                    <div className='flex justify-start gap-2'>
                        <input
                            type="text"
                            value={sheetUrl || sheetEventUrl || ''}
                            onChange={(e) => {
                                setSheetUrl(e.target.value)
                                setSelectedSheetUrl(e.target.value)
                                setSheetEventUrl(e.target.value)
                            }}
                            placeholder="paste google sheet url here"
                        />

                        <div >
                            {
                                !processingEvent && (<button className="w-[160px] rounded-full bg-blue-500 text-white px-4 py-1 text-xs disabled:bg-blue-300" disabled={sheetUrl.trim() === ''} onClick={() => {
                                    fetchSheetNames()
                                    fetchSheetEventNames()
                                }}>
                                    Sync
                                </button>)
                            }
                            {
                                processingEvent && (<button className='w-[160px] rounded-full bg-blue-500 text-white px-4 py-1 text-xs' disabled>Wait...</button>)}
                        </div>
                    </div>
                </div>
            </div>

            {
                sheetNames &&
                sheetNames.length > 0 &&
                (
                    <>
                        <div className="grid grid-cols-2 my-4 gap-2">
                            <div>
                                <div className="grid grid-cols-2 my-4 items-center">
                                    <div>
                                        <p className="text-xs font-sans text-gray-800 mb-2 font-bold">
                                            Select data page:
                                        </p>
                                    </div>
                                    <div>
                                        <SheetSelector sheetNames={sheetNames} onSelect={fetchColumnHeaders} />
                                    </div>
                                </div>

                                {
                                    columnHeaders.length > 0 && (
                                        <>
                                            <div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Name:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="name" />
                                                </div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Phone:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="phone" />
                                                </div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Message:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="message" />
                                                </div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Trigger column:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="status" />
                                                </div>

                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div>
                                <div className="grid grid-cols-2 my-4 items-center">
                                    <div>
                                        <p className="text-xs font-sans text-gray-800 mb-2 font-bold">
                                            Select trigger page:
                                        </p>
                                    </div>
                                    <div>
                                        <SheetSelector sheetNames={sheetEventNames} onSelect={fetchColumnEventHeaders} />
                                    </div>
                                </div>

                                {
                                    columnEventHeaders.length > 0 && (
                                        <>
                                            <div>
                                                <div>
                                                    <div className="grid grid-cols-2 my-2 items-center">
                                                        <label className=" font-semibold text-gray-800">Trigger values:</label>
                                                        <HeaderSelector columnHeaders={columnEventHeaders} onSelect={fetchColumnEventValues} mapColumn="actions" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        {
                            columnEventValues &&
                            Object.entries(columnEventValues).length > 0 &&
                            (
                                <>
                                    <div className="grid grid-cols-1 my-4">
                                        <p className="flex justify-center text-xs font-bold">-----------------------------------------SET TRIGGER ACTION-----------------------------------------</p>
                                    </div>

                                    {/* <div className='grid grid-cols-1 justify-center items-center'>
                                        <div>
                                            <div>
                                                <div className="grid grid-cols-2 my-2">
                                                    <label className="text-sm font-bold text-gray-800 my-2">Set Action : </label>
                                                    <button className='justify-self-end'>
                                                        <Add fontSize="large" className=' bg-blue-500 text-white rounded-full px-2 py-2' onClick={addRow} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-4 gap-2 items-center justify-around'>
                                                <div className='bg-gray-800 text-white p-2 text-center'>Trigger</div>
                                                <div className='bg-gray-800 text-white p-2 text-center'>Message</div>
                                                <div className='bg-gray-800 text-white p-2 text-center'>Phone</div>
                                                <div className='bg-gray-800 text-white p-2 text-center'>Delete</div>
                                            </div>
                                            <div id="eventRow">
                                                {
                                                    rows.map((row) => (
                                                        <PhoneWiseEventRow
                                                            key={row.id}
                                                            id={row.id}
                                                            rowData={row}
                                                            handleInputChange={handleInputChange}
                                                            removeRow={removeRow}
                                                            columnValues={columnEventValues.actions}
                                                            userInfo={userInfo}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='flex flex-col'>
                                        {/* Tab Headers */}
                                        <div className='flex justify-center mb-4'>
                                            <button
                                                onClick={() => handleTabChange('phone')}
                                                className={`px-4 py-2 ${activeTab === 'phone' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                            >
                                                Phone-Wise Events
                                            </button>
                                            <Tooltip title="You are not a valid user for this service">
                                                <button
                                                    onClick={() => handleTabChange('group')}
                                                    className={`px-4 py-2 ${activeTab === 'group' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                                    disabled={waGroups.length === 0}
                                                >
                                                    Group-Wise Events
                                                </button>
                                            </Tooltip>
                                        </div>

                                        {/* Tab Content */}
                                        {activeTab === 'phone' && (
                                            <div className='grid grid-cols-1 justify-center items-center'>
                                                <div>
                                                    <div className="grid grid-cols-2 my-2">
                                                        <label className="text-sm font-bold text-gray-800 my-2">Set Action:</label>
                                                        <button className='justify-self-end'>
                                                            <Add fontSize="large" className=' bg-blue-500 text-white rounded-full px-2 py-2' onClick={addRow} />
                                                        </button>
                                                    </div>
                                                    <div className='grid grid-cols-4 gap-2 items-center justify-around'>
                                                        <div className='bg-gray-800 text-white p-2 text-center'>Trigger</div>
                                                        <div className='bg-gray-800 text-white p-2 text-center'>Message</div>
                                                        <div className='bg-gray-800 text-white p-2 text-center'>Phone</div>
                                                        <div className='bg-gray-800 text-white p-2 text-center'>Delete</div>
                                                    </div>
                                                    <div id="eventRow">
                                                        {rows.map((row) => (
                                                            <PhoneWiseEventRow
                                                                key={row.id}
                                                                id={row.id}
                                                                rowData={row}
                                                                handleInputChange={handleInputChange}
                                                                removeRow={removeRow}
                                                                columnValues={columnEventValues.actions}
                                                                userInfo={userInfo}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {
                                            activeTab === 'group' &&
                                            waGroups.length > 0 &&
                                            (
                                                <div className='grid grid-cols-1 justify-center items-center'>
                                                    <div>
                                                        <div className="grid grid-cols-2 my-2">
                                                            <label className="text-sm font-bold text-gray-800 my-2">Set Action:</label>
                                                            <button className='justify-self-end'>
                                                                <Add fontSize="large" className=' bg-blue-500 text-white rounded-full px-2 py-2' onClick={addRow} />
                                                            </button>
                                                        </div>
                                                        <div className='grid grid-cols-4 gap-2 items-center justify-around'>
                                                            <div className='bg-gray-800 text-white p-2 text-center'>Trigger</div>
                                                            <div className='bg-gray-800 text-white p-2 text-center'>Message</div>
                                                            <div className='bg-gray-800 text-white p-2 text-center'>Group</div>
                                                            <div className='bg-gray-800 text-white p-2 text-center'>Delete</div>
                                                        </div>
                                                        <div id="eventRow">
                                                            {rows.map((row) => (
                                                                <GroupWiseEventRow
                                                                    key={row.id}
                                                                    id={row.id}
                                                                    rowData={row}
                                                                    handleInputChange={handleInputChange}
                                                                    removeRow={removeRow}
                                                                    columnValues={columnEventValues.actions}
                                                                    userInfo={userInfo}
                                                                    waGroups={waGroups}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </>
                            )
                        }

                    </>
                )
            }
        </div>
    );
}

export default GoogleSheetEventHandler;
