import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import Sidebar from '../../components/Sidebar';

import { gql, useQuery } from "@apollo/client";

const ENDPOINT = `https://apigully.com/`

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

function WebHook() {
    const [events, setEvents] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);

    const socket = socketIOClient(ENDPOINT);

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const { refetch, data, loading } = useQuery(DEVICE_GET);

    const handleSelectedDevice = (e) => {
        if (e.target.checked) {
            setSelectedDevice(e.target.name)
        }
    }

    useEffect(() => {

        if (
            userInfo &&
            userInfo.uid &&
            selectedDevice
        ) {

            socket.emit('joinRoom', `${userInfo.uid}-${selectedDevice}`);

            socket.on(`${userInfo.uid}-${selectedDevice}`, (msg) => {
                console.log("msg", msg)
                setEvents((prevMsg) => [...prevMsg, msg]);
            });

            socket.on('disconnect', () => {
                console.log("Disconnected to the server")
            });

            return () => {
                socket.disconnect();
                socket.off(`${userInfo.uid}-${selectedDevice}`);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, selectedDevice]);


    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    return (
        <div className="flex w-screen ">
            <Sidebar />

            <div className="body w-64 flex-1 bg-white p-8 h-screen">

                <div className=" flex flex-col items-center justify-center border-2 border-gray-300 shadow-md p-4 rounded-xl bg-white w-10/12 m-auto">
                    <h2 className="text-lg font-serif text-gray-800 uppercase">CONNECTED DEVICE : </h2>
                    <div className="mt-4 flex justify-start items-center space-x-2" >
                        {devices && devices.map((device) => {
                            return (
                                <div className="flex justify-start items-center space-x-2" key={device.deviceName}>
                                    <input type="checkbox" name={device.deviceName} id={device.deviceName} key={device.deviceName} onChange={handleSelectedDevice} />
                                    <label htmlFor={device.deviceName} className="text-xl font-serif">{device.deviceName}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>
                    <h1>Webhook Events</h1>
                    <ul>
                        {events.map((event, index) => (
                            <li key={index}>{JSON.stringify(event)}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    );
}

export default WebHook;
