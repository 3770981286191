import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { ContactPhone } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { View } from 'lucide-react';

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      name
      email
      mobile
      password
      address
      country
      doj
      planType
      userType
      expireInDays
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

const GROUP_GET = gql`
    query ($userId: Int!) {  
        groupsByID(userId: $userId) {    
            id    
            groupName    
            contacts {waGroupId}  
        }
    }
`;

const GET_CONTACTS = gql`
    query($groupId: Int!){
        contactsByGroupID(groupId: $groupId) {
            id
            groupId
            waGroupId
            formattedName
            waContact
            phone
        }
    }
`;

function GroupList({ handleGroupContact }) {

    const [selectedGroupContacts, setSelectedGroupContacts] = useState([])
    const [rowModesModel, setRowModesModel] = React.useState({});

    const columns = [
        { field: 'groupId', headerName: 'GID', width: 70, editable: false },
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'formattedName',
            headerName: 'Full name',
            width: 300,
            editable: true,
            renderEditCell: (params) => {
                const handleChange = (event) => {
                    // Update the cell's value using the API provided by DataGrid
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: event.target.value,
                    });
                };

                return (
                    <TextField
                        value={params.value || ''} // Ensure the value is controlled
                        onChange={handleChange} // Update the value on change
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                );
            },
        },
        {
            field: 'phone',
            headerName: 'Phone',
            type: 'string',
            width: 200,
            editable: true,
            renderEditCell: (params) => {
                const handleChange = (event) => {
                    // Update the cell's value using the API provided by DataGrid
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: event.target.value,
                    });
                };

                return (
                    <TextField
                        value={params.value || ''} // Ensure the value is controlled
                        onChange={handleChange} // Update the value on change
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                );
            },
        },
        { field: 'waGroupId', headerName: 'Group ID', width: 300, editable: false },
        { field: 'waContact', headerName: 'WA Contact', width: 200, editable: false }
    ];

    const theme = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#fafbfc',
                darker: '#053e85',
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
        },
    });

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };


    const [migration, setMigration] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [allGroups, setAllGroups] = useState([]);
    const [devices, setDevices] = useState([]);
    const [waGroups, setWaGroups] = useState([]);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const [showGroupContacts, setShowGroupContacts] = useState(false);
    const selectedGroupContactRef = useRef("");

    const { data } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    const { data: deviceData, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const [groups, { data: groupsData }] = useLazyQuery(GROUP_GET);
    const [getContacts, { data: contactsData }] = useLazyQuery(GET_CONTACTS);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([])

    const handleContacts = async (group) => {
        setExportProcessing(true);

        if (group && group.id) {
            await getContacts({
                variables: {
                    "groupId": Number(group.id)
                }
            });
        }

        setExportProcessing(false);
    }

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => selectedGroupContacts.find((row) => row.id === id));
        setSelectedContacts(selectedRowsData);
    };

    const handleSelectedContacts = () => {
        handleGroupContact(selectedContacts);
    }

    const handleCancel = () => {
        setShowGroupContacts(false);
        setSelectedContacts([])
        setWaGroups([])
        setMigration(false);
    }

    const handleSelectedGroupContact = (group) => {
        selectedGroupContactRef.current = group
        handleContacts(group);
        setShowGroupContacts(!showGroupContacts)
    }

    const [snackbar, setSnackbar] = React.useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        let devicesByUID = null;
        if (deviceData) {
            devicesByUID = deviceData.devicesByUID;
            devicesByUID = devicesByUID.filter(dm => dm.isConnected)
            setDevices(devicesByUID);
        }
    }, [deviceData, loading]);

    useEffect(() => {
        let userProfile = null;
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);

            groups(
                {
                    variables: {
                        "userId": Number(userProfile.id)
                    }
                }
            )
        }
    }, [data]);

    useEffect(() => {
        let groups = [];
        if (groupsData) {
            groups = groupsData.groupsByID
            setAllGroups(groups)
            setShowGroupContacts(false);
        }
    }, [groupsData])

    useEffect(() => {

        let gcontacts = [];

        if (contactsData && contactsData.contactsByGroupID.length > 0) {
            gcontacts = contactsData.contactsByGroupID
            setSelectedGroupContacts(gcontacts);
        }

        setShowGroupContacts(true);
        setDeleteProcessing(false);

    }, [contactsData])

    return (
        <>
            <div className='grid grid-cols-1 gap-2 w-full'>

                {
                    allGroups &&
                    allGroups.length > 0 &&
                    !showGroupContacts &&
                    (
                        <div>
                            <p className='font-serif font-bold text-center text-gray-600  mt-4 text-lg tracking-widest px-4 py-2 space-y-2'>SAVED GROUPS</p>
                            <ul className='h-[300px] overflow-y-scroll  m-auto'>
                                {
                                    allGroups.map((group, index) => {
                                        return (
                                            <li className={`flex justify-between items-center bg-lime-500 px-4 py-1 rounded-xl mt-2 text-white`} key={group.id}>
                                                {
                                                    exportProcessing ?
                                                        (
                                                            <ThemeProvider theme={theme}>
                                                                <CircularProgress size={14} color="primary" />
                                                            </ThemeProvider>
                                                        ) : (
                                                            <ContactPhone />
                                                        )
                                                }

                                                <p>{group.groupName}</p>
                                                <div className='flex justify-center items-center gap-2 cursor-pointer'>
                                                    <button onClick={() => handleSelectedGroupContact(group)}>
                                                        <View />
                                                    </button>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                }

                {
                    selectedGroupContacts &&
                    selectedGroupContacts.length > 0 &&
                    showGroupContacts &&
                    (
                        <div className='bg-white p-4'>
                            <h1 className='text-center text-sm font-serif text-gray-600 font-medium tracking-widest my-2 '>GROUP CONTACTS</h1>
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    '& .actions': {
                                        color: 'text.secondary',
                                    },
                                    '& .textPrimary': {
                                        color: 'text.primary',
                                    },
                                }}>
                                <DataGrid
                                    rows={selectedGroupContacts}
                                    columns={columns}
                                    pageSize={100}
                                    editMode="row"
                                    rowModesModel={rowModesModel}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    columnVisibilityModel={{
                                        groupId: false,
                                    }}

                                />
                                {!!snackbar && (
                                    <Snackbar
                                        open
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        onClose={handleCloseSnackbar}
                                        autoHideDuration={5000}
                                    >
                                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                    </Snackbar>
                                )}
                            </Box>


                            <div className='flex justify-end my-2 space-x-2 w-full'>

                                <button type='button' className='text-xs px-2 py-1 bg-red-400 text-white rounded-lg' onClick={handleCancel}>Cancel</button>

                                <button type='button' className='text-xs px-2 py-1 bg-blue-400 text-white rounded-lg' onClick={handleSelectedContacts}>Select Contacts</button>
                            </div>
                        </div>
                    )
                }
            </div>

        </>
    )
}

export default GroupList