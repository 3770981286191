import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import DataTable from "../../components/DataTable";
import Container from "../../components/Container";

const GET_ORDERS = gql`
  query ($uid: String!) {
    OrdersByUser(uid: $uid) {
      orderId
      name
      companyName
      gstin
      planType
      email
      mobile
      amount
      status
      createdAt
    }
  }
`;

const columns = [
  {
    field: "createdAt",
    headerName: "Date",
    minWidth: 130,
    align: "left",
    format: (value) => {
      moment(new Date(Number(value)))
        .utc()
        .format("DD-MM-YYYY");
    },
  },
  {
    field: "orderId",
    headerName: "Order Id",
    minWidth: 130,
    align: "left",
  },
  { field: "planType", headerName: "Plan Name", minWidth: 170, align: "left" },
  { field: "name", headerName: "Name", minWidth: 170, align: "left" },
  { field: "companyName", headerName: "Company Name", minWidth: 170, align: "left" },
  { field: "gstin", headerName: "GSTIN", minWidth: 170, align: "left" },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 130,
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 130,
    align: "center",
  },
];

function Order() {
  let location = useLocation();

  const userInfo = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const [order, setOrder] = useState(null);
  const [userOrdersData, setUserOrdersData] = useState([]);
  const [processing, setProcessing] = useState(false)

  const { data, loading } = useQuery(GET_ORDERS, {
    variables: {
      uid: userInfo.uid,
    },
  });

  const changeHandler = (e) => {
    setOrder((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const handlePaymentSubmit = async (orderId, totalAmount) => {

    try {
      const response = await axios.post('https://messagesapi.co.in/create-payment', {
        order_id: orderId,
        customer_name: order.name,
        customer_email: order.email,
        customer_phone: order.mobile,
        planType: order.planType,
        amount: totalAmount
      });

      if (response.data.paymentUrl) {
        window.location.href = response.data.paymentUrl;
      }
    } catch (error) {
      console.error('Error during payment initiation:', error);
    }
  };

  const handleOrder = (e) => {
    e.preventDefault();
    setProcessing(true);
    // Create a FormData object
    const formData = new FormData(e.target);

    // Generate dynamic values for orderID and totalAmount
    const orderID = `ODId_${Date.now()}`;
    const amount = parseFloat(formData.get("grossAmount"));
    const gst = parseFloat((amount * 18) / 100);
    const internetHandlingCharge = parseFloat((amount * 2) / 100);
    const totalAmount = amount + gst + internetHandlingCharge;

    // Append dynamically calculated values to FormData
    formData.append("uid", userInfo.uid);
    formData.append("orderId", orderID);
    formData.append("amount", parseFloat(totalAmount));
    formData.append("internetHandlingCharge", internetHandlingCharge);
    formData.append("gst", gst);

    // Convert FormData to an object for GraphQL variables
    const order = {};
    formData.forEach((value, key) => {
      order[key] = value;
    });

    const data = JSON.stringify({
      query: `mutation($order: OrderInput!){
            orderCreate(order: $order) {
                userErrors {
                    message
                },
                order {
                    id
                    status
                }
            }
        }`,
      variables: {
        order: {
          ...order,
          totalCredits: Number(order.totalCredits),
          amount: parseFloat(order.amount),
          gst: parseFloat(order.gst),
          grossAmount: parseFloat(order.grossAmount),
          internetHandlingCharge: parseFloat(order.internetHandlingCharge),
          userId: Number(order.userId)
        }
      },
    });

    const config = {
      method: "post",
      url: "https://graphql.messagesapi.co.in/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log("Order created:", response.data);
        handlePaymentSubmit(orderID, totalAmount); // Call payment function
        setProcessing(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setProcessing(false);
      });
  };


  useEffect(() => {
    if (data) {
      let { OrdersByUser } = data;

      let uOrder = OrdersByUser
      uOrder = uOrder.map(d => {
        return {
          ...d,
          id: d.orderId
        }
      })
      setUserOrdersData(uOrder);
    }
  }, [data, loading]);

  useEffect(() => {
    setOrder(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {
        order != null && (
          <div className="mx-auto flex w-full flex-col md:flex-row p-4">
            <div className="flex w-full flex-col">
              <h2 className="text-heading mb-4 font-bold md:text-xl ">
                Plan Purchase
              </h2>
              <form
                className="mx-auto w-full justify-center"
                method="post"
                onSubmit={handleOrder}
              >
                <input
                  name="userId"
                  type="hidden"
                  placeholder="userId"
                  value={userInfo.id}
                  onChange={changeHandler}
                />
                <input
                  name="planDetails"
                  type="hidden"
                  placeholder="planDetails"
                  value={JSON.stringify(location.state)}
                  onChange={changeHandler}
                />
                <input
                  name="customerId"
                  type="hidden"
                  placeholder="customerId"
                  value={userInfo.uid}
                  onChange={changeHandler}
                />
                <div className="">
                  <div className="space-x-0 lg:flex lg:space-x-4">
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="firstName"
                        className="mb-3 block text-sm font-semibold "
                      >
                        Name
                      </label>
                      <input
                        name="name"
                        type="text"
                        placeholder="Name"
                        required
                        onChange={changeHandler}
                        className="w-full rounded border border-blue-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-400 lg:text-sm"
                      />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="Email"
                        className="mb-3 block text-sm font-semibold "
                      >
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                        onChange={changeHandler}
                        className="w-full rounded border border-blue-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-400 lg:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-1 space-x-0 lg:flex lg:space-x-4">
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="Phone"
                        className="mb-3 block text-sm font-semibold "
                      >
                        Phone
                      </label>
                      <input
                        name="mobile"
                        type="phone"
                        placeholder="Phone"
                        required
                        onChange={changeHandler}
                        className="w-full rounded border border-blue-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-400 lg:text-sm"
                      />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="companyName"
                        className="mb-3 block text-sm font-semibold "
                      >
                        Company Name (Optional)
                      </label>
                      <input
                        className="w-full rounded border border-blue-300 px-4 py-3 text-xs focus:outline-none focus:ring-1 focus:ring-blue-400 lg:text-sm"
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        onChange={changeHandler}
                      ></input>
                    </div>
                  </div>
                  <div className="mt-1 space-x-0 lg:flex lg:space-x-4">
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="gstin"
                        className="mb-3 block text-sm font-semibold "
                      >
                        GSTIN (Optional)
                      </label>
                      <input
                        name="gstin"
                        type="text"
                        placeholder="GSTIN"
                        onChange={changeHandler}
                        className="w-full rounded border border-blue-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-400 lg:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-1">
                    <div className="grid grid-cols-2 gap-2 lg:w-1/2">
                      <label className="mb-3 block text-sm font-semibold ">
                        Plan Type
                      </label>
                      <input
                        className="mr-2 font-xs text-gray-400"
                        value={order.planType}
                        name="planType"
                        onChange={changeHandler}
                      />
                    </div>
                    <div>
                      <div className="mt-1">
                        <div className="grid grid-cols-2 gap-2 lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold ">
                            Total Credits
                          </label>
                          <input
                            className="mr-2 font-xs text-gray-400"
                            value={order.credits}
                            name="totalCredits"
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <div className="grid grid-cols-2 gap-2 lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold ">
                            Gross Amount
                          </label>
                          <input
                            className="mr-2 font-xs text-gray-400"
                            name="grossAmount"
                            value={order.amount}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <div className="grid grid-cols-2 gap-2 lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold ">
                            GST 18%
                          </label>
                          <input
                            className="mr-2 font-xs text-gray-400"
                            name="gst"
                            value={(order.amount * 18) / 100}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <div className="grid grid-cols-2 gap-2 lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold ">
                            Internet Handling Charge
                          </label>
                          <input
                            className="mr-2 font-xs text-gray-400"
                            name="internetHandlingCharge"
                            value={(order.amount * 2) / 100}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <div className="grid grid-cols-2 gap-2 lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold ">
                            Total Amount
                          </label>
                          <input
                            type="number"
                            className="font-xs text-gray-400"
                            value={
                              Number(order.amount) +
                              Number(order.amount * 18) / 100 +
                              Number(order.amount * 2) / 100
                            }
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 ">
                    {
                      !processing && (
                        <button
                          type="submit"
                          className="disabled:bg-gray-400 px-6 py-2 text-white rounded-full bg-blue-400"
                        >
                          {userInfo.id ? "Checkout" : "Login Once Again"}
                        </button>
                      )
                    }
                    {
                      processing && (
                        <button
                          type="submit"
                          className="disabled:bg-gray-400 px-6 py-2 text-white rounded-full bg-blue-400"
                        >
                          Processing...
                        </button>
                      )
                    }

                  </div>
                </div>
              </form>
            </div>
          </div>
        )
      }

      {
        order == null && (
          <>
            <div>
              <p className="text-green-400  flex justify-center items-center py-4 text-xl font-semibold">ORDER HISTORY</p>
            </div>
            <div className="grid grid-cols-12 gap-2  p-4 pt-0 h-screen overflow-y-scroll no-scrollbar">
              <div className="col-span-12 ">
                {
                  userOrdersData &&
                    userOrdersData.length >= 0 ? (
                    <DataTable columns={columns} data={userOrdersData} />
                  ) : (
                    <div className="h-screen w-full flex justify-center items-center">
                      <p className="text-xl font-semibold text-red-500">
                        no records found
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </>
        )
      }
    </Container>
  );
}

export default Order;
