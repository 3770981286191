import React, { useState, useEffect, useRef } from "react";

import { VisibilityOff, Visibility } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";

import { Link, useSearchParams } from "react-router-dom";


const USER_GET = gql`
  query getUserById($uid: String!) {
    user(uid: $uid) {
        id
        uid
    }
  }
`;

const USER_UPDATE = gql`
  mutation ($userId: ID!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userErrors {
        message
      }
      user {
        name
      }
    }
  }
`;

function PasswordChange() {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isVisibility, setIsVisibility] = useState(false);
    const [isConfirmVisibility, setIsConfirmVisibility] = useState(false);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const [userProfile, setUserProfile] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    let uid = searchParams.get("uid");
    let id = searchParams.get("id");

    //GRAPHQL USER QUERY START
    const { data, error, loading } = useQuery(USER_GET, {
        variables: { uid: uid, id: id },
    });
    //GRAPHQL USER QUERY END

    //GRAPHQL USER CHANGE PASSWORD START
    const [
        changePassword,
        {
            data: userPasswordData,
            loading: userPasswordLoading,
            error: userPasswordError,
        },
    ] = useMutation(USER_UPDATE);
    //GRAPHQL USER CHANGE PASSWORD END

    useEffect(() => {
        let userProfile = null;
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);
        }
    }, [data, loading]);

    const changePasswordHandler = () => {
        if (
            passwordRef.current.value &&
            confirmPasswordRef.current.value &&
            passwordRef.current.value !== "" &&
            confirmPasswordRef.current.value !== "" &&
            passwordRef.current.value === confirmPasswordRef.current.value
        ) {

            changePassword({
                variables: {
                    userId: userProfile.uid,
                    user: {
                        password: passwordRef.current.value,
                    },
                },
            });

            setErrorMessage("");
            setSuccessMessage("Password changed successfully!");
            passwordRef.current.value = "";
            confirmPasswordRef.current.value = "";

        } else {
            setSuccessMessage("");
            setErrorMessage("Password is not valid or mismatch");
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
            setSuccessMessage("");
        }, 10000);
    }, [errorMessage, successMessage]);

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <div>
                {
                    successMessage && (<p className="text-sm text-green-500 text-center">{successMessage}</p>)
                }

                {
                    errorMessage && (<p className="text-sm text-red-500 font-semibold">{errorMessage}</p>)
                }
            </div>
            <div className="bg-white rounded-md p-4 shadow-xl">
                {userProfile && userProfile.uid && !loading ? (
                    <div className="grid grid-cols-1">
                        <h1 className="text-xl text-center uppercase text-gray-400">Change Password</h1>
                        <hr />
                        <div className="grid grid-cols-1 self-center gap-2 p-2">
                            <div className="grid grid-cols-2 gap-2 items-center">
                                <label
                                    htmlFor="password"
                                    className="text-sm uppercase text-gray-400"
                                >
                                    Password
                                </label>
                                <div className="relative flex sm:flex-col sm:items-center sm:justify-center">

                                    <input
                                        type="password"
                                        className="border-black-400 ml-2 rounded-md border-2 p-1"
                                        name="password"
                                        defaultValue={
                                            passwordRef.current ? passwordRef.current.value : ""
                                        }
                                        ref={passwordRef}
                                    />
                                    <label
                                        htmlFor="peye"
                                        className="absolute top-1 right-0"
                                        onClick={() => {
                                            setIsVisibility(!isVisibility);
                                            if (!isVisibility) passwordRef.current.type = "text";
                                            else passwordRef.current.type = "password";
                                        }}
                                    >
                                        {isVisibility && <Visibility />}
                                        {!isVisibility && <VisibilityOff />}
                                    </label>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2 items-center">

                                <label
                                    htmlFor="confirmPassword"
                                    className="text-sm uppercase text-gray-400"
                                >
                                    Confirm Password
                                </label>

                                <div className="relative flex sm:flex-col sm:items-center sm:justify-center">

                                    <input
                                        type="password"
                                        className="border-black-400 ml-2 rounded-md border-2 p-1"
                                        name="confirmPassword"
                                        defaultValue={
                                            confirmPasswordRef.current
                                                ? confirmPasswordRef.current.value
                                                : ""
                                        }
                                        ref={confirmPasswordRef}
                                    />
                                    <label
                                        htmlFor="cpeye"
                                        className="absolute top-1 right-0"
                                        onClick={() => {
                                            setIsConfirmVisibility(!isConfirmVisibility);
                                            if (!isConfirmVisibility)
                                                confirmPasswordRef.current.type = "text";
                                            else confirmPasswordRef.current.type = "password";
                                        }}
                                    >
                                        {isConfirmVisibility && <Visibility />}
                                        {!isConfirmVisibility && <VisibilityOff />}

                                    </label>
                                </div>
                            </div>
                        </div>
                        <p className="font-md my-4 text-red-500">{error}</p>
                        <hr />
                        <div className="mt-4 flex w-full justify-end">
                            <button
                                className=" bg-blue-800 py-2 px-4  text-white text-xs rounded-md"
                                onClick={() => changePasswordHandler()}
                            >
                                Change
                            </button>
                            <Link
                                className="ml-4  bg-yellow-400 px-4 py-2 text-white text-xs rounded-md"
                                to="/login"
                            >
                                Cancel
                            </Link>

                        </div>
                    </div>) : !loading ?
                    (
                        <div className="grid grid-cols-1">
                            <h1 className="text-xl text-center uppercase text-gray-400 ">Change Password</h1>
                            <hr />
                            <p className="text-sm text-red-500 font-semibold">You are not a valid user!</p>
                        </div>
                    ) : (
                        <div>Please wait.....</div>
                    )
                }
            </div>

        </div>
    );
}

export default PasswordChange;
