import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_KEY = 'AIzaSyBtTBT1HrhXS_0k4UXjVVYH07UcX7_6afg'; // Replace with your API key

// Custom Hook to handle Google Sheets operations
function useGoogleEventSheet() {
    const [sheetEventUrl, setSheetEventUrl] = useState('');
    const [sheetEventNames, setSheetEventNames] = useState([]);
    const [selectedEventSheet, setSelectedEventSheet] = useState('');
    const [columnEventHeaders, setColumnEventHeaders] = useState([]);
    const [selectedEventHeader, setSelectedEventHeader] = useState('');
    const [columnEventValues, setColumnEventValues] = useState({});
    const [processingEvent, setProcessingEvent] = useState(false);

    const extractSheetId = (url) => {
        const match = url.match(/\/d\/([a-zA-Z0-9-_]+)\//);
        return match ? match[1] : null;
    };

    const getSheetNames = async (sheetId) => {
        try {
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}?key=${API_KEY}`
            );
            return response.data.sheets.map(sheet => sheet.properties.title);
        } catch (error) {
            console.error('Error fetching sheet names:', error);
            return [];
        }
    };

    const getColumnHeaders = async (sheetId, sheetEventNames) => {
        try {
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetEventNames}!1:1?key=${API_KEY}`
            );
            return response.data.values ? response.data.values[0] : [];
        } catch (error) {
            console.error('Error fetching column headers:', error);
            return [];
        }
    };

    const getColumnLetter = (headers, headerName) => {
        const index = headers.indexOf(headerName);

        if (index === -1) return null;

        return String.fromCharCode(65 + index); // 65 is the char code for 'A'
    };

    const getColumnValues = async (sheetId, sheetEventNames, headerName) => {
        try {
            const headers = await getColumnHeaders(sheetId, sheetEventNames);
            const columnLetter = getColumnLetter(headers, headerName);

            if (!columnLetter) {
                console.error(`Header "${headerName}" not found.`);
                return [];
            }

            const range = `${sheetEventNames}!${columnLetter}:${columnLetter}`;
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${API_KEY}`
            );

            return response.data.values ? response.data.values.slice(1).flat() : [];
        } catch (error) {
            console.error('Error fetching column values:', error);
            return [];
        }
    };

    const fetchSheetEventNames = async () => {
        setProcessingEvent(true)

        const sheetId = extractSheetId(sheetEventUrl);

        if (sheetId) {
            const names = await getSheetNames(sheetId);
            setSheetEventNames(names);
            setProcessingEvent(false)
        } else {
            console.error('Invalid Google Sheet URL');
            setProcessingEvent(false)
        }

    };

    const fetchColumnEventHeaders = async (sheetEventNames) => {
        const sheetId = extractSheetId(sheetEventUrl);

        if (sheetId) {
            setSelectedEventSheet(sheetEventNames);
            const headers = await getColumnHeaders(sheetId, sheetEventNames);
            setColumnEventHeaders(headers);
        }
    };

    const fetchColumnEventValues = async (header, mapColumn) => {
        const sheetId = extractSheetId(sheetEventUrl);

        if (sheetId) {
            setSelectedEventHeader(header);
            const values = await getColumnValues(sheetId, selectedEventSheet, header);

            setColumnEventValues(prevData => {
                return {
                    ...prevData,
                    [mapColumn]: values
                }
            });

        }
    };

    return {
        sheetEventUrl,
        setSheetEventUrl,
        sheetEventNames,
        selectedEventSheet,
        columnEventHeaders,
        selectedEventHeader,
        columnEventValues,
        fetchSheetEventNames,
        fetchColumnEventHeaders,
        fetchColumnEventValues,
        processingEvent
    };
}

export default useGoogleEventSheet