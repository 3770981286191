import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ContactPhone, ControlPointDuplicate, Download, MobileOff } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Alerts from "../../components/Alerts";
import axios from "axios";
import { Box, CircularProgress, TextField, Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { CopyPlus, HardDriveDownload, Trash, View } from 'lucide-react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import useGroup from '../../hook/useGroup';
import GroupSelector from './GroupSelector';

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            phoneNumber
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      name
      email
      mobile
      password
      address
      country
      doj
      planType
      userType
      expireInDays
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

const GROUP_GET = gql`
    query ($userId: Int!) {  
        groupsByID(userId: $userId) {    
            id    
            groupName    
            contacts {waGroupId}  
            groupAdmins {adminId}  
        }
    }
`;

const GROUP_GET_BY_GROUPID = gql`
    query($waGroupId: String!){
        contactsByWaGroupID(waGroupId: $waGroupId) {
            id
            groupId
            formattedName
            phone
            waGroupId
            waContact
            isWAContact
        }
    }
`;

const ADD_GROUP = gql`
    mutation($group: GroupInput!){
        groupCreate(group: $group) {
            group {
                id
            }
        }
    }
`;

const DELETE_GROUP = gql`
    mutation($groupId: ID!){
        groupDelete(groupId: $groupId) {
            group {
                id
            }
        }
    }
`;

const GET_CONTACTS = gql`
    query($groupId: Int!){
        contactsByGroupID(groupId: $groupId) {
            id
            groupId
            waGroupId
            formattedName
            waContact
            phone
        }
    }
`;

const UPDATE_CONTACTS = gql`
    mutation($contactId: ID!, $contact: ContactInput!){
        contactUpdate(contactId: $contactId, contact: $contact) {
            contact {
                id
            }
        }
    }
`;

const ADD_CONTACTS = gql`
    mutation($contact: ContactInput!){
        contactCreate(contact: $contact) {
            contact {
                id
            }
        }
    }
`;

const DELETE_CONTACTS = gql`
    mutation($contactId: ID!){
        contactDelete(contactId: $contactId) {
            contact {
            id
            }
        }
    }
`;

const useFakeMutation = () => {
    return React.useCallback(
        (user) =>
            new Promise((resolve, reject) =>
                setTimeout(() => {
                    if (user.name?.trim() === '') {
                        reject(new Error("Error while saving user: name can't be empty."));
                    } else {
                        resolve({ ...user, name: user.name?.toUpperCase() });
                    }
                }, 200),
            ),
        [],
    );
};

function Group() {

    const [selectedGroupContacts, setSelectedGroupContacts] = useState([])
    const [rowModesModel, setRowModesModel] = useState({});
    const [contacts, setContacts] = useState([]);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [migration, setMigration] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [allGroups, setAllGroups] = useState([]);
    const [uploadFileVisible, setUploadFileVisible] = useState(false);
    const [devices, setDevices] = useState([]);
    const [waGroups, setWaGroups] = useState([]);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const [showGroupContacts, setShowGroupContacts] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [cloneProcessing, setCloneProcessing] = useState(false);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [phoneGroupsInProgress, setPhoneGroupsInProgress] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState([])
    const [nameNotFound, setNameNotFound] = useState("")
    const [transferProcessing, setTransferProcessing] = useState(false);
    const [selectedGroupAdmins, setSelectedGroupAdmins] = useState([])
    const [addManuallyStatus, setAddManuallyStatus] = useState(true)

    // State to store the selected device
    const [selectedDevice, setSelectedDevice] = useState("");
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState("");
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("Select anyone WA Group")
    const [snackbar, setSnackbar] = useState(null);

    const groupNameRef = useRef("");
    const phonesFile = useRef("");
    const connectedDeviceRef = useRef("");
    const selectedConnectedDeviceRef = useRef("");
    const selectedGroupRef = useRef("");
    const selectedGroupContactRef = useRef("");

    const [groupAdmin, setGroupAdmin] = useState("")

    const {
        createGroup,
        deletePhoneGroup,
        addGroupParticipant,
        deleteGroupParticipant,
        successGroup,
        errorGroup
    } = useGroup()

    const columns = [
        { field: 'groupId', headerName: 'GID', width: 70, editable: false },
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'formattedName',
            headerName: 'Full name',
            width: 300,
            editable: true,
            renderEditCell: (params) => {
                const handleChange = (event) => {
                    // Update the cell's value using the API provided by DataGrid
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: event.target.value,
                    });
                };

                return (
                    <TextField
                        value={params.value || ''} // Ensure the value is controlled
                        onChange={handleChange} // Update the value on change
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                );
            },
        },
        {
            field: 'phone',
            headerName: 'Phone',
            type: 'string',
            width: 200,
            editable: true,
            renderEditCell: (params) => {
                const handleChange = (event) => {
                    // Update the cell's value using the API provided by DataGrid
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: event.target.value,
                    });
                };

                return (
                    <TextField
                        value={params.value || ''} // Ensure the value is controlled
                        onChange={handleChange} // Update the value on change
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                );
            },
        },
        { field: 'waGroupId', headerName: 'Group ID', width: 300, editable: false },
        { field: 'waContact', headerName: 'WA Contact', width: 200, editable: false },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: (props) => {
                let { id } = props

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Save Online / Offline"><SaveIcon /></Tooltip>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                            disabled={!addManuallyStatus}
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancel"><CancelIcon /></Tooltip>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                            disabled={!addManuallyStatus}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit Online / Offline"><EditIcon /></Tooltip>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        disabled={!addManuallyStatus}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete Online / Offline"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        onClick={handleDeleteClick(props)}
                        color="inherit"
                        disabled={!addManuallyStatus}
                    />,
                ];
            },
        },

    ];

    const theme = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#fafbfc',
                darker: '#053e85',
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
        },
    });

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const [addGroup] = useMutation(ADD_GROUP, {
        refetchQueries: [
            GROUP_GET, {
                variables: {
                    "userId": Number(userProfile?.id)
                }
            }
        ]
    })

    const [deleteGroup] = useMutation(DELETE_GROUP, {
        onCompleted: () => setMessage("Group deleted successfully!"),
        refetchQueries: [
            GROUP_GET,
            {
                variables: {
                    "userId": Number(userProfile?.id)
                }
            }
        ]
    })

    const { data } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    const { data: deviceData, loading, refetch } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const [groups, { data: groupsData }] = useLazyQuery(GROUP_GET);
    const [getContactByWaGroupID, { data: contactsByWaGroupID }] = useLazyQuery(GROUP_GET_BY_GROUPID);
    const [getContacts, { data: contactsData }] = useLazyQuery(GET_CONTACTS);

    const [updateContact] = useMutation(UPDATE_CONTACTS, {
        refetchQueries: [
            GET_CONTACTS,
            {
                variables: {
                    "groupId": Number(selectedGroupContactRef.current.id)
                }
            }
        ]
    });

    const [createContact] = useMutation(ADD_CONTACTS, {
        refetchQueries: [
            GET_CONTACTS,
            {
                variables: {
                    "groupId": Number(selectedGroupContactRef.current.id)
                }
            }
        ]
    });

    const [deleteContact] = useMutation(DELETE_CONTACTS, {
        refetchQueries: [
            GET_CONTACTS,
            {
                variables: {
                    "groupId": Number(selectedGroupContactRef.current.id)
                }
            }
        ]
    });

    let formattedContacts = [];

    const handleAllGroupsMember = async (e) => {
        e.preventDefault();

        let groupName = groupNameRef.current?.value.trim()

        if (selectedGroupRef.current.value !== "") {

            if (groupName === "") {
                setError("Group name is required");
                setProcessing(false);
                return
            }

            setProcessing(true);

            try {

                let config = {
                    method: 'get',
                    url: `https://messagesapi.co.in/contact/getGroupMembers/${userInfo.uid}/${selectedDevice}/${selectedGroupRef.current.value}`,
                    headers: {}
                };

                let response = await axios(config);

                if (response.data.length > 0) {

                    // let filterMembers = response.data.filter(fd => !fd.isAdmin)

                    formattedContacts = response.data.map(c => {
                        return {
                            formattedName: c.name,
                            isWAContact: c.isWAContact,
                            waGroupId: selectedGroupRef.current.value,
                            waContact: c.id,
                            phone: c.number,
                            isAdmin: c.isAdmin
                        }
                    })

                }

                setNameNotFound("")
                setContacts(formattedContacts);
                setProcessing(false);
            } catch (error) {
                setProcessing(false);
                setError(error.response?.data?.message);
            }

        } else {
            setProcessing(false);
            setError("Please select connected phone group");

        }
    }

    const handleWAAllGroups = (e) => {
        e.preventDefault();

        if (selectedDevice !== "") {
            setPhoneGroupsInProgress(true);
            setProcessing(true);

            let config = {
                method: 'get',
                url: `https://messagesapi.co.in/contact/getAllGroups/${userInfo.uid}/${selectedDevice}`,
                headers: {}
            };

            axios(config)
                .then((response) => {
                    let data = response.data;
                    if (data.length > 0) {
                        setWaGroups(data);
                    }

                    setPhoneGroupsInProgress(false);
                    setProcessing(false);
                })
                .catch((error) => {
                    setPhoneGroupsInProgress(false);
                    setProcessing(false);
                    setError(error.response.data.message)

                });
        } else {
            setPhoneGroupsInProgress(false);
            setProcessing(false);
            setError("Select any connected device");

        }

    }

    const handleMyWAAllGroups = () => {
        // e.preventDefault();

        if (selectedDevice !== "") {
            setPhoneGroupsInProgress(true);
            setProcessing(true);

            let config = {
                method: 'get',
                url: `https://messagesapi.co.in/contact/getAllMyGroups/${userInfo.uid}/${selectedDevice}`,
                headers: {}
            };

            axios(config)
                .then((response) => {
                    let data = response.data;

                    console.log("data", data)
                    console.log("selectedGroupContacts", selectedGroupContacts)

                    data = data.filter(d => d.groupId !== selectedGroupContacts[0].waGroupId)

                    if (data.length > 0) {
                        setWaGroups(data);
                    }

                    setPhoneGroupsInProgress(false);
                    setProcessing(false);
                })
                .catch((error) => {
                    setPhoneGroupsInProgress(false);
                    setProcessing(false);
                    setError(error.response.data.message)
                });
        } else {
            setPhoneGroupsInProgress(false);
            setProcessing(false);
            setError("Select any connected device");

        }

    }

    const handleClone = async () => {

        setCloneProcessing(true);

        let groupName = groupNameRef.current && groupNameRef.current.value ? groupNameRef.current.value.trim() : ""

        if (groupName === "") {
            setError("Group name is required");
            setCloneProcessing(false);
        } else if (contacts.length === 0) {
            setError("Import contatcts");
            setCloneProcessing(false);
        } else {

            let waGroupId = ""

            let participants = []

            if (contacts.length === 1) {
                setCloneProcessing(false);
                setError("You are the only admin in this group.")
                return
            }

            let filterMembers = contacts.filter(fd => !fd.isAdmin)

            participants = filterMembers.map(d => d.waContact)

            if (participants.length > 0) {

                //ADD TO PHONE
                const response = await createGroup(
                    {
                        "id": userInfo.uid,
                        "deviceName": selectedDevice,
                        "groupName": groupName,
                        "participants": participants
                    }
                )

                if (response.status === "success") {
                    waGroupId = response.data.gid._serialized
                }

                if (selectedDevice !== "") {

                    try {

                        let formatedContacts = contacts.map(sc => {
                            return {
                                "formattedName": sc.formattedName,
                                "isWAContact": sc.isWAContact,
                                "phone": sc.phone,
                                "waGroupId": selectedGroupRef.current ? selectedGroupRef.current.value : waGroupId,
                                "waContact": sc.waContact
                            }
                        })

                        //ADD TO DATABASE
                        await addGroup({
                            variables: {
                                "group": {
                                    "groupName": groupName,
                                    "userId": Number(userProfile.id),
                                    "contacts": formatedContacts,
                                    "groupAdmins": [
                                        {
                                            adminId: `${devices[selectedDeviceIndex].phoneNumber}@c.us`,
                                            adminName: userProfile.name
                                        }
                                    ]
                                }
                            }
                        });

                        setTransferProcessing(false); // Stop the loading state
                        setMessage("Successfully created!"); // Set success message
                        setCloneProcessing(false);

                    } catch (error) {
                        setTransferProcessing(false); // Stop the loading state even in case of an error
                        setError("Error occurred during transfer. Please try again."); // Set error message
                        setCloneProcessing(false);
                    }
                } else {
                    setError("Select a connected device");
                    setTransferProcessing(false);
                    setCloneProcessing(false);
                }
            } else {
                setError("Participants of group is not selected");
                setTransferProcessing(false);
                setCloneProcessing(false);
            }

        }
    }

    const handleSubmit = async () => {

        setProcessing(true);

        let groupName = groupNameRef.current && groupNameRef.current.value ? groupNameRef.current.value.trim() : ""

        if (groupName === "") {
            setError("Group name is required");
            setProcessing(false);
        } else if (contacts.length === 0) {
            setError("Import contatcts");
            setProcessing(false);
        } else {

            if (selectedDevice !== "") {
                try {

                    let formatedContacts = contacts.map(sc => {
                        return {
                            "formattedName": sc.formattedName,
                            "isWAContact": sc.isWAContact,
                            "phone": sc.phone,
                            "waGroupId": selectedGroupRef.current.value,
                            "waContact": sc.waContact
                        }
                    })

                    //ADD TO DATABASE
                    await addGroup({
                        variables: {
                            "group": {
                                "groupName": groupName,
                                "userId": Number(userProfile.id),
                                "contacts": formatedContacts,
                                "groupAdmins": groupAdmin
                            }
                        }
                    });

                    setTransferProcessing(false);
                    setMessage("Successfully created!");
                    setProcessing(false);

                } catch (error) {
                    console.log("error", error)
                    setTransferProcessing(false);
                    setError(error.message);
                    setProcessing(false);
                }
            } else {
                setError("Select a connected device");
                setTransferProcessing(false);
                setProcessing(false);

            }
        }
    }

    const handleExcelFile = async (e) => {
        const map = {
            Name: "Name",
            Phone: "Phone",
            Message: "Message",
        };

        let file = phonesFile.current.files[0];
        let contacts = [];

        await readXlsxFile(file, { map }).then(({ rows }) => {

            rows.forEach((row) => {
                contacts.push({
                    formattedName: row.Name.toString().trim(),
                    isWAContact: true,
                    phone: row.Phone.toString().trim(),
                    waContact: row.Phone.toString().trim() + "@c.us"
                }
                );

            });
        });

        setContacts(contacts);

    };

    const handleContacts = async (group) => {
        setExportProcessing(true);

        if (group && group.id) {
            await getContacts({
                variables: {
                    "groupId": Number(group.id)
                }
            });
        }

        setSelectedGroupAdmins(group.groupAdmins)

        setExportProcessing(false);
    }

    const handleMobileDelete = async (group) => {

        setProcessing(true)

        let deviceName = selectedDevice;
        let userId = userInfo.uid;
        let id = group.id;
        let groupId = group.contacts[0].waGroupId

        if (selectedDevice !== "") {

            if (
                groupId
            ) {
                //Delete from Database
                await deleteGroup({ variables: { groupId: id } })

                if (userInfo.uid) {

                    //Delete from Phone
                    let response = await deletePhoneGroup({ userId, deviceName, groupId, user: selectedPhoneNumber })
                    console.log("794 - response", response)

                } else {
                    setError("User Info is missing")
                    setProcessing(false)
                }
            } else {
                setError("Group ID is missing")
                setProcessing(false)
            }
        } else {
            setError("Select any connected device");
            setProcessing(false);
        }
    }

    const handleDelete = async (group) => {

        setProcessing(true);

        let id = group.id;
        let groupId = group.contacts[0].waGroupId

        if (selectedDevice !== "") {

            if (
                groupId
            ) {

                await deleteGroup({ variables: { groupId: id } })
                setProcessing(false)

            } else {
                setError("Group ID is missing")
                setProcessing(false)
            }
        } else {
            setError("Select any connected device");
            setProcessing(false);
        }
    }

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => selectedGroupContacts.find((row) => row.id === id));
        setSelectedContacts(selectedRowsData);
    };

    const handleDeleteSelectedContacts = async (e) => {
        e.preventDefault();

        setDeleteProcessing(true);
        let allDeletePromises = [];

        if (selectedDevice !== "") {

            selectedContacts.forEach((sc) => {

                //DELETE FROM DATABASE
                allDeletePromises.push(new Promise(async () => {
                    await deleteContact(
                        {
                            variables: { "contactId": sc.id }
                        }
                    )
                }))

                //DELETE FROM PHONE
                allDeletePromises.push(new Promise(async () => {
                    await deleteGroupParticipant(
                        {
                            "id": userInfo.uid,
                            "deviceName": selectedDevice,
                            "groupId": sc.waGroupId,
                            "participantNumber": sc.phone,
                        }
                    )
                }))
            });

            await Promise.all(allDeletePromises.map(f => f));
        } else {
            setError("Select any connected device");
            setDeleteProcessing(false);
        }

    }

    const handleTransferContacts = async () => {

        let dbRecord = contactsByWaGroupID?.contactsByWaGroupID

        setTransferProcessing(true); // Set loading state to true
        setMessage(""); // Clear any previous success message
        setError(""); // Clear any previous error message

        let allTransferPromises = [];

        if (selectedDevice !== "") {

            console.log("selectedContacts", selectedContacts)

            selectedContacts.forEach((sc) => {
                // Push a proper Promise that resolves after the async operation
                allTransferPromises.push(new Promise(async (resolve, reject) => {
                    try {
                        //ADD TO DATABASE
                        await createContact({
                            variables: {
                                "contact": {
                                    groupId: parseInt(dbRecord.groupId),
                                    formattedName: sc.formattedName,
                                    phone: sc.phone.toString(),
                                    isWAContact: true,
                                    waGroupId: dbRecord.waGroupId,
                                    waContact: `${sc.phone}@c.us`,
                                }
                            }
                        })

                        //ADD TO PHONE CONTACT
                        await addGroupParticipant({
                            "id": userInfo.uid,
                            "deviceName": selectedDevice,
                            "groupId": selectedGroupRef.current.value,
                            "participantNumber": sc.phone,
                        });

                        resolve(); // Resolve the promise after successful transfer
                    } catch (error) {
                        reject(error); // Reject the promise in case of failure
                    }
                }));
            });

            try {
                // Wait for all promises to resolve
                await Promise.all(allTransferPromises);

                setTransferProcessing(false); // Stop the loading state
                setMessage("Successfully transferred!"); // Set success message

            } catch (error) {
                setTransferProcessing(false); // Stop the loading state even in case of an error
                setError("Error occurred during transfer. Please try again."); // Set error message
            }
        } else {
            setError("Select a connected device"); // Set error if no device is selected
            setTransferProcessing(false); // Stop the loading state
        }
    };

    const handleCancel = () => {
        document.getElementById("group").reset();
        // setSelectedDeviceIndex("")
        // setSelectedDevice("");
        // setSelectedPhoneNumber("");
        setWaGroups([]);
        // setAllGroups([]);
        setContacts([]);
        setUploadFileVisible(false);
        // setSelectedGroupContacts([]);
        setShowGroupContacts(!showGroupContacts);
    }

    const handleSelectedGroupContact = (group) => {
        selectedGroupContactRef.current = group
        handleContacts(group);
        setWaGroups([])
        setShowGroupContacts(!showGroupContacts);
    }

    const createNewGroup = async (groupName) => {
        setTransferProcessing(true);
        setMessage("");
        setError("");

        let waGroupId = ""
        let participants = selectedContacts.map(d => d.waContact)

        //ADD TO PHONE
        const response = await createGroup(
            {
                "id": userInfo.uid,
                "deviceName": selectedDevice,
                "groupName": groupName,
                "participants": participants
            }
        )

        if (response.status === "success") {
            waGroupId = response.message.gid._serialized
        }

        if (selectedDevice !== "") {

            try {

                let contacts = selectedContacts.map(sc => {
                    return {
                        "formattedName": sc.formattedName,
                        "isWAContact": sc.isWAContact,
                        "phone": sc.phone,
                        "waGroupId": waGroupId,
                        "waContact": sc.waContact
                    }
                })

                //ADD TO DATABASE
                await addGroup({
                    variables: {
                        "group": {
                            "groupName": groupName,
                            "userId": Number(userProfile.id),
                            "contacts": contacts,
                            "groupAdmins": groupAdmin
                        }
                    }
                });

                setTransferProcessing(false); // Stop the loading state
                setMessage("Successfully created!"); // Set success message

            } catch (error) {
                setTransferProcessing(false); // Stop the loading state even in case of an error
                setError("Error occurred during transfer. Please try again."); // Set error message
            }
        } else {
            setError("Select a connected device"); // Set error if no device is selected
            setTransferProcessing(false); // Stop the loading state

        }
    }

    const mutateRow = useFakeMutation();

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = useCallback(
        async (newRow) => {

            // Make the HTTP request to save in the backend
            const response = await mutateRow(newRow);

            let {
                id,
                groupId,
                formattedName,
                phone,
                waGroupId,
                isWAContact = true,
                isNew
            } = response;

            if (isNew) {

                if (selectedConnectedDeviceRef.current !== "") {

                    //CREATE TO DATABASE
                    await createContact({
                        variables: {
                            "contact": {
                                groupId: parseInt(groupId),
                                formattedName,
                                phone: phone.toString(),
                                isWAContact: true,
                                waGroupId,
                                waContact: `${phone}@c.us`,
                            }
                        }
                    })

                    //ADD TO PHONE
                    await addGroupParticipant(
                        {
                            "id": userInfo.uid,
                            "deviceName": selectedConnectedDeviceRef.current,
                            "groupId": waGroupId,
                            "participantNumber": phone,
                        }
                    )

                } else {
                    setError("Select any connected device");
                }

                setSnackbar({ children: 'Record added successfully', severity: 'success' });
                return response;

            } else {

                updateContact({
                    variables: {
                        "contactId": id,
                        "contact": {
                            groupId: parseInt(groupId),
                            formattedName,
                            phone,
                            isWAContact,
                            waGroupId,
                            waContact: `${phone}@c.us`,
                        }
                    }
                });

                setSnackbar({ children: 'Record successfully saved', severity: 'success' });
                return response;
            }

        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    //table
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (rowData) => async () => {

        setDeleteProcessing(true);

        if (selectedDevice !== "") {

            let {
                id,
                phone,
                waGroupId
            } = rowData.row

            //DELETE FROM LOCAL DATABASE
            await deleteContact(
                {
                    variables: { "contactId": id }
                }
            )

            //DELETE FROM PHONE
            await deleteGroupParticipant(
                {
                    "id": userInfo.uid,
                    "deviceName": selectedDevice,
                    "groupId": waGroupId,
                    "participantNumber": phone,
                }
            )

            setSelectedGroupContacts(selectedGroupContacts.filter((row) => row.id !== id));

        } else {
            setError("Select any connected device");
            setDeleteProcessing(false);
            setSelectedGroupContacts(selectedGroupContacts);
        }

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = selectedGroupContacts.find((row) => row.id === id);

        if (editedRow.isNew) {
            setSelectedGroupContacts(selectedGroupContacts.filter((row) => row.id !== id));
        }
    };

    const fetchContactByWaGroupID = async (selectedGroup) => {

        await getContactByWaGroupID({
            variables: {
                "waGroupId": selectedGroup
            }
        });
    }

    // Function to handle the selection change
    const handleDeviceChange = (event) => {

        if (event.target.value) {

            setSelectedDeviceIndex(event.target.value)
            setSelectedDevice(devices[event.target.value].deviceName);
            setSelectedPhoneNumber(devices[event.target.value].phoneNumber);
            selectedConnectedDeviceRef.current = devices[event.target.value].deviceName

            groups(
                {
                    variables: {
                        "userId": Number(userProfile.id)
                    }
                }
            )

        } else {
            setSelectedDeviceIndex("")
            setSelectedDevice("");
            setSelectedPhoneNumber("");
        }
    };

    const isAdminGroup = (group) => {

        if (selectedPhoneNumber) {
            const groupAdmin = group.groupAdmins.some(gd => gd.adminId.includes(selectedPhoneNumber))
            return groupAdmin
        }
    }

    function EditToolbar(props) {

        const { setSelectedGroupContacts, setRowModesModel } = props;

        const handleClick = () => {
            let id = 0;
            let ids = [];

            // get largest number
            ids = selectedGroupContacts.map(d => Number(d.id))
            ids = ids.sort()
            id = ids[ids.length - 1] + 1

            setSelectedGroupContacts((oldRows) => [
                ...oldRows,
                { id, groupId: selectedGroupContacts[0].groupId, formattedName: '', phone: '', waContact: '', waGroupId: selectedGroupContacts[0].waGroupId, isNew: true },
            ]);

            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'formattedName' },
            }));
        };

        return (
            <GridToolbarContainer className='flex justify-end items-center'>
                <div >
                    {/* <div className='grid grid-cols-2 gap-2 justify-center items-center'>
                        <label htmlFor="connectedDevice" className='text-xs text-gray-600 font-semibold'>SELECT DEVICE : </label>
                        <select
                            name="connectedDevice"
                            id="connectedDevice"
                            ref={connectedDeviceRef}
                            value={selectedDeviceIndex}
                            onChange={handleDeviceChange}
                        >
                            <option value="">Select Connected Device</option>
                            {
                                devices.map((device, index) => {
                                    return (
                                        <option value={index} key={index}>{device.deviceName}</option>
                                    )
                                })
                            }
                        </select>
                    </div> */}
                    <div className='flex-1 w-full py-4'>
                        <Tooltip title="Add Participant Online / Offline">
                            <span>
                                <button
                                    className='w-[150px] text-xs bg-blue-500 text-white rounded-full px-1 py-2 disabled:bg-blue-300'
                                    onClick={handleClick}
                                    disabled={!addManuallyStatus}
                                >
                                    Add Participant
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    const handleSelectGroup = async (event) => {

        let selectedGroupName = waGroups.filter(gpn => gpn.groupId === event.target.value)

        const selectedName = waGroups.find(gs => gs.groupId === event.target.value)

        setSelectedGroup(event.target.value)

        if (groupNameRef.current) groupNameRef.current.value = selectedName.name

        if (
            selectedGroupName[0] &&
            selectedGroupName[0].admins
        ) {

            let groupAdmins = selectedGroupName[0].admins.map(ga => {
                return {
                    adminId: ga.id,
                    adminName: ga.name
                }
            })

            setGroupAdmin(groupAdmins)
        }

        // groupNameRef.current.value = selectedGroupName[0].name
        await fetchContactByWaGroupID(event.target.value)
    }

    useEffect(() => {
        let devicesByUID = null;
        if (deviceData) {
            devicesByUID = deviceData.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [deviceData, loading]);

    useEffect(() => {
        if (
            userInfo
        ) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo])

    useEffect(() => {
        let userProfile = null;
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        let groups = [];
        if (groupsData) {
            groups = groupsData.groupsByID
            setAllGroups(groups)
            setShowGroupContacts(false);
        }
    }, [groupsData])

    useEffect(() => {

        let gcontacts = [];

        if (contactsData && contactsData.contactsByGroupID.length > 0) {
            gcontacts = contactsData.contactsByGroupID
            setSelectedGroupContacts(gcontacts);
        }

        setShowGroupContacts(true);
        setDeleteProcessing(false);

    }, [contactsData])

    useEffect(() => {
        if (
            error ||
            message ||
            successGroup ||
            errorGroup

        ) {
            setTimeout(() => {
                document.getElementById("group").reset()
                setSelectedDeviceIndex("")
                setSelectedDevice("")
                setSelectedPhoneNumber("")
                setSelectedGroup("")
                setWaGroups([])
                setContacts([])
                setUploadFileVisible(false)
                setMessage("")
                setError("")
                // window.location.reload();
            }, 2500)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, message, successGroup, errorGroup, nameNotFound])

    useEffect(() => {
        if (
            devices &&
            devices.length > 0 &&
            selectedDeviceIndex &&
            selectedGroupAdmins
        ) {

            let addManuallyStatus = selectedGroupAdmins.some(sga => sga.adminId === `${devices[selectedDeviceIndex].phoneNumber}@c.us`);

            setAddManuallyStatus(addManuallyStatus)
        } else {
            setAddManuallyStatus(false)
        }
    }, [devices, selectedDeviceIndex, selectedGroupAdmins])

    console.log("devices", devices)

    return (
        <>
            <form id='group'>
                <div className='grid grid-cols-1 gap-2 w-[60%] p-4'>

                    <div className='flex  flex-col gap-2 justify-center items-center'>
                        {

                            error !== "" ? <Alerts alertType="Error">{error}</Alerts> :
                                errorGroup !== "" ? <Alerts alertType="Error">{errorGroup}</Alerts> :
                                    nameNotFound !== "" ? <Alerts alertType="Error">{nameNotFound}</Alerts> : ""
                        }
                        {

                            message !== "" ? <Alerts alertType="Success">{message}</Alerts> :
                                successGroup !== "" ? <Alerts alertType="Success">{successGroup}</Alerts> : ""

                        }

                    </div>

                    <div>
                        {
                            allGroups &&
                            allGroups.length > 0 &&
                            !showGroupContacts &&
                            (
                                <div>
                                    <p className='text-center text-gray-600 mt-4 text-blackfont-semibold font-serif text-md tracking-widest px-4 py-2 space-y-2 '>SAVED GROUPS</p>
                                    <ul className='h-[300px] overflow-y-scroll m-auto'>
                                        {
                                            allGroups.map((group, index) => {
                                                return (
                                                    <li className={`flex justify-between items-center bg-lime-500 px-4 py-1 rounded-xl mt-2 text-white`} key={group.id}>
                                                        {
                                                            exportProcessing ?
                                                                (
                                                                    <ThemeProvider theme={theme}>
                                                                        <CircularProgress size={24} color="primary" />
                                                                    </ThemeProvider>
                                                                ) : (
                                                                    <ContactPhone />
                                                                )
                                                        }

                                                        <p>{group.groupName}</p>
                                                        <div className='flex justify-center items-center gap-2 cursor-pointer'>
                                                            <Tooltip title="View Group Participants">
                                                                <button onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleSelectedGroupContact(group);
                                                                }}>
                                                                    <View />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Group Offline">
                                                                <button onClick={async (e) => {
                                                                    e.preventDefault();
                                                                    await handleDelete(group);
                                                                }}>
                                                                    <Trash />
                                                                </button>
                                                            </Tooltip>
                                                            {
                                                                isAdminGroup(group) && (
                                                                    <Tooltip title="Delete Group Online / Offline">
                                                                        <button onClick={async (e) => {
                                                                            e.preventDefault();
                                                                            await handleMobileDelete(group);
                                                                        }}>
                                                                            <MobileOff />
                                                                        </button>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        }
                        {/* {
                        selectedGroupContacts.length === 0 &&
                        (
                            <div>
                                <p className='text-green-400 font-serif text-sm mt-6'>
                                    Note:- Click above to select anyone group.
                                </p>
                            </div>
                        )
                    } */}
                    </div>

                    {
                        selectedGroupContacts &&
                        selectedGroupContacts.length > 0 &&
                        showGroupContacts &&
                        (
                            <div className='bg-white p-4'>
                                <h1 className='text-center text-sm font-serif font-medium tracking-widest my-4 text-gray-600'>GROUP CONTACTS</h1>
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        '& .actions': {
                                            color: 'text.secondary',
                                        },
                                        '& .textPrimary': {
                                            color: 'text.primary',
                                        },
                                    }}>
                                    <DataGrid
                                        rows={selectedGroupContacts}
                                        columns={columns}
                                        pageSize={100}
                                        editMode="row"
                                        rowModesModel={rowModesModel}
                                        rowsPerPageOptions={[5]}
                                        checkboxSelection
                                        onRowEditStop={handleRowEditStop}
                                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                        processRowUpdate={processRowUpdate}
                                        onProcessRowUpdateError={handleProcessRowUpdateError}
                                        experimentalFeatures={{ newEditingApi: true }}
                                        columnVisibilityModel={{
                                            groupId: false,
                                        }}
                                        components={{
                                            Toolbar: EditToolbar, // Use components or slots depending on your MUI version
                                        }}
                                        componentsProps={{
                                            toolbar: { setSelectedGroupContacts, setRowModesModel },
                                        }}
                                    />
                                    {!!snackbar && (
                                        <Snackbar
                                            open
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            onClose={handleCloseSnackbar}
                                            autoHideDuration={5000}
                                        >
                                            <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                        </Snackbar>
                                    )}
                                </Box>
                                {

                                    migration &&
                                    waGroups.length > 0 &&
                                    (
                                        <div className='grid grid-cols-2 gap-2 my-4 items-center'>
                                            <div className='text-sm text-gray-400 font-semibold'>ALL WA GROUPS : </div>
                                            <div>
                                                <div className='my-2'>
                                                    <select
                                                        name="selectedGroup"
                                                        id="selectedGroup"
                                                        ref={selectedGroupRef}
                                                        onChange={handleSelectGroup}
                                                    >
                                                        <option value="">Select anyone WA Group</option>
                                                        {
                                                            waGroups.map(group => {
                                                                return <option value={`${group.groupId}`} key={`${group.groupId}`}>{group.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    )

                                }

                                <div>
                                    {
                                        processing && (
                                            <p className='font-sans text-lg text-orange-500'>Please wait...</p>
                                        )
                                    }
                                </div>
                                {
                                    migration && (
                                        <div className='grid grid-cols-1 p-2'>
                                            <GroupSelector handleWAAllGroups={handleMyWAAllGroups} setWaGroups={setWaGroups} createNewGroup={createNewGroup} />
                                        </div>
                                    )
                                }

                                <div className='flex justify-end my-2 space-x-2 w-full'>
                                    {
                                        !deleteProcessing ? (
                                            <button type='button' className='px-2 py-1 text-xs bg-red-400 text-white rounded-lg' onClick={handleDeleteSelectedContacts}>Delete</button>
                                        ) : (
                                            <button type='button' className='px-2 py-1 text-xs bg-red-400 text-white rounded-lg' disabled>Wait...</button>
                                        )
                                    }

                                    <button type='button' className='px-2 py-1 text-xs bg-yellow-400 text-white rounded-lg' onClick={handleCancel}>Cancel</button>

                                    {
                                        !migration && (
                                            <button type='button' className='px-2 py-1 text-xs bg-orange-300 text-white rounded-lg' disabled={selectedDevice === ""} onClick={() => setMigration(!migration)}>
                                                {
                                                    selectedDevice === "" ? <span>Select device</span> : <span> Migrate</span>
                                                }
                                            </button>
                                        )
                                    }

                                    {
                                        waGroups &&
                                        waGroups.length > 0 &&
                                        selectedDevice !== "" &&
                                        selectedContacts &&
                                        selectedContacts.length > 0 &&
                                        selectedGroup !== "Select anyone WA Group" &&
                                        (
                                            <button type='button' className='flex justify-center items-center text-xs px-2 py-1 bg-pink-400 text-white rounded-lg space-x-2' onClick={handleTransferContacts}>
                                                {
                                                    transferProcessing ?
                                                        (
                                                            <ThemeProvider theme={theme}>
                                                                <CircularProgress size={24} color="primary" />
                                                            </ThemeProvider>
                                                        ) : (
                                                            <CopyPlus />
                                                        )
                                                }

                                                <span>Transfer Contacts</span>

                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }

                    <div>
                        {
                            !showGroupContacts &&
                            waGroups &&
                            waGroups.length > 0 && (
                                <div>
                                    <p className="text-gray-600 flex justify-center items-center py-4 text-md font-serif">SAVE NEW GROUPS FROM WA</p>
                                </div>)
                        }

                        <div>

                            {
                                uploadFileVisible && (
                                    <div className='flex justify-start items-center my-4'>
                                        <p className="inline-block text-xs font-bold uppercase leading-8 tracking-wider text-black">
                                            Download Sample Excel File
                                        </p>
                                        <a
                                            href="./assets/samples.xlsx"
                                            className="flex justify-center items-center text-md ml-4 h-8 w-8 p-2 bg-white font-semibold leading-8 text-blue-400 rounded-full"
                                        >
                                            <Download />
                                        </a>
                                    </div>
                                )
                            }

                            {
                                uploadFileVisible &&
                                (
                                    <div className=''>
                                        <input
                                            type="file"
                                            id="input"
                                            ref={phonesFile}
                                            onChange={handleExcelFile}
                                            accept=".xlsx"
                                            className="my-2 text-right"
                                        />
                                    </div>
                                )
                            }

                            {

                                <div className='my-2'>
                                    <label htmlFor="connectedDevice" className='sm text-gray-600 py-2'>SELECT DEVICE : </label>
                                    <select
                                        name="connectedDevice"
                                        id="connectedDevice"
                                        ref={connectedDeviceRef}
                                        value={selectedDeviceIndex}
                                        onChange={handleDeviceChange}>
                                        <option value="">Select Connected Device</option>
                                        {
                                            devices &&
                                            devices.length > 0 &&
                                            devices.map((device, index) => {
                                                return (
                                                    <option value={index} key={index}>{device.deviceName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                            }

                            {
                                !migration &&
                                waGroups &&
                                waGroups.length > 0 && (

                                    <div className='my-2'>
                                        <select
                                            name="selectedGroup"
                                            id="selectedGroup"
                                            ref={selectedGroupRef}
                                            value={selectedGroup}
                                            onChange={handleSelectGroup}
                                        >
                                            <option value="">Select anyone WA Group</option>
                                            {

                                                waGroups.map(group => {
                                                    return <option value={`${group.groupId}`} key={`${group.groupId}`}>{group.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                )


                            }

                            {
                                (
                                    !migration && (
                                        waGroups.length > 0 ||
                                        contacts.length > 0 ||
                                        uploadFileVisible)

                                ) &&
                                (
                                    <div className='flex justify-start items-center' >
                                        <div className='flex-1'>
                                            <input type="text" name="groupName" id="groupName" ref={groupNameRef} required placeholder="Give a name to your import group" />
                                        </div>
                                    </div>
                                )
                            }

                            {
                                (
                                    !contacts.length > 0 &&
                                    !waGroups.length > 0 &&
                                    !uploadFileVisible
                                ) && (<div className='text-sm text-black uppercase flex justify-center w-full'></div>)
                            }

                            {
                                selectedDeviceIndex >= 0 &&
                                !migration && (
                                    <div className='flex justify-center items-center space-x-2'>
                                        {

                                            waGroups.length === 0 &&
                                                !uploadFileVisible &&
                                                contacts.length === 0 &&
                                                <div className='my-2'>
                                                    {/* <select
                                                        name="connectedDevice"
                                                        id="connectedDevice"
                                                        ref={connectedDeviceRef}
                                                        value={selectedDeviceIndex}
                                                        onChange={handleDeviceChange}>
                                                        <option value="">Select Connected Device</option>
                                                        {
                                                            devices &&
                                                            devices.length > 0 &&
                                                            devices.map((device, index) => {
                                                                return (
                                                                    <option value={index} key={index}>{device.deviceName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> */}
                                                </div>
                                                ? (
                                                    <button
                                                        className='flex justify-center items-center space-x-2 px-2 py-1 bg-violet-500 disabled:bg-violet-300 text-white mt-2 rounded-full text-xs w-[200px]'
                                                        onClick={handleWAAllGroups}
                                                        disabled={selectedDevice !== "" ? false : true}
                                                    >
                                                        {
                                                            phoneGroupsInProgress ?
                                                                (
                                                                    <ThemeProvider theme={theme}>
                                                                        <CircularProgress size={24} color="primary" />
                                                                    </ThemeProvider>
                                                                ) : (
                                                                    <HardDriveDownload />
                                                                )
                                                        }

                                                        {
                                                            selectedDevice !== "" ? <span>View all WA groups</span> : <span>Select device</span>
                                                        }
                                                    </button>
                                                ) : (
                                                    !migration &&
                                                    waGroups.length > 0 &&
                                                    contacts.length === 0 &&
                                                    (
                                                        <>
                                                            <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-lime-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={handleAllGroupsMember}>
                                                                <HardDriveDownload />
                                                                <span>Import</span>
                                                            </button>
                                                            <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-red-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={() => {
                                                                window.location.reload();
                                                            }}>
                                                                <CancelIcon />
                                                                <span>Cancel</span>
                                                            </button>
                                                        </>
                                                    )
                                                )
                                        }

                                        {

                                            contacts.length === 0 &&
                                            waGroups.length === 0 &&
                                            (
                                                <>
                                                    <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-blue-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={() => setUploadFileVisible(!uploadFileVisible)}>
                                                        <HardDriveDownload />
                                                        <span>Excel</span>
                                                    </button>
                                                    {
                                                        uploadFileVisible && (
                                                            <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-red-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={() => setUploadFileVisible(!uploadFileVisible)}>
                                                                <CancelIcon />
                                                                <span>Cancel</span>
                                                            </button>
                                                        )
                                                    }

                                                </>

                                            )
                                        }

                                        {
                                            !migration &&
                                            contacts.length > 0 &&
                                            (
                                                <div className='flex gap-2 justify-center items-center'>
                                                    <Tooltip title="Create Group Offline">
                                                        <button type='button' className='flex justify-center items-center space-x-2 px-2 py-1 bg-orange-400 text-white mt-2 rounded-full text-xs w-[150px]' disabled={processing || cloneProcessing} onClick={handleSubmit}>

                                                            {
                                                                processing ?
                                                                    (
                                                                        <ThemeProvider theme={theme}>
                                                                            <CircularProgress size={24} color="primary" />
                                                                        </ThemeProvider>
                                                                    ) : (
                                                                        <HardDriveDownload />
                                                                    )
                                                            }

                                                            <span className='mx-1 '>Save</span>

                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Create Group Online / Offline">
                                                        <button type='button' className='flex justify-center items-center space-x-2 px-2 py-1 bg-purple-400 text-white mt-2 rounded-full text-xs w-[150px]' disabled={processing || cloneProcessing} onClick={handleClone}>

                                                            {
                                                                cloneProcessing ?
                                                                    (
                                                                        <ThemeProvider theme={theme}>
                                                                            <CircularProgress size={24} color="primary" />
                                                                        </ThemeProvider>
                                                                    ) : (
                                                                        <ControlPointDuplicate />
                                                                    )
                                                            }

                                                            <span className='mx-1 '>Clone</span>

                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            }

                        </div>

                    </div>
                </div>
            </form >
        </>
    )
}

export default Group