import React, { useState, useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import DataTable from "../../components/DataTable";
import Container from "../../components/Container";

const GET_SCHEDULE_REPORT = gql`
    query getScheduleReports($uid:String){
        scheduleReportsByUID(uid: $uid) {
            id,
            uid,
            deviceName,
            message,
            dateTime,
            isCompleted,
            status,
            phones,
            fileURL
        }
    }
`;

const columns = [
    { field: "dateTime", headerName: "Date Time", minWidth: 250, align: "left" },
    { field: "message", headerName: "Message", minWidth: 200, align: "left" },
    { field: "deviceName", headerName: "Device Name", minWidth: 130, align: "left" },
    { field: "phones", headerName: "Phones", minWidth: 150, align: "center" },
    { field: "status", headerName: "Status", minWidth: 100, align: "left" },
    {
        field: "fileURL",
        headerName: "Attachment",
        minWidth: 200,
        align: "left",
        format: (value) => value.toLocaleString("en-IN"),
    },
    {
        field: "isCompleted",
        headerName: "Completed",
        minWidth: 100,
        align: "right",
        format: (value) => value.toLocaleString("en-IN"),
    },
];

export default function Schedule() {
    const [data, setData] = useState(null);

    const [error, setError] = useState("");

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const uid = userInfo.uid;

    const {
        data: scheduleReportsData,
        loading: scheduleReportsLoading,
    } = useQuery(GET_SCHEDULE_REPORT, {
        variables: { uid: uid },
    });

    useEffect(() => {

        if (
            scheduleReportsData
        ) {

            let data = [...scheduleReportsData.scheduleReportsByUID];

            data = data.sort(function (a, b) {
                return new Date(b.dateTime) - new Date(a.dateTime);
            }).map(user => {
                return {
                    id: moment(new Date(Number(user.dateTime)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                    ...user, dateTime: moment(new Date(Number(user.dateTime)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                }
            });

            setData(data);
        } else {
            setData([]);

        }
    }, [scheduleReportsData, scheduleReportsLoading]);

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    return (
        <Container>
            <div>
                <p className="text-gray-600 flex justify-center items-center py-4 text-xl">SCHEDULE DELIVERY REPORTS</p>
            </div>
            <div className="grid grid-cols-12 gap-2  p-4 pt-0 h-screen overflow-y-scroll no-scrollbar">
                <div className="col-span-12">
                    {!scheduleReportsLoading && data && (
                        <div className="mb-4">

                            {data.length >= 0 ? (
                                <DataTable columns={columns} data={data} />
                            ) : (
                                <p className="text-xl font-semibold text-red-500 ">
                                    no records found
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
}
