import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Emojis from "../../util/Emojis";
import { gql, useQuery } from "@apollo/client";
import { ImportContacts } from "@mui/icons-material";
import Container from "../../components/Container";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import GroupList from "../group/GroupList";
import { Link } from "react-router-dom";
import Alerts from "../../components/Alerts";
import CronStringGenerator from "../../util/CronStringGenerator";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const GROUP_GET = gql`
    query ($userId: Int!) {  
        groupsByID(userId: $userId) {    
            id    
            groupName    
            contacts {waGroupId}  
        }
    }
`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

const GET_TEMPLATES = gql`
    query($uid: String!){
        templateByUID(uid:$uid) {
            id,
            templateName,
            body
        }
    }
`

function BulkMessage() {
    const [devices, setDevices] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };


    const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    const [userProfileInfo, setUserProfileInfo] = useState({})
    const [message, setMessage] = useState({ messageType: "template" });
    const [success, setSuccess] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [deviceName, setDeviceName] = useState("")
    const [templates, setTemplates] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [isScheduleProcess, setIsScheduleProcess] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [templateSelected, setTemplateSelected] = useState(false);
    const selectedDeviceRef = useRef([]);

    const cursorRef = useRef("");
    const quillRef = useRef(null);
    const selectedTemplateRef = useRef("");

    const [isClicked, setIsClicked] = useState(false);

    const [receivers, setReceivers] = useState([])
    const [receiversPhone, setReceiversPhone] = useState([])

    const phoneRef = useRef("");
    const messageRef = useRef("");
    const dateTimeRef = useRef({});
    const fileRef = useRef("");

    //SET Remain Msg to be send
    const [totalMessage, setTotalMessage] = useState(0);
    const [sentMessage, setSentMessage] = useState(0);
    const [groupVisible, setGroupVisible] = useState(false);

    //INTERNET CHECKER START
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    //GRAPHQL DEVICE QUERY START
    const { refetch, data, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
        variables: {
            uid: userInfo.uid
        }
    });

    const { data: groupsData } = useQuery(GROUP_GET, {
        variables: { "userId": Number(userInfo.id) },
    });

    const [allGroups, setAllGroups] = useState([]);

    useEffect(() => {

        if (templateData) {
            let data = templateData.templateByUID;

            setTemplates(data);
        }
    }, [templateData, templateLoading]);

    useEffect(() => {
        let groups = [];
        if (groupsData) {
            groups = groupsData.groupsByID
            setAllGroups(groups)
        }
    }, [groupsData])

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    function formatWhatsAppMessage(message) {
        // Remove all HTML entities (e.g., &nbsp;, &amp;, etc.)
        message = message.replace(/&[a-zA-Z0-9#]+;/g, " ");

        // Remove all placeholder words from the string
        message = message.replace(/\{\{[^}]+\}\}/g, "").replace(/\s{2,}/g, " ").trim();

        // Replace <em> tags with WhatsApp italic formatting
        message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

        // Replace <strong> tags with WhatsApp bold formatting, allowing optional spaces around the text
        message = message.replace(/<strong>\s*(.*?)\s*<\/strong>/g, '*$1*');

        // Replace <u> tags (underline) with emphasis (using _ for emphasis since underline isn't supported)
        message = message.replace(/<u>(.*?)<\/u>/g, '_$1_');

        // Ensure <p> tags add new lines
        message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
        message = message.replace(/<p>/g, ''); // Remove opening <p> tags

        // Convert ordered list <ol> into plain numbered list
        message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            let counter = 1;
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `${counter}. ${listItem}\n`; // Add numbering
                counter++;
            });
            return formattedList; // Return formatted list
        });

        // Convert unordered list <ul> into plain bullet list
        message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `• ${listItem}\n`; // Add bullet points
            });
            return formattedList; // Return formatted list
        });

        // Remove any other remaining HTML tags
        message = message.replace(/<[^>]*>/g, '');

        // Handle adjacent formatting symbols and ensure no spaces are lost
        message = message.replace(/_([\s\S]+?)_|\*([\s\S]+?)\*/g, function (match, italic, bold) {
            if (italic) return `_${italic}_`;
            if (bold) return `*${bold}*`;
            return match;
        });

        return message.trim(); // Trim any trailing spaces or newlines
    }

    const sendScheduleMessage = async () => {

        setIsScheduleProcess(true);

        let phones = [];
        let index = 0;
        let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

        if (
            phoneRef.current.value &&
            phoneRef.current.value.length > 0
        ) {
            if (message.message && message.message !== "") {

                if (isSelected) {
                    sendScheduleFileMessage()

                } else {

                    if (phoneRef.current.value.includes(",")) {

                        phones = phoneRef.current.value.split(",")

                        const sendMsg = async (phone) => {

                            let receiver = "";
                            receiver = receivers[index];

                            let data = {
                                id: userInfo.uid,
                                phones: phone,
                                message: formatWhatsAppMessage(message.message, receiver.formattedName),
                                dateTime: dateTimeRef.current.value,
                                cronString: CronStringGenerator(dateTimeRef.current.value),
                                uid: userInfo.uid,
                                deviceName: deviceName,
                                file: ""
                            };

                            let config = {
                                method: "POST",
                                url: apiURL,
                                headers: {
                                    "Content-type": "application/json",
                                },
                                data: data,
                            };

                            let response = await axios(config)

                            let msgResp = response.data.message;
                            let msgStatus = response.data.status;

                            if (msgStatus !== "error") {

                                setStatus(msgResp);

                            } else {

                                setError(msgResp);
                            }

                            ++index;

                            if (index < phones.length) {

                                sendMsg(phones[index])

                            }

                            return

                        }

                        sendMsg(phones[index]);
                        setSuccess("Schedule message created successfully!")
                        setIsScheduleProcess(false);
                    } else {

                        let receiver = "";
                        receiver = receivers[index];

                        let data = {
                            id: userInfo.uid,
                            phones: receiver.phone,
                            message: formatWhatsAppMessage(message.message, receiver.formattedName),
                            dateTime: dateTimeRef.current.value,
                            cronString: CronStringGenerator(dateTimeRef.current.value),
                            uid: userInfo.uid,
                            deviceName: deviceName,
                            file: ""
                        };

                        let config = {
                            method: "POST",
                            url: apiURL,
                            headers: {
                                "Content-type": "application/json",
                            },
                            data: data,
                        };

                        let response = await axios(config)

                        let msgResp = response.data.message;
                        let msgStatus = response.data.status;

                        if (msgStatus !== "error") {

                            setStatus(msgResp);

                        } else {

                            setError(msgResp);
                        }

                        setSuccess("Schedule message created successfully!")
                        setIsScheduleProcess(false);

                    }
                }
            }

        } else {

            setError("Phone is not valid");
            setIsScheduleProcess(false);

        }
    }

    const sendScheduleFileMessage = async () => {

        setIsScheduleProcess(false);

        let phones = [];
        let index = 0;
        const apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;

        if (
            phoneRef.current.value &&
            phoneRef.current.value.length > 0
        ) {
            if (
                message.message &&
                message.message !== ""
            ) {
                if (phoneRef.current.value.includes(",")) {

                    phones = phoneRef.current.value.split(",");

                    const sendMsg = async (phone) => {

                        let receiver = "";
                        receiver = receivers[index];
                        const formData = new FormData();

                        formData.append("id", userInfo.uid);
                        formData.append("uid", userInfo.uid);
                        formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
                        formData.append("deviceName", deviceName);
                        formData.append("file", selectedFile);
                        formData.append("message", formatWhatsAppMessage(message.message, receiver.formattedName));
                        formData.append("dateTime", dateTimeRef.current.value);
                        formData.append("phones", phone);

                        fetch(apiURL, {
                            method: "POST",
                            headers: {},
                            body: formData,
                        }, {
                            mode: 'no-cors'
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                let msgResp = result.message;
                                let msgStatus = result.status;

                                if (msgStatus !== "error") {

                                    setStatus(msgResp);
                                } else {

                                    setError(msgResp);
                                }

                                ++index;

                                if (index < phones.length) {

                                    sendMsg(phones[index])
                                }
                            })
                            .catch((error) => {

                                setError(error.response.data.message);
                            });
                    }

                    await sendMsg(phones[index]);
                    setSuccess("Schedule message created successfully!")
                    setIsScheduleProcess(false);

                } else {

                    let receiver = "";
                    receiver = receivers[0];
                    const formData = new FormData();

                    formData.append("id", userInfo.uid);
                    formData.append("uid", userInfo.uid);
                    formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
                    formData.append("deviceName", deviceName);
                    formData.append("file", selectedFile);
                    formData.append("message", formatWhatsAppMessage(message.message, receiver.formattedName));
                    formData.append("dateTime", dateTimeRef.current.value);
                    formData.append("phones", receiver.phone);

                    fetch(apiURL, {
                        method: "POST",
                        headers: {},
                        body: formData,
                    }, {
                        mode: 'no-cors'
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            let msgResp = result.message;
                            let msgStatus = result.status;

                            if (msgStatus !== "error") {

                                setStatus(msgResp);
                            } else {

                                setError(msgResp);
                            }
                        })
                        .catch((error) => {

                            setError(error.response.data.message);
                        });

                    setSuccess("Schedule message created successfully!")
                    setIsScheduleProcess(false);
                }

            } else {

                setError("Message is not valid");
                setIsScheduleProcess(false);

            }
        } else {

            setError("Phone is not valid");
            setIsScheduleProcess(false);

        }


    };

    //INTERNET CHECKER END
    const handleContacts = (contacts) => {
        let receiversPhone = contacts.map(contacts => contacts && contacts.phone)
        setReceivers(contacts);
        phoneRef.current.value = contacts;
        setReceiversPhone(receiversPhone);
        setGroupVisible(false);
        setTotalMessage(receiversPhone.length);
    }

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const sendFileMessage = async (phone, message) => {
        let response;

        try {
            const apiURL = `https://messagesapi.co.in/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;
            const formData = new FormData();

            formData.append("file", selectedFile);
            formData.append("message", formatWhatsAppMessage(message));
            formData.append("phone", phone.replace(/[^0-9]|\s+/g, "").trim());

            response = await fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            });

        } catch (error) {

            return error.message;
        }

        // Uses the 'optional chaining' operator
        if (response?.ok) {

            let result = await response.json();
            return result.message;
        } else {

            // return response?.status;
            return "Your device is offline. Please check Internet in your mobile phone or reconnect your device";
        }
    };

    const sendSimpleMessage = async (phone, message) => {

        let apiURL = "https://messagesapi.co.in/chat/sendMessage";

        let data = JSON.stringify({
            "id": userInfo.uid,
            "name": deviceName,
            "phone": phone.replace(/[^0-9]|\s+/g, "").trim(),
            "message": formatWhatsAppMessage(message)
        });

        var config = {
            method: "POST",
            url: apiURL,
            headers: {
                "Content-type": "application/json",
            },
            data: data,
        };

        try {
            let response = await axios(config);
            setStatus(response.data.message);
        } catch (error) {
            if (error.response) {
                // The client was given an error response (5xx, 4xx)
                setError(error.response.data.message);
            } else if (error.request) {
                // The client never received a response, and the request was never left
                setError(error.request);
            } else {
                // Anything else
                setError(error.message);
            }
        }

    }

    const sendMessage = () => {

        setIsClicked(true);

        let timesPromise = [];
        let j = 0;

        if (receiversPhone.length > 0) {

            let promiseLength = Math.round(receiversPhone.length / selectedDeviceRef.current.value.length) === 1 ? selectedDeviceRef.current.value.length : Math.round(receiversPhone.length / selectedDeviceRef.current.value.length);

            const prepareMessage = (callBack) => {

                timesPromise = [];
                j = 0;

                for (let round = 0; round <= promiseLength; round++) {

                    for (let i = 0; i < selectedDeviceRef.current.value.length; i++) {

                        let phone = "";
                        let receiver = "";
                        receiver = receivers[j++];

                        if (deviceName && receiver) {
                            phone = receiver.phone;
                        } else {
                            break;
                        }

                        if (
                            deviceName &&
                            phone
                        ) {

                            if (!timesPromise[round])

                                timesPromise[round] = [];

                            if (message.messageType === "template") {

                                timesPromise[round].push(() => callBack(phone, formatWhatsAppMessage(message.message, receiver.formattedName), deviceName));
                            } else {
                                timesPromise[round].push(() => callBack(phone, `${message.message}`, deviceName));
                            }

                        } else {
                            break;
                        }
                    }

                    //RESET IF RECEIVERS NUMBERS ARE REMAIN
                    if (j < receiversPhone.length) {
                        continue;
                    } else {
                        break;
                    }
                }
            }

            if (
                message.message &&
                message.message.trim() !== ""

            ) {

                if (isSelected) {

                    prepareMessage(sendFileMessage);
                } else {

                    prepareMessage(sendSimpleMessage);
                }

                //SELF EXECUTABLE CODE
                (function myLoop(i) {

                    let interval = 100;

                    setTimeout(async function () {

                        const response = await Promise.all(timesPromise[i].map(f => f()))

                        setSentMessage(prevData => {
                            return prevData + response.length;
                        });

                        if (--i >= 0) {
                            if (isOnline) myLoop(i);
                        } else {
                            setSuccess("All message sent successfully! ")
                            resetAll();
                        }

                    }, interval)
                })(timesPromise.length - 1);

            } else {

                setError("Message shouldn't be blank!");
                return
            }

        } else {
            setError("No Receivers are found!!")
        }

    };

    const handleSelectedDevice = (e) => {
        setDeviceName(e.target.value)
        setIsDeviceSelected(!isDeviceSelected)
    }

    const handleSelectTemplate = (e) => {
        if (e.target.value) {
            setMessage((prevData) => {
                return { ...prevData, "message": e.target.value };
            });

            setTemplateSelected(!templateSelected)

        } else {
            setMessage((prevData) => {
                return { ...prevData, "message": "" };
            });

            setTemplateSelected(!templateSelected)
        }
    }

    const handleQuillChange = (value) => {
        setMessage((prevData) => {
            return { ...prevData, "message": value };
        });
    };

    const handleSelectionChange = useCallback(() => {
        const editor = quillRef.current.getEditor();
        const selection = editor.getSelection();
        if (selection) {
            cursorRef.current = selection.index;
        }
    }, []);

    useEffect(() => {
        const editor = quillRef.current.getEditor();
        editor.on('selection-change', handleSelectionChange);

        return () => {
            editor.off('selection-change', handleSelectionChange);
        };
    }, [handleSelectionChange]);

    const insertEmoji = (emoji) => {
        const editor = quillRef.current.getEditor();
        const cursorPosition = cursorRef.current;

        if (cursorPosition !== undefined) {
            editor.insertText(cursorPosition, emoji);
        }
    };

    const resetAll = () => {

        setMessage("");
        setStatus("");
        setError("");
        setSelectedFile("");
        setIsDeviceSelected(false);
        setIsSelected(false);
        setIsClicked(false);
        setIsScheduleClicked(false);
        setIsScheduleProcess(false);
        setTotalMessage(0);
        setSentMessage(0);
        setGroupVisible(!groupVisible)
        setTemplateSelected(false);
        if (phoneRef.current.value) phoneRef.current.value = "";
        if (messageRef.current.value) messageRef.current.value = "";
        if (selectedDeviceRef.current.value) selectedDeviceRef.current.value = "";
        if (selectedTemplateRef.current.value) selectedTemplateRef.current.value = "";
        if (dateTimeRef.current) dateTimeRef.current.value = null;
        fileRef.current.value ? fileRef.current.value = "" : fileRef.current = "";

    }

    useEffect(() => {
        if (
            phoneRef.current &&
            receiversPhone &&
            receiversPhone.length > 0
        )
            phoneRef.current.value = receiversPhone.join(",");

    }, [receiversPhone])

    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            error !== "" ||
            success !== "" ||
            status !== ""
        ) {

            setTimeout(() => {
                resetAll()
            }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, success, status]);

    useEffect(() => {
        let userProfileData = null;

        if (userProfile) {
            userProfileData = userProfile.user;
            setUserProfileInfo(userProfileData);

            if (
                !(
                    userProfileData.planType &&
                    (

                        userProfileData.planType.includes("WG") ||
                        userProfileData.planType.includes("RC")
                    )
                )
            ) {
                setError("You are not a valid user for this service")
            }
        }
    }, [userProfile, userInfoLoading]);


    return (
        // <Container>
        //     <div className="grid grid-cols-12 gap-2 px-4 pt-8 pl-8">
        //         <div className="col-span-9">
        //             <div className="my-2">
        //                 {
        //                     status !== "" ? (
        //                         <Alerts alertType="Info">{status}</Alerts>
        //                     ) : error !== "" ? (
        //                         <p className="text-center text-sm tracking-normal text-red-500">
        //                             {
        //                                 error && (<Alerts alertType="Error">{error}</Alerts>)
        //                             }
        //                         </p>
        //                     ) : success !== "" ? (
        //                         <p className="text-center text-sm tracking-normal text-red-500">
        //                             {
        //                                 error && (<Alerts alertType="Success">{success}</Alerts>)
        //                             }
        //                         </p>
        //                     ) : (
        //                         ""
        //                     )
        //                 }
        //             </div>
        //             <div className="flex flex-col space-y-4">
        //                 {/* <div>
        //                         <p className="text-gray-600  flex justify-center items-center pb-4 text-xl font-semibold">BROADCAST</p>
        //                     </div> */}

        //                 <div className="flex w-10/12 flex-col space-y-4">
        //                     <div className="grid grid-cols-2 gap-2">
        //                         <div className="">
        //                             <p className="text-sm font-semibold text-gray-600">CONNECTED DEVICE : </p>
        //                         </div>
        //                         <div className="flex justify-start items-center" >
        //                             <select onChange={handleSelectedDevice} ref={selectedDeviceRef}>
        //                                 <option name="" value="" key="select device">Select device</option>
        //                                 {
        //                                     devices && devices.map((device) => {
        //                                         return (
        //                                             <option name={device.deviceName} key={device.deviceName} value={device.deviceName} >{device.deviceName}</option>
        //                                         )
        //                                     }
        //                                     )
        //                                 }
        //                             </select>
        //                         </div>
        //                     </div>
        //                     <div className="grid grid-cols-2 gap-2">
        //                         <div>
        //                             <p className="text-sm font-semibold text-gray-600">SELECT TEMPLATE : </p>
        //                         </div>
        //                         <div>
        //                             <select ref={selectedTemplateRef} onChange={handleSelectTemplate}>
        //                                 <option name="" value="" key="select device">Select Template</option>
        //                                 {
        //                                     templates && templates.map((template) => {
        //                                         return (
        //                                             <option name={template.templateName} key={template.templateName} value={template.body} >{template.templateName}</option>
        //                                         )
        //                                     }
        //                                     )
        //                                 }
        //                             </select>
        //                         </div>
        //                     </div>
        //                 </div>

        //                 <div
        //                     className="flex w-10/12 flex-col space-y-4"
        //                 >
        //                     {
        //                         groupVisible &&
        //                         allGroups.length > 0 &&
        //                         <GroupList className="w-full" handleGroupContact={handleContacts} />
        //                     }

        //                     {
        //                         !groupVisible &&
        //                             allGroups.length > 0 ?
        //                             <div className="grid grid-cols-2 gap-2 my-2 items-center">
        //                                 <label htmlFor="phone" className="text-md font-serif font-semibold text-gray-600 ">
        //                                     Select Any Group :
        //                                 </label>
        //                                 <button disabled={isClicked}
        //                                     className="text-white text-xs flex gap-2 justify-center items-center w-[150px] px-2 py-1 rounded-full bg-blue-400 " onClick={() => setGroupVisible(!groupVisible)}>
        //                                     <ImportContacts size="24" />
        //                                     <p>View Group</p>
        //                                 </button>
        //                             </div> :

        //                             !groupVisible &&
        //                                 allGroups.length === 0 ? (
        //                                 <div
        //                                     className="grid grid-cols-2 gap-2 my-2"
        //                                 >
        //                                     <p className="text-md text-red-500">You don't have offline group! Create one.</p>
        //                                     <Link to="/manageGroup" className="flex justify-center items-center text-xs cursor-pointer bg-purple-400 rounded-lg text-white w-[100px]">Create Group</Link>
        //                                 </div>
        //                             ) : ""


        //                     }

        //                     <input autoComplete="chrome-off"
        //                         type="text"
        //                         name="phone"
        //                         id="phone"
        //                         className="w-full rounded-md px-2"
        //                         onChange={handleMessage}
        //                         ref={phoneRef}
        //                         disabled={isClicked}
        //                         placeholder="contacts"
        //                         readOnly
        //                     />

        //                     <div className="flex justify-between items-center">
        //                         <div>
        //                             <label
        //                                 htmlFor="message"
        //                                 className="text-md font-serif font-semibold text-gray-600"
        //                             >
        //                                 Message:{" "}
        //                                 <span className="text-md  text-blue-500 font-semibold">
        //                                     Que : {totalMessage}
        //                                 </span>{" "}
        //                                 |{" "}
        //                                 <span className="text-md  text-green-500 font-semibold">
        //                                     Sent : {sentMessage}
        //                                 </span>
        //                             </label>
        //                         </div>
        //                         <div className="flex justify-start items-center space-x-2">
        //                             <label htmlFor="template" className="flex justify-start space-x-2 items-center text-gray-600">
        //                                 <input type="radio" name="messageType" id="messageType" value={templateSelected ? "template" : ""} onChange={handleMessage} checked={templateSelected} />
        //                                 <span>Template Message</span>
        //                             </label>
        //                             <label htmlFor="template" className="flex justify-start space-x-2 items-center text-gray-600">
        //                                 <input type="radio" name="messageType" id="messageType" value={!templateSelected ? "default" : ""} onChange={handleMessage} checked={!templateSelected} disabled={templateSelected} />
        //                                 <span>Instant Message</span>
        //                             </label>
        //                         </div>
        //                     </div>
        //                     <div className="w-full rounded-md p-2 bg-white h-[200px]">
        //                         <ReactQuill
        //                             style={{ height: '150px' }}
        //                             ref={quillRef}
        //                             name="message"
        //                             id="message"
        //                             onChange={handleQuillChange}
        //                             value={message?.message}
        //                             disabled={isClicked}
        //                             placeholder="Message"
        //                             theme="snow"
        //                             readOnly={templateSelected}
        //                         />
        //                     </div>

        //                     <label htmlFor="file" className="text-md font-serif font-semibold text-gray-600">
        //                         Upload File:
        //                     </label>
        //                     <input autoComplete="chrome-off"
        //                         type="file"
        //                         name="file"
        //                         id="file"
        //                         onChange={changeHandler}
        //                         ref={fileRef}
        //                         disabled={isClicked}
        //                     />

        //                     {isSelected && selectedFile ? (
        //                         <div>
        //                             <p>
        //                                 Filename: {selectedFile.name ? selectedFile.name : ""}
        //                             </p>
        //                             <p>
        //                                 Filetype: {selectedFile.type ? selectedFile.type : ""}
        //                             </p>
        //                             <p>
        //                                 Size in bytes:{" "}
        //                                 {selectedFile.size ? selectedFile.size : ""}
        //                             </p>
        //                             <p>
        //                                 lastModifiedDate:{" "}
        //                                 {selectedFile.lastModifiedDate
        //                                     ? selectedFile.lastModifiedDate.toLocaleDateString()
        //                                     : ""}
        //                             </p>
        //                         </div>
        //                     ) : (
        //                         <p></p>
        //                     )}

        //                     {isScheduleClicked && (
        //                         <div>
        //                             <label htmlFor="dateTime" className="text-md font-serif font-semibold text-gray-600 ">
        //                                 Schedule DateTime:
        //                             </label>
        //                             <input autoComplete="chrome-off"
        //                                 type="datetime-local"
        //                                 name="dateTime"
        //                                 id="dateTime"
        //                                 className="w-full rounded-md p-2"
        //                                 onChange={handleMessage}
        //                                 ref={dateTimeRef}
        //                                 disabled={isClicked}
        //                             />
        //                         </div>
        //                     )}

        //                     <div className="my-2">
        //                         {
        //                             status !== "" ? (
        //                                 <Alerts alertType="Info">{status}</Alerts>
        //                             ) : error !== "" ? (
        //                                 <p className="text-center text-sm tracking-normal text-red-500">
        //                                     {
        //                                         error && (<Alerts alertType="Error">{error}</Alerts>)
        //                                     }
        //                                 </p>
        //                             ) : success !== "" ? (
        //                                 <p className="text-center text-sm tracking-normal text-red-500">
        //                                     {
        //                                         error && (<Alerts alertType="Success">{success}</Alerts>)
        //                                     }
        //                                 </p>
        //                             ) : (
        //                                 ""
        //                             )
        //                         }
        //                     </div>

        //                     {
        //                         userProfileInfo.planType &&
        //                         (

        //                             userProfileInfo.planType.includes("WG") ||
        //                             userProfileInfo.planType.includes("RC")
        //                         ) && (
        //                             <div className="p-2 text-right">
        //                                 <button
        //                                     className="rounded-md bg-orange-400 py-1 px-4 text-sm text-white"
        //                                     onClick={sendMessage}
        //                                     disabled={isClicked || isScheduleClicked || !isDeviceSelected}
        //                                 >
        //                                     {!isDeviceSelected ? "Select device" : isClicked ? "Sending..." : "Send Now"}
        //                                 </button>
        //                                 {
        //                                     !isScheduleClicked &&
        //                                     !isScheduleProcess && (<button
        //                                         className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
        //                                         onClick={() => setIsScheduleClicked(true)}
        //                                     >
        //                                         Schedule
        //                                     </button>)}
        //                                 {
        //                                     isScheduleClicked && !isScheduleProcess && (<button
        //                                         className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
        //                                         disabled={isClicked || !isScheduleClicked || !isDeviceSelected}
        //                                         onClick={() => sendScheduleMessage()}
        //                                     >
        //                                         Set Schedule
        //                                     </button>)}
        //                                 {
        //                                     isScheduleClicked && isScheduleProcess && (<button
        //                                         className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
        //                                     >
        //                                         Please Wait...
        //                                     </button>)}
        //                             </div>
        //                         )
        //                     }
        //                 </div>

        //             </div>
        //         </div>
        //         <div className="col-span-3">
        //             <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
        //         </div>
        //     </div>
        // </Container>
        <Container>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-4 pt-8">
                {/* Main Content Area */}
                <div className="md:col-span-9">
                    <div className="my-2">
                        {status !== "" ? (
                            <Alerts alertType="Info">{status}</Alerts>
                        ) : error !== "" ? (
                            <p className="text-center text-sm text-red-500">
                                {error && <Alerts alertType="Error">{error}</Alerts>}
                            </p>
                        ) : success !== "" ? (
                            <p className="text-center text-sm text-green-500">
                                {success && <Alerts alertType="Success">{success}</Alerts>}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="flex flex-col space-y-4">
                        {/* Connected Device */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            <p className="text-sm font-semibold text-gray-600">CONNECTED DEVICE:</p>
                            <select onChange={handleSelectedDevice} ref={selectedDeviceRef}>
                                <option name="" value="" key="select device">Select device</option>
                                {devices?.map((device) => (
                                    <option name={device.deviceName} key={device.deviceName} value={device.deviceName}>
                                        {device.deviceName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Select Template */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            <p className="text-sm font-semibold text-gray-600">SELECT TEMPLATE:</p>
                            <select ref={selectedTemplateRef} onChange={handleSelectTemplate}>
                                <option name="" value="" key="select template">Select Template</option>
                                {templates?.map((template) => (
                                    <option name={template.templateName} key={template.templateName} value={template.body}>
                                        {template.templateName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Group Visibility */}
                        {groupVisible && allGroups.length > 0 && (
                            <GroupList className="w-full" handleGroupContact={handleContacts} />
                        )}

                        {/* Message Input */}
                        <div>
                            <label htmlFor="message" className="text-sm font-semibold text-gray-600">
                                Message:
                                <span className="text-sm text-blue-500 ml-2">Que: {totalMessage}</span> |{" "}
                                <span className="text-sm text-green-500">Sent: {sentMessage}</span>
                            </label>
                            <div className="w-full bg-white rounded-md p-2">
                                <ReactQuill
                                    style={{ height: "150px" }}
                                    ref={quillRef}
                                    name="message"
                                    id="message"
                                    onChange={handleQuillChange}
                                    value={message?.message}
                                    disabled={isClicked}
                                    placeholder="Message"
                                    theme="snow"
                                    readOnly={templateSelected}
                                />
                            </div>
                        </div>

                        {/* File Upload */}
                        <div>
                            <label htmlFor="file" className="text-sm font-semibold text-gray-600">
                                Upload File:
                            </label>
                            <input
                                type="file"
                                name="file"
                                id="file"
                                onChange={changeHandler}
                                ref={fileRef}
                                disabled={isClicked}
                                className="block w-full"
                            />
                            {isSelected && selectedFile && (
                                <div className="text-xs">
                                    <p>Filename: {selectedFile.name}</p>
                                    <p>Filetype: {selectedFile.type}</p>
                                    <p>Size in bytes: {selectedFile.size}</p>
                                    <p>Last Modified: {selectedFile.lastModifiedDate?.toLocaleDateString()}</p>
                                </div>
                            )}
                        </div>

                        {/* Schedule DateTime */}
                        {isScheduleClicked && (
                            <div>
                                <label htmlFor="dateTime" className="text-sm font-semibold text-gray-600">
                                    Schedule DateTime:
                                </label>
                                <input
                                    type="datetime-local"
                                    name="dateTime"
                                    id="dateTime"
                                    className="block w-full rounded-md p-2"
                                    onChange={handleMessage}
                                    ref={dateTimeRef}
                                    disabled={isClicked}
                                />
                            </div>
                        )}

                        {/* Action Buttons */}
                        <div className="text-right space-x-2">
                            <button
                                className="rounded-md bg-orange-400 py-1 px-4 text-sm text-white"
                                onClick={sendMessage}
                                disabled={isClicked || !isDeviceSelected}
                            >
                                {!isDeviceSelected ? "Select Device" : isClicked ? "Sending..." : "Send Now"}
                            </button>
                            {!isScheduleClicked && !isScheduleProcess && (
                                <button
                                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white"
                                    onClick={() => setIsScheduleClicked(true)}
                                >
                                    Schedule
                                </button>
                            )}
                            {isScheduleClicked && !isScheduleProcess && (
                                <button
                                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white"
                                    onClick={sendScheduleMessage}
                                    disabled={!dateTimeRef.current.value}
                                >
                                    Set Schedule
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                {/* Emojis Section */}
                <div className="md:col-span-3 hidden md:block">
                    <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
                </div>
            </div>
        </Container>

    );
}

export default BulkMessage;
